import { useTranslation } from 'react-i18next';

import { PlayCircleIcon } from '../../icons/play-circle';
import { PauseIcon } from '../../icons/pause';
import styles from './big-play-button.module.css';

type Props = {
  isPlaying?: boolean;
  onClick?: () => void;
};

export function BigPlayButton({ isPlaying = false, onClick }: Props) {
  const { t } = useTranslation();

  const playButtonLabel = t('ui.play.button.label.active');
  const pauseButtonLabel = t('ui.play.button.label.inactive');

  return isPlaying ? (
    <button
      type="button"
      name="pause-button"
      aria-label={playButtonLabel}
      className={styles.button}
      onClickCapture={onClick}
    >
      <PauseIcon />
    </button>
  ) : (
    <button
      type="button"
      name="big-play-button"
      aria-label={pauseButtonLabel}
      className={styles.button}
      onClickCapture={onClick}
    >
      <PlayCircleIcon />
    </button>
  );
}
