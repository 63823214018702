// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timeline-preview_wrapper__BDMR- {
  position: absolute;
  bottom: 100%;
  z-index: 1;

  background: var(--backgroundColor);
  color: var(--textColor);

  border-radius: 2px;

  font-size: 14px;
  line-height: 23px;
  letter-spacing: 0px;
  text-align: center;

  opacity: 0;
  overflow: hidden;
  pointer-events: none;

  transform: translateY(10px) scale(0.9);
  transform-origin: center bottom;

  transition-property: opacity, transform;
  transition-duration: 0.15s;
}

.mobile .timeline-preview_wrapper__BDMR- {
  bottom: calc(100% + 16px);
}

.desktop .timeline-preview_wrapper__BDMR- {
  bottom: 100%;
}

.timeline-preview_active__vXlU6 {
  opacity: 1;
  transform: translateY(0) scale(1);
}

@media (prefers-reduced-motion) {
  .timeline-preview_wrapper__BDMR- {
    transform: none;
    transition: none;
  }
}

.timeline-preview_wrapper__BDMR- div {
  background-repeat: no-repeat;
}
`, "",{"version":3,"sources":["webpack://./src/components/timeline-preview/timeline-preview.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,UAAU;;EAEV,kCAAkC;EAClC,uBAAuB;;EAEvB,kBAAkB;;EAElB,eAAe;EACf,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;;EAElB,UAAU;EACV,gBAAgB;EAChB,oBAAoB;;EAEpB,sCAAsC;EACtC,+BAA+B;;EAE/B,uCAAuC;EACvC,0BAA0B;AAC5B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,UAAU;EACV,iCAAiC;AACnC;;AAEA;EACE;IACE,eAAe;IACf,gBAAgB;EAClB;AACF;;AAEA;EACE,4BAA4B;AAC9B","sourcesContent":[".wrapper {\n  position: absolute;\n  bottom: 100%;\n  z-index: 1;\n\n  background: var(--backgroundColor);\n  color: var(--textColor);\n\n  border-radius: 2px;\n\n  font-size: 14px;\n  line-height: 23px;\n  letter-spacing: 0px;\n  text-align: center;\n\n  opacity: 0;\n  overflow: hidden;\n  pointer-events: none;\n\n  transform: translateY(10px) scale(0.9);\n  transform-origin: center bottom;\n\n  transition-property: opacity, transform;\n  transition-duration: 0.15s;\n}\n\n:global(.mobile) .wrapper {\n  bottom: calc(100% + 16px);\n}\n\n:global(.desktop) .wrapper {\n  bottom: 100%;\n}\n\n.active {\n  opacity: 1;\n  transform: translateY(0) scale(1);\n}\n\n@media (prefers-reduced-motion) {\n  .wrapper {\n    transform: none;\n    transition: none;\n  }\n}\n\n.wrapper div {\n  background-repeat: no-repeat;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `timeline-preview_wrapper__BDMR-`,
	"active": `timeline-preview_active__vXlU6`
};
export default ___CSS_LOADER_EXPORT___;
