import type { VideoJsPlayer } from 'video.js';
import type { HivePluginOptions } from '../player.interface';

import videojs from 'video.js';
import { SourceType } from '../player.config';
import { VideoJsNativeEvent } from '../videojs-event';
import { HiveErrorMessage, HivePlugin, HiveSeverity, OptionsHtml5 } from './hive.interface';
import { ErrorCode, PlayerError } from '../player-errors';

let plugin: HivePlugin | undefined;

export function getHiveSources(
  player: VideoJsPlayer,
  hiveOptions: HivePluginOptions,
  abortSignal: AbortSignal
): Promise<videojs.Tech.SourceObject[]> {
  return new Promise(
    (resolve: (source: videojs.Tech.SourceObject[]) => void, reject: (reason: PlayerError) => void) => {
      if (abortSignal.aborted) {
        plugin?.closeHiveSession();
        reject(
          new PlayerError(ErrorCode.PLAYER_ERR_GET_SOURCE_ABORTED, 'The request for sources from hive was aborted.')
        );
        return;
      }

      if (!plugin) {
        plugin = createPlugin(player, hiveOptions);
      }

      abortSignal.addEventListener('abort', () => {
        plugin?.closeHiveSession();
        reject(
          new PlayerError(ErrorCode.PLAYER_ERR_GET_SOURCE_ABORTED, 'The request for sources from hive was aborted.')
        );
      });

      try {
        const { ticket, token } = hiveOptions;
        const value = token || ticket;

        if (value) {
          plugin.initSession(value).then((session) => {
            window.HiveModule.enable(plugin, player, videojs);

            player.on(VideoJsNativeEvent.DISPOSE, () => plugin?.closeHiveSession());
            player.on(VideoJsNativeEvent.ERROR, () => plugin?.closeHiveSession());

            resolve([{ src: session.manifest, type: SourceType.HLS }]);
            return;
          });
        }
      } catch (_) {
        destroyPlugin();
        reject(new PlayerError(ErrorCode.PLAYER_ERR_INIT_HIVE_P2P_FAILED, 'The Hive plugin failed to initialize.'));
        return;
      }
    }
  );
}

function destroyPlugin() {
  try {
    plugin?.destroyHivePlugin();
    plugin = undefined;
  } catch (_) {
    // In the unlikely scenario that the cleanup fails, we do nothing.
  }
}

function createPlugin(player: VideoJsPlayer, hiveOptions: HivePluginOptions): HivePlugin {
  const errorHandler = (msg: HiveErrorMessage) => {
    if (msg.severity === HiveSeverity.NORMAL) {
      // No action required
    } else if (msg.severity === HiveSeverity.HIGH) {
      // Session should be re-initialized, ideally with a re-trial mechanism
      plugin?.closeHiveSession();
      // Partner re-initialization logic
    } else if (msg.severity === HiveSeverity.CRITICAL) {
      // Disable the Hive Plugin ( and fallback to the CDN if required)
      destroyPlugin();
    }
  };

  const options: OptionsHtml5 = {
    playerName: 'videojs',
    playerVersion: videojs.VERSION,
    eventName: hiveOptions.eventName,
    debugLevel: hiveOptions.debugLevel,
    hiveTechOrder: hiveOptions?.techOrder,
    onError: errorHandler,
  };

  // backward compatibility for webcast
  const { ticket } = hiveOptions;
  if (ticket) {
    options.HiveJs = {
      streamUrlMatcher: [/https:\/\/.*(\/.*\.ts|\/.*\.m3u8|\/.*\.mp4)/],
    };
  }

  require('./../../../../libs/hive/hive-module-10.1.0.min');
  const { HiveHtml5 } = require('./../../../../libs/hive/hive-html5-java-hivejs-10.1.1.min');

  // tech() will log warning without any argument
  return new HiveHtml5(player.tech(false).el(), options);
}
