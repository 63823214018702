import { useMemo } from 'react';
import { YouboraPluginConfig } from 'youboralib';
import type {
  HivePluginConfig,
  PlayerConfig,
  PluginsConfig,
  RampPluginConfig,
  SettingsConfig,
  VideoMetadataConfig,
  YouboraAnalyticsConfig,
} from '../player.config';
import type {
  HivePluginOptions,
  PlayerOptions,
  PlayerSettings,
  Plugins,
  RampPluginOptions,
  VideoMetadata,
} from '../player.interface';
import { HiveSession } from '../utils/hive.interface';

export function usePlayerOptions(config: PlayerConfig): PlayerOptions {
  const options: PlayerOptions = useMemo(() => {
    return {
      lng: config.lng,
      poster: config.poster,
      sources: config.sources,
      metadata: getVideoMetadata(config.metadata),
      settings: getPlayerSettings(config.settings),
      tracks: config.tracks || [],
      storyboard: config.storyboard,
      plugins: getPlugins(config.plugins),
      onReady: config.onReady,
      watermark: config.watermark,
      analyticsMode: config.analyticsMode || 'minimal',
      onOverlayVisibilityChange: config.onOverlayVisibilityChange,
      downloadSources: config.downloadSources || [],
    };
  }, [
    config.lng,
    config.poster,
    config.sources,
    config.metadata,
    config.settings,
    config.tracks,
    config.storyboard,
    config.plugins,
    config.onReady,
    config.watermark,
    config.analyticsMode,
    config.onOverlayVisibilityChange,
    config.downloadSources,
  ]);
  return options;
}

declare global {
  interface Window {
    hiveRenderStatsCallback?: (stats: unknown) => void;
    hiveOnActiveSession?: (session: HiveSession) => void;
    hiveOnErrorCallback?: (error: unknown) => void;
    hiveOnLoadingCompleted?: (plugin: unknown) => void;
  }
}

function getPlugins(config?: PluginsConfig): Plugins {
  return {
    hive: getHiveConfig(config?.hive),
    ramp: getRampConfig(config?.ramp),
    youboraAnalytics: getYouboraOptions(config?.youboraAnalytics),
  };
}

function getYouboraOptions(config?: YouboraAnalyticsConfig): YouboraPluginConfig | undefined {
  return config
    ? {
        ...config,
      }
    : undefined;
}

function getHiveConfig(config?: HivePluginConfig): HivePluginOptions | undefined {
  return config?.token || config?.ticket
    ? {
        ticket: config.ticket,
        token: config.token,
        eventName: config.eventName,
        techOrder: config.techOrder || ['HiveJava', 'HiveJS', 'StatsJS'],
        debugLevel: config.debugLevel || 'notice',
      }
    : undefined;
}

const defaultRampConfig: RampPluginConfig = {
  id: '',
  title: '',
  omnicacheUris: '',
  vbrickHostName: '',
  vbrickJwtKeyName: '',
  vbrickApiKey: '',
  vbrickApiSecret: '',
};

function getRampConfig({
  id,
  title = '',
  omnicacheUris = '',
  vbrickHostName = '',
  vbrickJwtKeyName = '',
  vbrickApiKey = '',
  vbrickApiSecret = '',
  debug = false,
}: RampPluginConfig = defaultRampConfig): RampPluginOptions | undefined {
  const p2pEnabled = !!vbrickHostName && !!vbrickJwtKeyName && !!vbrickApiKey && !!vbrickApiSecret;

  return omnicacheUris || p2pEnabled
    ? {
        id,
        title,
        vbrickHostName,
        vbrickJwtKeyName,
        vbrickApiKey,
        vbrickApiSecret,
        omnicacheUris,
        p2pEnabled,
        debug: !!debug,
      }
    : undefined;
}

function getVideoMetadata(config?: VideoMetadataConfig): VideoMetadata {
  return {
    videoId: config?.videoId || '',
    title: config?.title || '',
    description: config?.description || '',
  };
}

function getPlayerSettings(settings?: SettingsConfig): PlayerSettings {
  return {
    primaryColor: settings?.primaryColor || '',
    theme: settings?.theme,
    controlBarColor: settings?.controlBarColor || '',
    iconColor: settings?.iconColor || '',
    logoUrl: settings?.logoUrl || '',
    responsive: settings?.responsive ?? true,
    autoplay: settings?.autoplay ?? false,
    looping: settings?.looping ?? false,
    muted: settings?.muted ?? false,
    liveUI: settings?.liveUI ?? false,
    noUI: settings?.noUI ?? false,
    audioOnly: settings?.audioOnly ?? false,
    audioOnlyImageUrl: settings?.audioOnlyImageUrl || '',
    volume: settings?.volume ?? 0.7,
    trimmable: settings?.trimmable ?? false,
    hideLogo: settings?.hideLogo ?? false,
    hideTitle: settings?.hideTitle ?? false,
    hidePlayButton: settings?.hidePlayButton ?? false,
    hideTimeline: settings?.hideTimeline ?? false,
    hideVolumeInput: settings?.hideVolumeInput ?? false,
    hideTimer: settings?.hideTimer ?? false,
    hideLoopButton: settings?.hideLoopButton ?? false,
    hideMenu: settings?.hideMenu ?? false,
    hideFullscreenButton: settings?.hideFullscreenButton ?? false,
    hidePlaybackSpeed: settings?.hidePlaybackSpeed ?? false,
    hideSettingsMenu: settings?.hideSettingsMenu ?? false,
    hideLanguageMenu: settings?.hideLanguageMenu ?? false,
    hideBigPlayButton: settings?.hideBigPlayButton ?? false,
    stepButtonsAreVisible: settings?.stepButtonsAreVisible ?? false,
    preferredQuality: settings?.preferredQuality || '',
    maxPlaylistRetries: settings?.maxPlaylistRetries ?? 15,
    aspectRatio: getVideoJsAspectRatio(settings?.aspectRatio),
    videoLayout: settings?.videoLayout,

    breakpoints: settings?.breakpoints || [],
  };
}

function getVideoJsAspectRatio(aspectRatio: '16:9' | '4:3' | 'Auto' | undefined): '16:9' | '4:3' | undefined {
  /*
   * The "Auto" option is there for backwards compatibilty with VMPRO options.
   * What we call "Auto" is the default behavior for video.js. So there's no need to set it.
   */
  return aspectRatio === '16:9' || aspectRatio === '4:3' ? aspectRatio : undefined;
}
