import classNames from 'classnames';

import { ChevronIcon } from '../../icons/chevron';

import type { Submenu, MenuOption } from './settings-menu.types';

import styles from './settings-menu.module.css';

type MainScreenButtonProps = {
  label: string;
  goTo: Submenu;
  menuOption: MenuOption;
  hasSubMenu: boolean;
  tabIndex?: number;
  onClick: (submenu: Submenu) => void;
};

export function MainScreenButton({ goTo, label, menuOption, tabIndex, onClick, hasSubMenu }: MainScreenButtonProps) {
  return hasSubMenu ? (
    <button
      type="button"
      role="menuitem"
      data-goto={goTo}
      className={classNames(styles.item, styles.mainMenuItem)}
      onClick={() => onClick(goTo)}
      tabIndex={tabIndex}
    >
      <span>{label}</span>
      {menuOption && <span lang={menuOption.languageCode}>{menuOption.label}</span>}
      <ChevronIcon />
    </button>
  ) : (
    <button disabled type="button" role="menuitem" className={classNames(styles.item, styles.mainMenuItem)}>
      <span>{label}</span>
      {menuOption && <span lang={menuOption.languageCode}>{menuOption.label}</span>}
    </button>
  );
}
