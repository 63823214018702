import {
  GhostButton,
  IconButton,
  Modal,
  Paragraph,
  PrimaryButton,
  Tooltip,
  TrashIcon,
  UserIcon,
} from '@movingimage-evp/mi-ui-component-library';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Search } from '../../../../components/search';

import styles from './emails-list.module.css';

type EmailsListProps = {
  emails: string[];
  onAddAttendees: () => void;
  onRemoveEmail: (email: string) => void;
  onRemoveAll: () => void;
};

export function EmailsList({ emails, onAddAttendees, onRemoveEmail, onRemoveAll }: EmailsListProps) {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);

  if (emails.length === 0) return null;

  const showDeleteConfirmationModal = () => {
    setDeleteConfirmationVisible(true);
  };

  const closeDeleteConfirmationModal = () => {
    setDeleteConfirmationVisible(false);
  };

  const handleRemoveAll = () => {
    closeDeleteConfirmationModal();
    onRemoveAll();
  };

  const filteredEmails = emails.filter((email) => email.toLowerCase().includes(search.toLowerCase()));

  return (
    <div data-testid="emails-list" className={styles.emailsList}>
      <header>
        <span data-testid="emails-list-length">
          {t('manager.webcastSetup.viewerAccess.options.emailWhitelist.attendeeList.attendesNumber', {
            count: emails.length,
          })}
        </span>

        <PrimaryButton data-testid="emails-list-add-attendees-button" small onClick={onAddAttendees}>
          {t('manager.webcastSetup.viewerAccess.options.emailWhitelist.attendeeList.addAttendees')}
        </PrimaryButton>

        <GhostButton data-testid="emails-list-delete-all-button" small onClick={showDeleteConfirmationModal}>
          {t('manager.webcastSetup.viewerAccess.options.emailWhitelist.attendeeList.deleteAll')}
        </GhostButton>
      </header>

      <Search
        placeholder={t('manager.webcastSetup.viewerAccess.options.emailWhitelist.attendeeList.searchForEmail')}
        value={search}
        onChange={(event) => setSearch(event.target.value)}
        onClear={() => setSearch('')}
      />

      <div data-testid="emails-list-items" className={styles.items}>
        {filteredEmails.map((email, index) => (
          <div key={index} data-testid={`emails-list-email-${index}`} className={styles.item}>
            <span>
              <UserIcon />
              {email}
            </span>

            <Tooltip label={t('manager.webcastSetup.viewerAccess.options.emailWhitelist.attendeeList.removeEmail')}>
              <IconButton
                data-testid={`emails-list-email-${index}-remove-button`}
                type="button"
                aria-label={t('manager.webcastSetup.viewerAccess.options.emailWhitelist.attendeeList.removeEmail')}
                rounded
                onClick={() => onRemoveEmail(email)}
              >
                <TrashIcon />
              </IconButton>
            </Tooltip>
          </div>
        ))}

        {filteredEmails.length === 0 && (
          <Paragraph data-testid="emails-list-no-results">
            {t('manager.webcastSetup.viewerAccess.options.emailWhitelist.attendeeList.noEmailsFound')}
          </Paragraph>
        )}
      </div>

      <Modal
        data-testid="delete-confirmation-modal"
        title={t('manager.webcastSetup.viewerAccess.options.emailWhitelist.deleteConfirmationModal.title')}
        isOpen={deleteConfirmationVisible}
        onClose={closeDeleteConfirmationModal}
        footer={
          <div className={styles.deleteConfirmationModalFooter}>
            <GhostButton
              data-testid="delete-confirmation-modal-cancel-button"
              small
              onClick={closeDeleteConfirmationModal}
            >
              {t('manager.webcastSetup.viewerAccess.options.emailWhitelist.deleteConfirmationModal.footer.cancel')}
            </GhostButton>

            <PrimaryButton data-testid="delete-confirmation-modal-submit-button" small onClick={handleRemoveAll}>
              {t('manager.webcastSetup.viewerAccess.options.emailWhitelist.deleteConfirmationModal.footer.confirm')}
            </PrimaryButton>
          </div>
        }
      >
        <Paragraph>
          {t('manager.webcastSetup.viewerAccess.options.emailWhitelist.deleteConfirmationModal.description')}
        </Paragraph>
      </Modal>
    </div>
  );
}
