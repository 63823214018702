import type { Channel, VideoManager } from '../../../../generated/graphql-manager';

import {
  Heading,
  InfoCircleIcon,
  Paragraph,
  SelectionInputLayout,
  Spinner,
  Toggle,
  Tooltip,
  classNames,
} from '@movingimage-evp/mi-ui-component-library';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';

import { getPathForChannel } from './event-recording-utils';
import { TurnOffRecordingConfirmationModal } from './turn-off-recording-confirmation-modal';
import { VideoManagerChannels } from './video-manager-channels';
import {
  State,
  useGetRecordingSettingsQuery,
  useGetVideoManagerQuery,
  useUpdateWebcastRecordSettingsMutation,
} from '../../../../generated/graphql-manager';
import { useAbsoluteRoutes } from '../../../../routes';
import { notEmpty } from '../../../../utils/typeguards';
import { SetupPageFooter } from '../../../components/setup-page-footer';
import { ChannelBrowser } from '../../../components/video-manager-browser/channel-browser';
import { useUserPermissions } from '../../../hooks/user-permissions';
import managerStyles from '../../../manager.module.css';
import { useVmproConnection } from '../../../providers/vmpro-connector';

import styles from './event-recording.module.css';

const channelBrowserOpenState = 'CHANNEL_BROWSER';

export type SharingData = {
  sharingUrl: string;
  securedLink: boolean;
  accessCode: string;
};

export function EventRecordingPage() {
  const { t } = useTranslation();
  const { webcastId = '' } = useParams();
  const { isVmproLinked, saveState, showConnectionModal } = useVmproConnection();
  const routes = useAbsoluteRoutes();
  const [searchParams] = useSearchParams();
  const { isEventEditingAllowed } = useUserPermissions();

  const [videoManagerId, setVideoManagerId] = useState<VideoManager['id'] | undefined>();
  const [isVideoManagerNotFound, setIsVideoManagerNotFound] = useState(false);
  const [fileBrowserOpen, setFileBrowserOpen] = useState(false);
  const [turnOffRecordingConfirmationModalOpen, setTurnOffRecordingConfirmationModalOpen] = useState(false);
  const [videoManagerName, setVideoManagerName] = useState<string>('');

  const getWebcastRecordingSettingsResponse = useGetRecordingSettingsQuery({
    variables: { webcastId },
    onCompleted: (data) => {
      const videoManagerId = data.webcast.recordingSettings?.videoManager?.videoManagerId;

      if (videoManagerId) {
        setVideoManagerId(videoManagerId);
      }
    },
  });

  const getCurrentVideoManagerResponse = useGetVideoManagerQuery({
    skip: !videoManagerId || !isVmproLinked,
    variables: { videoManagerId: videoManagerId || '' },
    onCompleted: (data) => {
      const videoManagerName = data.videoManager.__typename === 'VideoManager' ? data.videoManager.name : '';
      setVideoManagerName(videoManagerName);
    },
    onError: () => {
      setVideoManagerId(undefined);
      setIsVideoManagerNotFound(true);
    },
  });

  const [updateWebcastRecordSettings, { loading: updateWebcastRecordSettingsLoading }] =
    useUpdateWebcastRecordSettingsMutation();

  const updateWebcast = ({
    enableRecording,
    enableUpload,
    updatedVideoManagerId,
    updatedChannels,
  }: {
    enableRecording?: boolean;
    enableUpload?: boolean;
    updatedChannels?: { id: string; name: string }[];
    updatedVideoManagerId?: string;
  }) => {
    const channelsToSave = updatedChannels || channels;

    const getUploadEnabled = () => {
      if (channelsToSave.length === 0) return false;

      if ((updatedChannels?.length || 0) > 0) return true;

      return enableUpload !== undefined ? enableUpload : uploadEnabled;
    };

    const updatedEnableUpload = getUploadEnabled();

    updateWebcastRecordSettings({
      variables: {
        input: {
          id: webcastId,
          recordingSettings: {
            enabled: enableRecording !== undefined ? enableRecording : recordingEnabled,
            videoManager:
              updatedVideoManagerId || videoManagerId
                ? {
                    enabled: updatedEnableUpload,
                    videoManagerId: updatedVideoManagerId || videoManagerId,
                    channels: channelsToSave.map((channel) => ({ id: channel.id, name: channel.name })),
                  }
                : undefined,
          },
        },
      },
      onCompleted: (data) => {
        const updateWebcast = data.updateWebcast;
        const success = updateWebcast.__typename === 'UpdateWebcastSuccess';

        const updatedChannels = (success && updateWebcast.webcast.recordingSettings?.videoManager?.channels) || [];
        if (updatedChannels.length === 0 && channels.length > 0) {
          setFileBrowserOpen(true);
        }

        const videoManagerId =
          data.updateWebcast.__typename === 'UpdateWebcastSuccess'
            ? data.updateWebcast.webcast.recordingSettings?.videoManager?.videoManagerId
            : undefined;

        if (videoManagerId) {
          setVideoManagerId(videoManagerId);
        }
      },
    });
  };

  const recordingSettings = getWebcastRecordingSettingsResponse.data?.webcast.recordingSettings;
  const recordingEnabled = recordingSettings?.enabled ?? false;
  const uploadEnabled = recordingSettings?.videoManager?.enabled ?? false;
  const channels = recordingSettings?.videoManager?.channels || [];

  const channelsWithPaths = useMemo(() => {
    const videoManagersChannels =
      getCurrentVideoManagerResponse.data?.videoManager.__typename === 'VideoManager'
        ? getCurrentVideoManagerResponse.data.videoManager.channels || []
        : [];

    return (getWebcastRecordingSettingsResponse.data?.webcast?.recordingSettings?.videoManager?.channels || [])
      .map((channel) => {
        const videoManagerChannel = videoManagersChannels.find(
          (videoManagerChannel) => videoManagerChannel.id === channel?.id
        );

        if (!videoManagerChannel) return null;

        const path = videoManagerChannel
          ? getPathForChannel(videoManagerChannel, videoManagersChannels)
          : channel?.id || '';
        return {
          path: path.replace('root_channel', t('components.videoManager.homeFolder')),
          ...videoManagerChannel,
        };
      })
      .filter(notEmpty);
  }, [getCurrentVideoManagerResponse, getWebcastRecordingSettingsResponse, t]);

  const handleToggleAutoUpload = () => {
    if (!isVmproLinked && channels.length > 0) {
      showConnectionModal();
      return;
    }

    if (channels.length === 0) {
      setFileBrowserOpen(true);

      if (!isVmproLinked) {
        saveState(channelBrowserOpenState);
      }
    } else {
      updateWebcast({
        enableUpload: !uploadEnabled,
      });
    }
  };

  const toggleTurnOffRecordingConfirmationModal = () => {
    setTurnOffRecordingConfirmationModalOpen((state) => !state);
  };

  const handleOnRecordingEnabledChange = () => {
    if (!isVmproLinked && channels.length > 0) {
      showConnectionModal();
      return;
    }

    if (recordingEnabled) {
      toggleTurnOffRecordingConfirmationModal();
    } else {
      handleToggleRecording();
    }
  };

  const handleChooseChannel = (channels: Channel[], videoManagerId: string) => {
    updateWebcast({
      enableUpload: true,
      updatedChannels: channels,
      updatedVideoManagerId: videoManagerId,
    });

    setFileBrowserOpen(false);
  };

  const handleDeleteChannel = (channelId: string) => {
    const updatedChannels = channels.filter((channel) => channel.id !== channelId);
    updateWebcast({ updatedChannels });
  };

  const handleToggleRecording = () => {
    updateWebcast({
      enableRecording: !recordingEnabled,
      enableUpload: recordingEnabled ? false : uploadEnabled,
    });
  };

  useEffect(() => {
    const previousState = searchParams.get('state');

    if (!previousState) return;

    if (previousState === channelBrowserOpenState && isVmproLinked) {
      setFileBrowserOpen(true);
    }
  }, [searchParams, isVmproLinked]);

  if (
    getCurrentVideoManagerResponse.loading ||
    getCurrentVideoManagerResponse.loading ||
    getWebcastRecordingSettingsResponse.loading
  ) {
    return (
      <div className={styles.spinner}>
        <Spinner size={40} />
      </div>
    );
  }

  const showSelectedChannels = channels.length > 0;
  const formDisabled =
    getWebcastRecordingSettingsResponse?.data?.webcast.state !== State.PRELIVE || !isEventEditingAllowed;
  const channelsNotAvailable = (isVmproLinked && isVideoManagerNotFound) || (!isVmproLinked && channels.length > 0);

  return (
    <main className={classNames(managerStyles.main, styles.wrapper)} data-testid="event-recording-page">
      <Heading className={managerStyles.grayText}>{t('manager.webcastSetup.eventRecording.title')}</Heading>
      <Paragraph className={managerStyles.grayText}>{t('manager.webcastSetup.eventRecording.description')}</Paragraph>

      <div className={styles.selectionWrapper}>
        <SelectionInputLayout
          hintMessage={
            <div>
              {t('manager.webcastSetup.eventRecording.options.recordLivestream.hintMessageVOD')}

              <Tooltip
                label={t('manager.webcastSetup.eventRecording.options.recordLivestream.recordingTooltip')}
                delay={0}
              >
                <InfoCircleIcon className={styles.hint} />
              </Tooltip>
            </div>
          }
          className={styles.inputLayout}
          checked={recordingEnabled}
          disabled={formDisabled}
        >
          <Toggle
            data-testid="enable-recording-toggle"
            checked={recordingEnabled}
            disabled={formDisabled}
            onChange={handleOnRecordingEnabledChange}
          >
            {t('manager.webcastSetup.eventRecording.options.recordLivestream.label')}
          </Toggle>

          <TurnOffRecordingConfirmationModal
            isOpen={turnOffRecordingConfirmationModalOpen}
            onCancel={toggleTurnOffRecordingConfirmationModal}
            onConfirm={() => {
              toggleTurnOffRecordingConfirmationModal();
              handleToggleRecording();
            }}
          />
        </SelectionInputLayout>

        <SelectionInputLayout
          className={styles.inputLayout}
          hintMessage={t('manager.webcastSetup.eventRecording.options.uploadRecording.hintMessage')}
          checked={uploadEnabled}
          disabled={!recordingEnabled || formDisabled}
        >
          <Toggle
            data-testid="enable-upload-toggle"
            checked={uploadEnabled}
            disabled={!recordingEnabled || formDisabled}
            onChange={handleToggleAutoUpload}
          >
            {t('manager.webcastSetup.eventRecording.options.uploadRecording.label')}
          </Toggle>

          {fileBrowserOpen && (
            <ChannelBrowser
              isOpen={fileBrowserOpen}
              onClose={() => setFileBrowserOpen(false)}
              onSaveSelectedChannels={handleChooseChannel}
              selectedChannels={channelsWithPaths}
              videoManagerId={videoManagerId}
            />
          )}
        </SelectionInputLayout>
      </div>

      {showSelectedChannels && (
        <VideoManagerChannels
          disabled={!uploadEnabled || formDisabled}
          setFileBrowserVisibility={setFileBrowserOpen}
          channels={channelsWithPaths}
          videoManagerName={videoManagerName}
          noAccessToChannels={channelsNotAvailable}
          onDeleteChannel={handleDeleteChannel}
        />
      )}

      <SetupPageFooter
        nextLabel={t('manager.webcastSetup.eventRecording.nextStep.label')}
        route={routes.webcastSetup_protocolSettings}
        saving={updateWebcastRecordSettingsLoading}
      />
    </main>
  );
}
