import { MouseEvent, MutableRefObject, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { DownloadSource } from '../player/player.config';
import { DownloadSourcesMenuButton, DownloadSourcesMenu } from '../download-sources-menu';

import styles from './top-bar.module.css';

type TopBarProps = {
  playerWrapperRef: MutableRefObject<HTMLElement | null>;
  isHidden?: boolean;
  logoUrl?: string;
  title?: string;
  downloadSources?: DownloadSource[];
  downloadSourcesMenuOpen?: boolean;
  onDownloadSourcesMenuButtonClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  onDownloadSourcesMenuClose?: () => void;
};

export const TopBar = forwardRef<HTMLDivElement, TopBarProps>((props, ref) => {
  const { t } = useTranslation();
  const {
    isHidden,
    logoUrl,
    title,
    downloadSources,
    playerWrapperRef,
    downloadSourcesMenuOpen,
    onDownloadSourcesMenuButtonClick,
    onDownloadSourcesMenuClose,
  } = props;

  return (
    <div ref={ref} className={classNames(styles.wrapper, { [styles.hidden]: isHidden })} data-testid="top-bar">
      {logoUrl && <img className={styles.logo} src={logoUrl} alt={t('ui.video-logo.alt')} data-testid="video-logo" />}
      {title && (
        <span className={styles.title} data-testid="video-title">
          {title}
        </span>
      )}

      {downloadSources && downloadSources.length > 0 && (
        <div className={styles.downloadSourcesButton}>
          <DownloadSourcesMenuButton isActive={downloadSourcesMenuOpen} onClick={onDownloadSourcesMenuButtonClick} />
          <DownloadSourcesMenu
            options={downloadSources}
            isOpen={downloadSourcesMenuOpen}
            playerWrapperRef={playerWrapperRef}
            onClose={onDownloadSourcesMenuClose}
          />
        </div>
      )}
    </div>
  );
});
