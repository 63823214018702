import { useMemo } from 'react';
import { supportsNativeHls } from '../utils/supports-native-hls';
import type { VideoJsPlayerOptions } from 'video.js';
import type { VideoLayout } from '../player.config';

export function useVideoJsOptions(
  maxPlaylistRetries: number,
  looping: boolean,
  muted: boolean,
  aspectRatio?: '16:9' | '4:3',
  videoLayout?: VideoLayout,
  poster?: string
): VideoJsPlayerOptions {
  const options: VideoJsPlayerOptions = useMemo(() => {
    const layout = videoLayout || 'fluid';
    return {
      children: ['mediaLoader', 'posterImage', 'liveTracker'],
      html5: {
        hls: {
          overrideNative: !supportsNativeHls(),
        },
        vhs: {
          maxPlaylistRetries,
          overrideNative: !supportsNativeHls(),
        },
        nativeTextTracks: supportsNativeHls(),
        nativeAudioTracks: supportsNativeHls(),
      },
      loop: looping,
      poster, 
      muted,
      fluid: layout === 'fluid',
      fill: layout === 'fill',
      playsinline: true,
      disablePictureInPicture: true,
      aspectRatio: layout === 'fluid' ? aspectRatio : undefined,
    };
  }, [maxPlaylistRetries, looping, poster, muted, videoLayout, aspectRatio]);

  return options;
}
