import type { SelectOption } from '@movingimage-evp/mi-ui-component-library';
import type { ChangeEvent } from 'react';

import {
  CopyToClipboard,
  Heading,
  InputDescription,
  Paragraph,
  Select,
  TextInput,
  Toggle,
  Tooltip,
  getLanguageKey,
  usePrevious,
} from '@movingimage-evp/mi-ui-component-library';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  IngestProtocol,
  State,
  useGetSupportedLanguagesQuery,
  useGetWebcastLanguagesQuery,
  useUpdateWebcastLanguagesMutation,
} from '../../../../generated/graphql-manager';
import { useAbsoluteRoutes } from '../../../../routes';
import { FeatureToggle } from '../../../../service/feature-toggle/feature-toggle';
import { SetupPageFooter } from '../../../components/setup-page-footer';
import { useUserPermissions } from '../../../hooks/user-permissions';
import managerStyles from '../../../manager.module.css';

import styles from './language.module.css';

export function LanguagePage() {
  const { webcastId = '' } = useParams();
  const { t } = useTranslation();
  const routes = useAbsoluteRoutes();
  const { isEventEditingAllowed } = useUserPermissions();

  const { data: languages } = useGetSupportedLanguagesQuery();
  const supportedLanguages = languages?.configuration.supportedLanguages || [];

  const { data } = useGetWebcastLanguagesQuery({
    variables: { webcastId },
    skip: !webcastId || !supportedLanguages.length,
    onCompleted(data) {
      const webcast = data.webcast;

      setAdditionalLanguages(webcast?.additionalLanguages || []);
      setPrimaryLanguage(webcast?.primaryLanguage);
      setLanguagesData(
        supportedLanguages.map((supportedLanguage) => {
          const title = webcast?.contents.find(({ language }) => language === supportedLanguage)?.title || '';
          return { language: supportedLanguage, title };
        })
      );
      setAiLiveTranscriptGeneration(webcast?.subtitleSettings?.aiLiveTranscriptGeneration === true);
    },
  });

  const webcast = data?.webcast;
  const webcastPrimaryLanguage = webcast?.primaryLanguage;
  const webcastAdditionalLanguages = webcast?.additionalLanguages || [];
  const isSrtProtocol = webcast?.streaming?.live?.ingestProtocol === IngestProtocol.SRT;

  const [primaryLanguage, setPrimaryLanguage] = useState(webcastPrimaryLanguage || '');
  const [additionalLanguages, setAdditionalLanguages] = useState(
    webcastAdditionalLanguages.filter((language) => language !== webcastPrimaryLanguage)
  );
  const [languagesData, setLanguagesData] = useState<{ language: string; title: string }[]>([]);
  const previousPrimaryLanguage = usePrevious(primaryLanguage);

  const [aiLiveTranscriptGeneration, setAiLiveTranscriptGeneration] = useState(
    webcast?.subtitleSettings?.aiLiveTranscriptGeneration === true
  );
  const aiSubtitlesAvailable = additionalLanguages.length === 0 && !isSrtProtocol;

  const handleAdditionalLanguageChange = (option: SelectOption) => {
    setAdditionalLanguages(
      (state) =>
        option.checked
          ? [...state, option.key] // Add language to the list
          : state.filter((language) => language !== option.key) // Remove language from the list
    );
  };

  const [updateWebcastLanguagesMutation, { loading }] = useUpdateWebcastLanguagesMutation();

  const onTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;

    setLanguagesData((state) =>
      state.map((language) => (language.language === name ? { ...language, title: value } : language))
    );
  };

  const saveChanges = () => {
    const languages = [
      { language: primaryLanguage, isPrimary: true },
      ...additionalLanguages.map((language) => ({ language, isPrimary: false })),
    ];

    const contents = languagesData.filter(
      (item) => additionalLanguages.includes(item.language) || item.language === primaryLanguage
    );

    updateWebcastLanguagesMutation({
      variables: {
        input: {
          id: webcastId,
          languages,
          subtitleSettings: { aiLiveTranscriptGeneration },
          contents,
        },
      },
      optimisticResponse: {
        updateWebcast: {
          __typename: 'UpdateWebcastSuccess',
          webcast: {
            __typename: 'Webcast',
            id: webcastId,
            primaryLanguage,
            additionalLanguages,
            subtitleSettings: { aiLiveTranscriptGeneration },
            contents: languagesData,
          },
        },
      },
      update: (cache, { data }) => {
        if (data?.updateWebcast.__typename === 'UpdateWebcastSuccess') {
          cache.modify({
            id: cache.identify({ __typename: 'Webcast', id: webcastId }),
            fields: {
              presentations: (presentation) => {
                if (languages.length === 1) return [];
                return [...(presentation || [])];
              },
            },
          });
        }
      },
    });
  };

  const primaryLanguageTitle = languagesData?.find((language) => language.language === primaryLanguage)?.title || '';
  const previousPrimaryLanguageTitle = usePrevious(primaryLanguageTitle);

  useEffect(() => {
    const newSelectedPrimaryLanguageHasNoTitle = previousPrimaryLanguageTitle && !primaryLanguageTitle;
    const primaryLanguageHasChanged = previousPrimaryLanguage !== '' && previousPrimaryLanguage !== primaryLanguage;

    if (newSelectedPrimaryLanguageHasNoTitle) {
      setLanguagesData((state) =>
        state.map((language) => ({
          ...language,
          title: language.language === primaryLanguage ? previousPrimaryLanguageTitle : language.title,
        }))
      );
    }

    if (primaryLanguageHasChanged) {
      const hasSwitchedLanguageBeenChecked = additionalLanguages.includes(primaryLanguage);

      setAdditionalLanguages((state) =>
        (hasSwitchedLanguageBeenChecked ? [...state, previousPrimaryLanguage] : state).filter(
          (language) => language !== primaryLanguage
        )
      );
    }
  }, [
    additionalLanguages,
    previousPrimaryLanguage,
    previousPrimaryLanguageTitle,
    primaryLanguage,
    primaryLanguageTitle,
  ]);

  const editingDisabled = !isEventEditingAllowed || webcast?.state !== State.PRELIVE;

  return (
    <main className={managerStyles.main} data-testid="language-page">
      <Heading className={managerStyles.grayText}>{t('manager.webcastSetup.language.title')}</Heading>
      <Paragraph>{t('manager.webcastSetup.language.headings.primaryLanguage')}</Paragraph>

      <InputDescription label={t('manager.webcastSetup.language.inputs.primaryLanguage.label')}>
        <Select
          data-testid="primary-language-select"
          disabled={editingDisabled}
          value={primaryLanguage}
          onChange={({ key }) => setPrimaryLanguage(key)}
          options={supportedLanguages.map((language) => ({
            key: language,
            label: t(`manager.webcastSetup.language.supportedLanguages.${getLanguageKey(language)}`),
          }))}
        />
      </InputDescription>

      <InputDescription label={t('manager.webcastSetup.language.inputs.additionalLanguages.label')}>
        <Tooltip
          delay={0}
          hidden={!aiLiveTranscriptGeneration && !aiLiveTranscriptGeneration}
          label={
            aiLiveTranscriptGeneration
              ? t('manager.webcastSetup.language.inputs.additionalLanguages.disabledInfo.aiSubtitles')
              : t('manager.webcastSetup.language.inputs.additionalLanguages.disabledInfo.srt')
          }
        >
          <Select
            data-testid="additional-languages"
            disabled={editingDisabled || isSrtProtocol || aiLiveTranscriptGeneration}
            onChange={handleAdditionalLanguageChange}
            placeholder={t('manager.webcastSetup.language.inputs.additionalLanguages.placeholder')}
            options={supportedLanguages
              .filter((language) => language !== primaryLanguage)
              .map((language) => ({
                key: language,
                label: t(`manager.webcastSetup.language.supportedLanguages.${getLanguageKey(language)}`),
                checked: additionalLanguages.includes(language),
              }))}
          />
        </Tooltip>
      </InputDescription>

      {additionalLanguages.length > 0 && (
        <div className={styles.additionalLanguagesTitles}>
          <Paragraph>{t('manager.webcastSetup.language.headings.additionalLanguages')}</Paragraph>

          <InputDescription
            inputId="primary-language-title"
            label={t('manager.webcastSetup.language.inputs.primaryLanguageTitle.label')}
          >
            <TextInput
              value={primaryLanguageTitle}
              name={primaryLanguage}
              onChange={onTitleChange}
              data-testid="primary-language-title"
            >
              <CopyToClipboard value={primaryLanguageTitle} notificationPosition="left" />
            </TextInput>
          </InputDescription>

          {languagesData
            ?.filter(({ language }) => language !== primaryLanguage && additionalLanguages.includes(language))
            .map(({ language, title }) => (
              <InputDescription
                key={language}
                inputId={`language-title-${language}`}
                label={t(`manager.common.languages.${getLanguageKey(language)}`)}
              >
                <TextInput
                  data-testid={`language-title-${language}`}
                  name={language}
                  value={title || ''}
                  onChange={onTitleChange}
                />
              </InputDescription>
            ))}
        </div>
      )}

      <FeatureToggle featureName="aiSubtitles">
        <div className={styles.aiSubtitles}>
          <Paragraph>{t('manager.webcastSetup.language.inputs.aiSubtitles.info')}</Paragraph>

          <Tooltip
            delay={0}
            hidden={aiSubtitlesAvailable}
            label={
              additionalLanguages.length > 0
                ? t('manager.webcastSetup.language.inputs.aiSubtitles.disabledInfo.multilanguage')
                : t('manager.webcastSetup.language.inputs.aiSubtitles.disabledInfo.srt')
            }
          >
            <Toggle
              data-testid="ai-subtitles-toggle"
              checked={aiLiveTranscriptGeneration}
              disabled={
                editingDisabled ||
                !aiSubtitlesAvailable ||
                webcast?.subtitleSettings?.aiLiveTranscriptGeneration === undefined
              }
              onChange={() => setAiLiveTranscriptGeneration((state) => !state)}
            >
              {t('manager.webcastSetup.language.inputs.aiSubtitles.label')}
            </Toggle>
          </Tooltip>
        </div>
      </FeatureToggle>

      <SetupPageFooter
        nextLabel={t('manager.webcastSetup.language.nextStep.label')}
        route={routes.webcastSetup_eventDescription}
        showActiveSaveButton
        saveButtonDisabled={editingDisabled}
        saving={loading}
        onSaveButtonClick={saveChanges}
      />
    </main>
  );
}
