import { ReactNode } from 'react';
import classNames from 'classnames';
import type { MenuOption } from '../settings-menu/settings-menu.types';

import { CheckIcon } from '../../icons/check';

import styles from './language-menu.module.css';

type SubMenuProps = {
  label: ReactNode;
  dataTestId: string;
  height?: number;
  chosenItemId?: string;
  menuOptions?: MenuOption[];
  onChooseItem?: (itemId: string) => void;
  isOpen?: boolean;
};

export function LanguageSubMenu({
  label,
  dataTestId,
  chosenItemId,
  menuOptions,
  onChooseItem,
  isOpen,
  height,
}: SubMenuProps) {
  return (
    <div role="menu" className={styles.menu} data-testid={dataTestId} style={{ maxHeight: height || '100%' }}>
      <div className={classNames(styles.item, styles.menuHeading)} data-testid="menu-heading">
        {label}
      </div>

      <div className={classNames(styles.menuContent, 'player-scrollbar')}>
        {menuOptions?.map((option) => (
          <button
            type="button"
            role="menuitem"
            key={option.id}
            data-testid={option.id || option.label}
            className={classNames(styles.item, styles.subMenuItem, { [styles.active]: option.id === chosenItemId })}
            onClick={() => {
              onChooseItem?.(option.id);
            }}
            tabIndex={isOpen ? 0 : -1}
          >
            <CheckIcon />
            <span className={styles.span} lang={option.languageCode}>
              {option.label}
            </span>
          </button>
        ))}
      </div>
    </div>
  );
}
