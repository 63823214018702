import { useRef, useState, Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { FullscreenButton } from '../../fullscreen-button';
import { MobileOverlay } from '../../mobile-overlay';
import { Subtitles } from '../../subtitles';
import { Timeline } from '../../timeline';
import { SettingsMenu, SettingsMenuButton } from '../../settings-menu';
import { Spinner } from '../../spinner/spinner';
import { WatermarkOverlay } from '../../watermark-overlay';
import { useHover } from '../../../hooks/use-hover';
import { useMouseMoving } from '../../../hooks/use-mouse-moving';
import { useDynamicSize } from '../../../hooks/use-dynamic-size';
import { useElementsVisibilities } from '../hooks/use-elements-visibilities';
import { AudioAnimationOverlay } from '../../audio-animation-overlay';

import styles from '../user-interfaces.module.css';
import { LiveUIMobileProps } from '../user-interface.types';
import { LanguageMenuButton, LanguageMenu } from '../../language-menu';

export function LiveUIMobile({
  elementVisibilitySettings = {},
  breakpoints = [],
  isPlaying = false,
  isStopped = true,
  logoUrl = '',
  title = '',
  currentTime = 0,
  totalTime = 0,
  bufferPercentage = 0,
  isFullscreen = false,
  audioOnly = false,
  audioOnlyImageUrl = '',
  subtitlesEnabled = false,
  subtitle = '',
  subtitleOptionId = '',
  subtitleOptions = [],
  qualityId = '',
  qualities = [],
  autoQualityOptionAvailable = true,
  playbackSpeedId = '',
  playbackSpeeds = [],
  storyboard,
  watermark,
  onPlaybackToggle,
  onTimeChange,
  onFullscreenToggle,
  onSetSubtitle,
  onSetQuality,
  audioTracks = [],
  audioTrackId = '',
  onSetAudioTrack,
  onOverlayVisibilityChange,
}: LiveUIMobileProps) {
  const { t } = useTranslation();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const bottomBarRef = useRef(null);
  const isMouseMovingOverWrapper = useMouseMoving(wrapperRef);
  const isBottomBarHovered = useHover(bottomBarRef);
  const { width: wrapperWidth } = useDynamicSize(wrapperRef);
  const {
    showLogo,
    showTitle,
    showTimeline,
    showSubtitles,
    showSettingsButton,
    showFullscreenButton,
    showBigPlayButton,
  } = useElementsVisibilities(wrapperWidth, elementVisibilitySettings, breakpoints);

  const [settingsMenuIsOpen, setSettingsMenuIsOpen] = useState(false);
  const [languageMenuIsOpen, setLanguageMenuIsOpen] = useState(false);

  const toggleSettingsMenu = () => {
    setSettingsMenuIsOpen((state) => !state);
  };

  const closeSettingsMenu = () => {
    setSettingsMenuIsOpen(false);
  };

  const toggleLanguageMenu = () => {
    setLanguageMenuIsOpen((state) => !state);
  };

  const closeLanguageMenu = () => {
    setLanguageMenuIsOpen(false);
  };

  const controlsAreHidden = isPlaying && !isMouseMovingOverWrapper;
  const logoIsVisible = showLogo && logoUrl !== '';
  const titleIsVisible = showTitle && title !== '';
  const timelineIsVisible = showTimeline && totalTime > 0;
  const subtitlesAreVisible = subtitlesEnabled && subtitle !== '';
  const subtitlesHaveSpaceForBottomBar = isBottomBarHovered || !controlsAreHidden;
  const languageMenuIsVisible =
    showSubtitles && ((audioTracks && audioTracks?.length > 0) || subtitleOptions.length > 0);
  const topBarIsVisible = logoIsVisible || titleIsVisible || languageMenuIsVisible || showSettingsButton;

  useEffect(() => {
    onOverlayVisibilityChange?.(controlsAreHidden);
  }, [controlsAreHidden, onOverlayVisibilityChange]);

  return (
    <div
      ref={wrapperRef}
      className={classNames(styles.wrapper, 'mobile', { settingsMenuIsOpen })}
      data-testid="player-wrapper"
      tabIndex={-1}
    >
      <Spinner />

      <WatermarkOverlay watermark={watermark} />

      {topBarIsVisible && (
        <div className={classNames(styles.topBar, { [styles.hidden]: controlsAreHidden })} data-testid="top-bar">
          <div className={styles.topBarLogoTitle}>
            {logoIsVisible && <img src={logoUrl} alt={t('ui.video-logo.alt')} data-testid="video-logo" />}
            {titleIsVisible && <span data-testid="video-title">{title}</span>}
          </div>
          <div className={styles.menuButtons}>
            {languageMenuIsVisible && (
              <>
                <LanguageMenuButton isActive={languageMenuIsOpen} onClick={toggleLanguageMenu} />
                <LanguageMenu
                  isOpen={languageMenuIsOpen}
                  chosenSubtitleId={subtitleOptionId}
                  subtitles={subtitleOptions}
                  subtitlesEnabled={subtitlesEnabled}
                  playerWrapperRef={wrapperRef}
                  bottomBarRef={bottomBarRef}
                  onSetSubtitle={onSetSubtitle}
                  onClose={closeLanguageMenu}
                  audioTracks={audioTracks}
                  onSetAudioTrack={onSetAudioTrack}
                  chosenAudioTrackId={audioTrackId}
                />
              </>
            )}
            {showSettingsButton && (
              <>
                <SettingsMenuButton isActive={settingsMenuIsOpen} onClick={toggleSettingsMenu} />
                <SettingsMenu
                  isOpen={settingsMenuIsOpen}
                  chosenQualityId={qualityId}
                  qualities={qualities}
                  autoQualityOptionAvailable={autoQualityOptionAvailable}
                  chosenPlaybackSpeedId={playbackSpeedId}
                  playbackSpeeds={playbackSpeeds}
                  playerWrapperRef={wrapperRef}
                  hidePlaybackSpeed={true}
                  onSetQuality={onSetQuality}
                  onClose={closeSettingsMenu}
                />
              </>
            )}
          </div>
        </div>
      )}

      {audioOnly && <AudioAnimationOverlay imageUrl={audioOnlyImageUrl} isPlaying={isPlaying} isStopped={isStopped} />}

      <div
        ref={bottomBarRef}
        className={classNames(styles.bottomBar, { [styles.hidden]: controlsAreHidden })}
        data-testid="bottom-bar"
      >
        <div className={styles.buttonsSection} data-testid="buttons-bottom-bar">
          <div className={styles.timelineWrapper}>
            {timelineIsVisible && (
              <Suspense fallback={null}>
                <Timeline
                  currentTime={currentTime}
                  totalTime={totalTime}
                  bufferPercentage={bufferPercentage}
                  liveUI={true}
                  isMobile={true}
                  storyboard={storyboard}
                  onChange={onTimeChange}
                />
              </Suspense>
            )}
            {showFullscreenButton && <FullscreenButton isFullscreen={isFullscreen} onClick={onFullscreenToggle} />}
          </div>
        </div>
      </div>

      {subtitlesAreVisible && (
        <Subtitles withSpaceForBottomBar={subtitlesHaveSpaceForBottomBar} wrapperWidth={wrapperWidth}>
          {subtitle}
        </Subtitles>
      )}

      <MobileOverlay
        isPlaying={isPlaying}
        liveUI={true}
        isHidden={controlsAreHidden}
        onPlayClick={onPlaybackToggle}
        showBigPlayButton={showBigPlayButton}
      />
    </div>
  );
}
