import type { Channel, VideoManager } from '../../../generated/graphql-manager';

import { Modal, PlusIcon, PrimaryButton, SecondaryButton, usePrevious } from '@movingimage-evp/mi-ui-component-library';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ChannelsList } from './channels-list';
import { VideoManagerSelect } from './video-manager-select';
import { useGetVideoManagerQuery, useGetVideoManagersQuery } from '../../../generated/graphql-manager';
import { useVmproConnection } from '../../providers/vmpro-connector';

import styles from './channel-browser.module.css';
import modalStyles from './modal.module.css';

type Props = {
  isOpen: boolean;
  selectedChannels?: Channel[];
  videoManagerId?: string;
  onSaveSelectedChannels: (channels: Channel[], videoManagerId: string) => void;
  onClose: () => void;
};

export function ChannelBrowser({
  isOpen,
  selectedChannels: initialSelectedChannels,
  videoManagerId: initialVideoManagerId,
  onClose,
  onSaveSelectedChannels,
}: Props) {
  const { t } = useTranslation();

  const { isVmproLinked, connectionModalVisible, showConnectionModal } = useVmproConnection();
  const modalVisible = isOpen && isVmproLinked;
  const vmproNotLinked = isOpen && !isVmproLinked;

  const [selectedChannels, setSelectedChannels] = useState<Channel[]>(initialSelectedChannels || []);

  // --- Video managers ---
  const [videoManagerId, setVideoManagerId] = useState<VideoManager['id']>(initialVideoManagerId || '');
  const getVideoManagersResponse = useGetVideoManagersQuery({
    skip: !modalVisible,
    onCompleted: (data) => {
      if (!initialVideoManagerId) {
        const videoManagerId =
          data.videoManagers.__typename === 'GetVideoManagersSuccess' ? data.videoManagers.videoManagers[0].id : '';
        setVideoManagerId(videoManagerId);
      }
    },
  });

  const videoManagers =
    getVideoManagersResponse.data?.videoManagers.__typename === 'GetVideoManagersSuccess'
      ? getVideoManagersResponse.data.videoManagers.videoManagers
      : [];

  // -- Current video manager ---
  const getVideoManagerResponse = useGetVideoManagerQuery({
    variables: { videoManagerId },
    skip: !videoManagerId || !modalVisible,
    onCompleted: (data) => {
      const channelId =
        (data.videoManager.__typename === 'VideoManager' && data?.videoManager?.channels?.[0]?.id) || '';
      setChannelId(channelId);
    },
  });

  const handleVideoManagerIdChange = ({ key }: { key: string }) => {
    setVideoManagerId(key);

    if (initialVideoManagerId === key) {
      setSelectedChannels(initialSelectedChannels || []);
    } else {
      setSelectedChannels([]);
    }
  };

  // --- Channels ---
  const [channelId, setChannelId] = useState<Channel['id']>('');
  const channels =
    getVideoManagerResponse.data?.videoManager.__typename === 'VideoManager'
      ? getVideoManagerResponse.data.videoManager.channels || []
      : [];

  const handleOnChannelSelect = (channel: Channel) => {
    const isChannelSelected = selectedChannels.find(({ id }) => id === channel.id);
    if (isChannelSelected) {
      setSelectedChannels(selectedChannels.filter(({ id }) => id !== channel.id));
    } else {
      setSelectedChannels([...selectedChannels, channel]);
    }
  };

  useEffect(() => {
    if (vmproNotLinked) showConnectionModal();
  }, [vmproNotLinked, showConnectionModal]);

  const previousConnectionModalVisible = usePrevious(connectionModalVisible);

  useEffect(() => {
    if (vmproNotLinked && previousConnectionModalVisible && !connectionModalVisible) {
      onClose();
    }
  }, [vmproNotLinked, connectionModalVisible, previousConnectionModalVisible, onClose]);

  return (
    <Modal
      data-testid="video-manager-channel-browser"
      title={t('components.videoManager.channelBrowser.title')}
      isOpen={modalVisible}
      className={modalStyles.modalBackground}
      contentProps={{ className: modalStyles.modal }}
      onClose={onClose}
    >
      <VideoManagerSelect
        info={t('components.videoManager.channelBrowser.info')}
        videoManagers={videoManagers}
        currentVideoManagerId={videoManagerId}
        handleVideoManagerIdChange={handleVideoManagerIdChange}
      />

      <ChannelsList
        currentChannelId={channelId}
        channels={channels}
        onChannelClick={setChannelId}
        onChannelSelect={handleOnChannelSelect}
        rootChannelLabel={t('components.videoManager.homeFolder')}
        className={styles.channelList}
      />

      <div className={styles.footer}>
        <PrimaryButton
          data-testid="video-manager-channel-browser-save-button"
          className={styles.saveButton}
          disabled={!selectedChannels.length}
          onClick={() => {
            onSaveSelectedChannels?.(selectedChannels, videoManagerId);
          }}
        >
          {t('components.videoManager.channelBrowser.save')}
          <PlusIcon />
        </PrimaryButton>

        <SecondaryButton
          data-testid="video-manager-channel-browser-cancel-button"
          className={styles.cancelButton}
          onClick={onClose}
        >
          {t('components.videoManager.channelBrowser.cancel')}
        </SecondaryButton>
      </div>
    </Modal>
  );
}
