import type { ReactNode } from 'react';

import { LanguageProvider } from '@movingimage-evp/mi-ui-component-library';
import { Suspense, lazy } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { AdminApiProvider, ViewerApiProvider } from './api';
import { MediaQueryProvider } from './common/media-query-provider';
import { AdminCustomAppearance } from './components/admin-custom-appearance';
import { ChatProvider } from './components/chat/use-sendbird-chat';
import { DevToolsProvider } from './dev-tools/dev-tools';
import i18n from './i18n';
import { BrandingPage } from './manager/pages/branding';
import { ChannelSettings } from './manager/pages/channel-settings/channel-settings';
import { CompliancePage } from './manager/pages/channel-settings/compliance/compliance-page';
import { PlanDetailsAndUsagePage } from './manager/pages/channel-settings/plan-details-and-usage/plan-details-and-usage';
import { ChannelsPage } from './manager/pages/channels/channels-page';
import { UsersPage } from './manager/pages/users/users-page';
import { EcdnPage } from './manager/pages/webcast-setup/ecdn';
import { EventDescriptionPage } from './manager/pages/webcast-setup/event-description';
import { EventDetailsPage } from './manager/pages/webcast-setup/event-details';
import { EventRecordingPage } from './manager/pages/webcast-setup/event-recording/event-recording';
import { EventSummaryGuard } from './manager/pages/webcast-setup/event-summary/event-summary-guard';
import { LanguagePage } from './manager/pages/webcast-setup/language';
import { MediaPage } from './manager/pages/webcast-setup/media';
import { MessagingPage } from './manager/pages/webcast-setup/messaging';
import { ProtocolSettingsPage } from './manager/pages/webcast-setup/protocol-settings';
import { SetupSummaryPage } from './manager/pages/webcast-setup/setup-summary/setup-summary';
import { SharingAndEmbeddingPage } from './manager/pages/webcast-setup/sharing-and-embedding';
import { ViewerAccessPage } from './manager/pages/webcast-setup/viewer-access';
import { WebcastGuard } from './manager/pages/webcast-setup/webcast-guard';
import { WebcastsPage } from './manager/pages/webcasts';
import { PresentationsProcessorProvider } from './manager/providers/presentations-processor';
import { ProgressContextProvider } from './manager/providers/progress-context/progress-context';
import { UiStateProvider } from './manager/providers/ui-state-provider';
import { VmproConnectionModal, VmproConnectionProvider } from './manager/providers/vmpro-connector';
import { relativeRoutes, useAbsoluteRoutes } from './routes';
import { KeycloakProvider } from './service/authentication/keycloak-provider';
import { useFeatureToggle } from './service/feature-toggle/feature-toggle';

const StudioApp = lazy(() => import('./studio'));
const ManagerApp = lazy(() => import('./manager'));
const ViewerApp = lazy(() => import('./viewer'));
const FeatureTogglePage = lazy(() => import('./service'));
const IFrameTestApp = lazy(() => import('./service/iframe/iframe-test-app'));
const SubtitlesDemoApp = lazy(() => import('./viewer/subtitles-demo'));
const VwDemoApp = lazy(() => import('./viewer/vw-demo'));

function Admin({ children }: { children: ReactNode }) {
  return (
    <AdminApiProvider>
      <ProgressContextProvider>
        <PresentationsProcessorProvider>
          <VmproConnectionProvider>
            <UiStateProvider>
              <AdminCustomAppearance />
              {children}
            </UiStateProvider>
            <VmproConnectionModal />
          </VmproConnectionProvider>
        </PresentationsProcessorProvider>
      </ProgressContextProvider>
    </AdminApiProvider>
  );
}

function Viewer({ children }: { children: ReactNode }) {
  return <ViewerApiProvider>{children}</ViewerApiProvider>;
}

export function App() {
  const absoluteRoutes = useAbsoluteRoutes();
  const detailsAndUsageAvailable = useFeatureToggle('detailsAndUsage');

  const isDevelopment = process.env.REACT_APP_MODE === 'development';

  return (
    <MediaQueryProvider>
      <LanguageProvider i18n={i18n}>
        <BrowserRouter basename={process.env.REACT_APP_SUBFOLDER_PATH}>
          <DevToolsProvider>
            <Suspense fallback={null}>
              <Routes>
                {isDevelopment && (
                  <Route
                    path={absoluteRoutes.iFrame}
                    element={
                      <UiStateProvider>
                        <IFrameTestApp />
                      </UiStateProvider>
                    }
                  />
                )}

                <Route path={absoluteRoutes.subtitlesDemo} element={<SubtitlesDemoApp />} />
                <Route path={absoluteRoutes.vwDemo} element={<VwDemoApp />} />

                <Route
                  path={absoluteRoutes.viewer}
                  element={
                    <Viewer>
                      <ChatProvider>
                        <ViewerApp />
                      </ChatProvider>
                    </Viewer>
                  }
                >
                  <Route path=":webcastId" element={<ViewerApp />}>
                    <Route path=":language" element={<ViewerApp />} />
                  </Route>
                </Route>

                <Route
                  path={`/:channelId${absoluteRoutes.studio}`}
                  element={
                    <KeycloakProvider>
                      <Admin>
                        <ChatProvider>
                          <StudioApp />
                        </ChatProvider>
                      </Admin>
                    </KeycloakProvider>
                  }
                >
                  <Route path=":webcastId" element={<StudioApp />} />

                  <Route path={relativeRoutes.messaging} element={<StudioApp />}>
                    <Route path=":webcastId" element={<StudioApp />} />
                  </Route>
                </Route>

                <Route
                  path="/"
                  element={
                    <KeycloakProvider>
                      <Admin>
                        <ManagerApp />
                      </Admin>
                    </KeycloakProvider>
                  }
                >
                  <Route path=":channelId">
                    <Route index element={<WebcastsPage />} />
                    <Route path={relativeRoutes.channels} element={<ChannelsPage />} />
                    <Route path={relativeRoutes.users} element={<UsersPage />} />

                    <Route path={relativeRoutes.eventSummary} element={<EventSummaryGuard />}>
                      <Route path=":webcastId" element={<EventSummaryGuard />} />
                    </Route>

                    <Route path={relativeRoutes.webcastSetup} element={<WebcastGuard />}>
                      <Route path={relativeRoutes.eventDetails} element={<EventDetailsPage />}>
                        <Route path=":webcastId" element={<EventDetailsPage />} />
                      </Route>

                      <Route path={relativeRoutes.eventDescription} element={<EventDescriptionPage />}>
                        <Route path=":webcastId" element={<EventDescriptionPage />} />
                      </Route>

                      <Route path={relativeRoutes.messaging} element={<MessagingPage />}>
                        <Route path=":webcastId" element={<MessagingPage />} />
                      </Route>

                      <Route path={relativeRoutes.eventRecording} element={<EventRecordingPage />}>
                        <Route path=":webcastId" element={<EventRecordingPage />} />
                      </Route>

                      <Route path={relativeRoutes.language} element={<LanguagePage />}>
                        <Route path=":webcastId" element={<LanguagePage />} />
                      </Route>

                      <Route path={relativeRoutes.sharingAndEmbedding} element={<SharingAndEmbeddingPage />}>
                        <Route path=":webcastId" element={<SharingAndEmbeddingPage />} />
                      </Route>

                      <Route path={relativeRoutes.viewerAccess} element={<ViewerAccessPage />}>
                        <Route path=":webcastId" element={<ViewerAccessPage />} />
                      </Route>

                      <Route path={relativeRoutes.protocolSettings} element={<ProtocolSettingsPage />}>
                        <Route path=":webcastId" element={<ProtocolSettingsPage />} />
                      </Route>

                      <Route path={relativeRoutes.ecdn} element={<EcdnPage />}>
                        <Route path=":webcastId" element={<EcdnPage />} />
                      </Route>

                      <Route path={relativeRoutes.media} element={<MediaPage />}>
                        <Route path=":webcastId" element={<MediaPage />} />
                      </Route>

                      <Route path={relativeRoutes.setupSummary} element={<SetupSummaryPage />}>
                        <Route path=":webcastId" element={<SetupSummaryPage />} />
                      </Route>
                    </Route>

                    <Route path={relativeRoutes.channelSettings} element={<ChannelSettings />}>
                      {detailsAndUsageAvailable && (
                        <Route path={relativeRoutes.planDetailsAndUsage} element={<PlanDetailsAndUsagePage />} />
                      )}

                      <Route path={relativeRoutes.compliance} element={<CompliancePage />} />
                    </Route>

                    <Route path={relativeRoutes.branding} element={<BrandingPage />} />

                    <Route path="*" element={<Navigate to="/" />} />
                  </Route>

                  <Route path="*" element={<Navigate to="/" />} />
                </Route>

                <Route path={absoluteRoutes.featureToggle} element={<FeatureTogglePage />} />
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </Suspense>
          </DevToolsProvider>
        </BrowserRouter>
      </LanguageProvider>
    </MediaQueryProvider>
  );
}
