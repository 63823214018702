import type { User } from '../../../../generated/graphql-manager';
import type { SortOrder } from '@movingimage-evp/mi-ui-component-library';

import {
  Heading,
  IconButton,
  Paragraph,
  PencilIcon,
  PlusIcon,
  PrimaryButton,
  Spinner,
  Table,
  Tooltip,
  TrashIcon,
  UserCircleIcon,
  classNames,
  getNextSorting,
  sort,
} from '@movingimage-evp/mi-ui-component-library';
import { format } from 'date-fns';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetUsersQuery } from '../../../../generated/graphql-manager';
import { getLocale } from '../../../../i18n';
import { getUserNameAndInitials } from '../../../../service/authentication/keycloak-provider';
import { useCurrentUser } from '../../../hooks/current-user';
import managerStyles from '../../../manager.module.css';
import { EditUserModal } from '../edit-user-modal';
import { InviteUserModal } from '../invite-user-modal';
import { RemoveUserModal } from '../remove-user-modal';

import styles from './users-panel.module.css';

export function UsersPanel() {
  const { t } = useTranslation();
  const { lsproId } = useCurrentUser();
  const { email } = getUserNameAndInitials();
  const locale = getLocale();

  const [sorting, setSorting] = useState<{ id: keyof User; order?: SortOrder }>();
  const [isEditUserModalOpened, setIsEditUserModalOpened] = useState(false);
  const [isRemoveUserModalOpened, setIsRemoveUserModalOpened] = useState(false);
  const [userInviteOpen, setUserInviteOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User>();

  const getUsersResponse = useGetUsersQuery({ variables: { lsproId } });

  const handleSortChange = (columnId: keyof User) => {
    if (columnId !== sorting?.id) {
      setSorting({ id: columnId, order: 'asc' });
      return;
    }

    setSorting({ ...sorting, order: getNextSorting(sorting.order) });
  };

  const onSelectedUser = (user: User, action: 'REMOVE' | 'EDIT') => {
    setSelectedUser(user);
    if (action === 'EDIT') setIsEditUserModalOpened(true);
    if (action === 'REMOVE') setIsRemoveUserModalOpened(true);
  };

  const onCloseUserActionModal = (action: 'REMOVE' | 'EDIT') => {
    setSelectedUser(undefined);
    if (action === 'EDIT') setIsEditUserModalOpened(false);
    if (action === 'REMOVE') setIsRemoveUserModalOpened(false);
  };

  const onInviteUser = () => {
    setUserInviteOpen(true);
  };

  const onCloseInviteUser = () => {
    setUserInviteOpen(false);
  };

  const sortedUserData = useMemo(() => {
    if (getUsersResponse.data?.users.__typename !== 'GetUsersSuccess') return [];

    const users = getUsersResponse.data?.users?.users || [];

    if (sorting === undefined || sorting.order === undefined) return users;

    return sort([...users], sorting.id, sorting.order);
  }, [sorting, getUsersResponse.data]);

  const getRolesCellContent = (roles: User['roles']) => {
    const translatedRoles = roles.map((role) => {
      return t(`users.roles.${role}`);
    });
    return translatedRoles.join(', ');
  };

  return (
    <>
      <section className={classNames(managerStyles.section, styles.usersPanel)}>
        <header className={styles.header}>
          <div>
            <Heading>{t('users.title')}</Heading>
            <Paragraph>{t('users.subTitle')}</Paragraph>
          </div>

          <PrimaryButton data-testid="invite-user-button" onClick={onInviteUser} disabled={getUsersResponse.loading}>
            {t('users.inviteButton')}
            <PlusIcon />
          </PrimaryButton>
        </header>

        {getUsersResponse.loading && <Spinner size={40} style={{ margin: 'auto' }} />}

        {!getUsersResponse.loading && (
          <div className={classNames(styles.scroll, 'lspro-scrollbars')}>
            <Table className={styles.table} data-testid="user-table">
              <Table.Header sticky>
                <Table.Row tabIndex={undefined}>
                  <Table.HeaderCell
                    data-testid="user-email-header-cell"
                    sorting={{
                      onSortingChange: () => handleSortChange('email'),
                      order: sorting?.id === 'email' ? sorting?.order : undefined,
                    }}
                  >
                    {t('users.columnsTitle.email')}
                  </Table.HeaderCell>

                  <Table.HeaderCell
                    data-testid="user-role-header-cell"
                    sorting={{
                      onSortingChange: () => handleSortChange('roles'),
                      order: sorting?.id === 'roles' ? sorting?.order : undefined,
                    }}
                  >
                    {t('users.columnsTitle.role')}
                  </Table.HeaderCell>

                  <Table.HeaderCell
                    data-testid="user-invited-at-header-cell"
                    sorting={{
                      onSortingChange: () => handleSortChange('invitedAt'),
                      order: sorting?.id === 'invitedAt' ? sorting?.order : undefined,
                    }}
                  >
                    {t('users.columnsTitle.invitedAt')}
                  </Table.HeaderCell>

                  <Table.HeaderCell
                    data-testid="user-status-header-cell"
                    sorting={{
                      onSortingChange: () => handleSortChange('status'),
                      order: sorting?.id === 'status' ? sorting?.order : undefined,
                    }}
                  >
                    {t('users.columnsTitle.status')}
                  </Table.HeaderCell>

                  <Table.HeaderCell>{t('users.columnsTitle.actions')}</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {sortedUserData.map((user) => (
                  <Table.Row key={`${user.id}-row`} data-testid="user-row">
                    <Table.Cell data-testid="user-email">
                      <div className={styles.labelWithIcon}>
                        <UserCircleIcon />
                        <span> {user.email}</span>
                      </div>
                    </Table.Cell>

                    <Table.Cell data-testid="user-role" className={styles.rolesCell}>
                      {getRolesCellContent(user.roles)}
                    </Table.Cell>

                    <Table.Cell data-testid="user-date">
                      {user.invitedAt && format(new Date(user.invitedAt), 'PP', { locale })}
                    </Table.Cell>

                    <Table.Cell>
                      {
                        <span
                          className={classNames(
                            user.status === 'ACTIVE' && styles.statusActive,
                            user.status === 'INVITED' && styles.statusInvited,
                            user.status === 'DISABLED' && styles.statusDisabled
                          )}
                          data-testid="user-status"
                        >
                          {t(`users.status.${user.status}`)}
                        </span>
                      }
                    </Table.Cell>

                    <Table.ActionCell>
                      <Tooltip label={t('users.editButton')}>
                        <IconButton
                          rounded
                          onClick={() => onSelectedUser(user, 'EDIT')}
                          aria-label={t('users.editButton')}
                          data-testid="user-edit-button"
                        >
                          <PencilIcon />
                        </IconButton>
                      </Tooltip>

                      <Tooltip label={t('users.removeButton')}>
                        <IconButton
                          rounded
                          onClick={() => onSelectedUser(user, 'REMOVE')}
                          disabled={email === user.email}
                          aria-label={t('users.removeButton')}
                          data-testid="user-remove-button"
                        >
                          <TrashIcon />
                        </IconButton>
                      </Tooltip>
                    </Table.ActionCell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        )}
      </section>

      <EditUserModal
        className={styles.modalContainer}
        user={selectedUser}
        isOpen={isEditUserModalOpened}
        onClose={() => onCloseUserActionModal('EDIT')}
      />

      <RemoveUserModal
        className={styles.modalContainer}
        user={selectedUser}
        isOpen={isRemoveUserModalOpened}
        onClose={() => onCloseUserActionModal('REMOVE')}
      />

      <InviteUserModal className={styles.modalContainer} isOpen={userInviteOpen} onClose={onCloseInviteUser} />
    </>
  );
}
