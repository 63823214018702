// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.watermark-overlay_watermark__D1bmV {
  font-size: 24px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  height: 40px;
}

.watermark-overlay_container__y4QNs {
  align-items: center;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  padding: 0;
  margin: 0;
  top: 0;
}

.watermark-overlay_top-left__86acJ {
  align-items: flex-start;
  justify-content: flex-start;
}

.watermark-overlay_top-center__tsmZX {
  align-items: flex-start;
  justify-content: center;
}

.watermark-overlay_top-right__Xu\\+68 {
  align-items: flex-start;
  justify-content: flex-end;
}

.watermark-overlay_bottom-left__y5zgf {
  align-items: flex-end;
  justify-content: flex-start;
}

.watermark-overlay_bottom-center__QhgW\\+ {
  align-items: flex-end;
  justify-content: center;
}

.watermark-overlay_bottom-right__8vGu3 {
  align-items: flex-end;
  justify-content: flex-end;
}
`, "",{"version":3,"sources":["webpack://./src/components/watermark-overlay/watermark-overlay.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,oBAAoB;EACpB,yBAAyB;EAGzB,iBAAiB;EACjB,aAAa;EACb,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,UAAU;EACV,SAAS;EACT,MAAM;AACR;;AAEA;EACE,uBAAuB;EACvB,2BAA2B;AAC7B;;AAEA;EACE,uBAAuB;EACvB,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;EACvB,yBAAyB;AAC3B;;AAEA;EACE,qBAAqB;EACrB,2BAA2B;AAC7B;;AAEA;EACE,qBAAqB;EACrB,uBAAuB;AACzB;;AAEA;EACE,qBAAqB;EACrB,yBAAyB;AAC3B","sourcesContent":[".watermark {\n  font-size: 24px;\n  display: flex;\n  flex-direction: column;\n  text-align: center;\n  justify-content: center;\n  height: 40px;\n}\n\n.container {\n  align-items: center;\n  pointer-events: none;\n  -webkit-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n  display: flex;\n  width: 100%;\n  height: 100%;\n  position: absolute;\n  padding: 0;\n  margin: 0;\n  top: 0;\n}\n\n.top-left {\n  align-items: flex-start;\n  justify-content: flex-start;\n}\n\n.top-center {\n  align-items: flex-start;\n  justify-content: center;\n}\n\n.top-right {\n  align-items: flex-start;\n  justify-content: flex-end;\n}\n\n.bottom-left {\n  align-items: flex-end;\n  justify-content: flex-start;\n}\n\n.bottom-center {\n  align-items: flex-end;\n  justify-content: center;\n}\n\n.bottom-right {\n  align-items: flex-end;\n  justify-content: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"watermark": `watermark-overlay_watermark__D1bmV`,
	"container": `watermark-overlay_container__y4QNs`,
	"topLeft": `watermark-overlay_top-left__86acJ`,
	"topCenter": `watermark-overlay_top-center__tsmZX`,
	"topRight": `watermark-overlay_top-right__Xu+68`,
	"bottomLeft": `watermark-overlay_bottom-left__y5zgf`,
	"bottomCenter": `watermark-overlay_bottom-center__QhgW+`,
	"bottomRight": `watermark-overlay_bottom-right__8vGu3`
};
export default ___CSS_LOADER_EXPORT___;
