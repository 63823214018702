import { VideoJsPlayer } from 'video.js';
import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { PlayerTextTrack } from '../player.config';
import { VideoJsNativeEvent } from '../videojs-event';
import { SubtitleOption } from 'components/user-interfaces';

export interface Subtitle {
  text: string;
}

// activeTrack mode: disabled, or not set -> video doesnt dispatch cuechange events
// activeTrack mode: showing -> video dispatch cuechange events

export function useSubtitles(
  player: VideoJsPlayer,
  trackOptions: PlayerTextTrack[],
  selectedSubtitleId: string = '',
  setSelectedSubtitleId: Dispatch<SetStateAction<string>>
): {
  subtitle: Subtitle | null;
  subtitleOptions: SubtitleOption[];
} {
  const [subtitle, setSubtitle] = useState<Subtitle | null>(null);
  const [subtitleOptions, setSubtitleOptions] = useState<SubtitleOption[]>([]);

  // Detect when subtitles tracks are added/removed from the player
  useEffect(() => {
    const onAddTrack = ({ track }: TrackEvent) => {
      if (track?.kind === 'subtitles' || track?.kind === 'captions') {
        // Add track to the state, if it does not exist yet
        setSubtitleOptions((prevState) =>
          prevState.find((t) => t.id === track.id) ? prevState : [...prevState, mapTextTrackToSubtitleOption(track)]
        );
      }
    };

    const onRemoveTrack = ({ track }: TrackEvent) => {
      if (track?.kind === 'subtitles' || track?.kind === 'captions') {
        // Remove the track from the state
        setSubtitleOptions((prevState) => prevState.filter((t) => t.id !== track.id));

        // If the removed track was the selected one, set the selected track to off
        setSelectedSubtitleId((prevState) => prevState === track.id ? '' : prevState);
      }
    };

    // Add 'addtrack' and 'removetrack' event listeners
    player.textTracks().addEventListener(VideoJsNativeEvent.ADD_TRACK, onAddTrack);
    player.textTracks().addEventListener(VideoJsNativeEvent.REMOVE_TRACK, onRemoveTrack);

    return () => {
      player.textTracks().removeEventListener(VideoJsNativeEvent.ADD_TRACK, onAddTrack);
      player.textTracks().removeEventListener(VideoJsNativeEvent.REMOVE_TRACK, onRemoveTrack);
    };
  }, [player, setSelectedSubtitleId]);

  // Add external subtitles to player
  useEffect(() => {
    if (trackOptions?.length) {
      const addedTracks: HTMLTrackElement[] = [];
      const setExternalSubtitles = () => {
        trackOptions.forEach((trackOption) => {
          // If the track is not added yet, add it to the player
          const loadedTrack = getSubtitleTrackFromPlayer(player, trackOption.id);
          if (!loadedTrack) {
            addedTracks.push(player.addRemoteTextTrack(trackOption, true));
          }
        });
      };

      player.on(VideoJsNativeEvent.LOAD_START, setExternalSubtitles);

      return () => {
        addedTracks.forEach((track) => player.removeRemoteTextTrack(track));

        player.off(VideoJsNativeEvent.LOAD_START, setExternalSubtitles);
      }
    }
  }, [player, trackOptions]);

  // Activate the current selected subtitle (and deactivate the previous one) when selectedSubtitleId changes
  useEffect(() => {
    if (player && selectedSubtitleId && selectedSubtitleId !== 'off') {
      const trackToActivate = Array.from(player.textTracks()).find(
        (trackOption) => trackOption.id === selectedSubtitleId
      );

      if (trackToActivate) {
        const onCueChange = () => setSubtitleByActiveCue(trackToActivate);

        // Activate the selected track
        trackToActivate.mode = 'hidden';
        trackToActivate.addEventListener(VideoJsNativeEvent.CUE_CHANGE, onCueChange);

        // Read currently active cue
        setSubtitleByActiveCue(trackToActivate);

        return () => {
          // Deactivate previous track
          trackToActivate.mode = 'disabled';
          trackToActivate.removeEventListener(VideoJsNativeEvent.CUE_CHANGE, onCueChange);
        };
      }
    } else {
      setSubtitle(null);
    }

    function setSubtitleByActiveCue(track: TextTrack) {
      if (track && track.activeCues?.length) {
        const activeCue = track.activeCues[0] as VTTCue;

        if (activeCue) {
          setSubtitle({ text: activeCue.text });
        } else {
          setSubtitle(null);
        }
      } else {
        setSubtitle(null);
      }
    }
  }, [player, trackOptions, selectedSubtitleId]);

  return { subtitle, subtitleOptions };
}

function mapTextTrackToSubtitleOption(track: TextTrack): SubtitleOption {
  return {
    id: track.id,
    languageCode: track.language,
    englishLabel: track.label,
  };
}

function getSubtitleTrackFromPlayer(player: VideoJsPlayer, id: string = ''): TextTrack | undefined {
  return Array.from(player.textTracks()).find((track) => track.kind === 'subtitles' && track.id === id);
}
