import { CopyToClipboard } from '@movingimage-evp/mi-ui-component-library';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useGetWebcastSetupSummaryQuery } from '../../../../generated/graphql-manager';
import { formatEventStartDate } from '../../../../utils';
import {
  findAccessCodePolicy,
  findEmailRegistrationPolicy,
  findEmailWhitelistPolicy,
  findSingleSignOnPolicy,
} from '../../../../utils/graphql-helpers';
import { Language } from '../../../components/language';
import { useGetPrimaryTitle } from '../../../hooks/use-get-primary-title';

import styles from './basic-event-info.module.css';

export function BasicEventInfo() {
  const { webcastId = '' } = useParams();
  const title = useGetPrimaryTitle();
  const { t } = useTranslation();

  const { data } = useGetWebcastSetupSummaryQuery({ variables: { webcastId }, skip: !webcastId });
  const webcast = data?.webcast;

  let accessPolicyType: 'accessCode' | 'emailRegistration' | 'emailWhitelist' | 'singleSignOn' | 'none' = 'none';
  if (findAccessCodePolicy(webcast?.securityPolicies)?.enabled) accessPolicyType = 'accessCode';
  if (findEmailRegistrationPolicy(webcast?.securityPolicies)?.enabled) accessPolicyType = 'emailRegistration';
  if (findEmailWhitelistPolicy(webcast?.securityPolicies)?.enabled) accessPolicyType = 'emailWhitelist';
  if (findSingleSignOnPolicy(webcast?.securityPolicies)?.enabled) accessPolicyType = 'singleSignOn';

  const ecdnAvailable = webcast?.ecdnSettings !== null;
  const ecdnEnabled = webcast?.ecdnSettings?.isActive ? 'enabled' : 'disabled';
  const accessCodePolicy = findAccessCodePolicy(webcast?.securityPolicies);
  const multiLanguages = webcast?.additionalLanguages && webcast.additionalLanguages.length > 0;

  return (
    <section className={styles.wrapper}>
      <div className={styles.infoWrapper}>
        <span>{t('manager.webcastSetup.summary.basicInfo.titleLabel')}:</span>
        <span data-testid="event-title">{title}</span>
      </div>

      <div className={styles.infoWrapper}>
        <span>{t('manager.webcastSetup.summary.basicInfo.startDateLabel')}:</span>
        <span data-testid="event-start-date">
          {formatEventStartDate(webcast?.plannedStartAt, webcast?.durationInMinutes)}
        </span>
      </div>

      {multiLanguages && (
        <div className={styles.infoWrapper}>
          <span>{t('manager.webcastSetup.summary.basicInfo.languageLabel')}:</span>
          <div data-testid="event-languages" className={styles.language}>
            <Language language={webcast?.primaryLanguage} />
            {webcast?.additionalLanguages.map((language) => <Language key={language} language={language} />)}
          </div>
        </div>
      )}

      <div className={styles.infoWrapper}>
        <span>{t('manager.webcastSetup.summary.basicInfo.messagingLabel')}:</span>
        <span data-testid="event-messaging">
          {t(`manager.webcastSetup.summary.basicInfo.messagingOptions.${webcast?.chat?.type || 'none'}`)}
        </span>
      </div>

      {!accessCodePolicy?.enabled && (
        <div className={styles.infoWrapper}>
          <span>{t('manager.webcastSetup.summary.basicInfo.accessLabel')}:</span>
          <span data-testid="event-access">
            {t(`manager.webcastSetup.summary.basicInfo.accessOptions.${accessPolicyType}`)}
          </span>
        </div>
      )}

      {accessCodePolicy?.enabled && (
        <div className={styles.infoWrapper}>
          <span>{t('manager.webcastSetup.summary.basicInfo.accessOptions.accessCode')}:</span>
          <div data-testid="event-access-code" className={styles.accessCode}>
            {accessCodePolicy.accessCode}
            <CopyToClipboard value={accessCodePolicy.accessCode} notificationPosition="right" />
          </div>
        </div>
      )}

      {ecdnAvailable && (
        <div data-testid="ecdn" className={styles.infoWrapper}>
          <span>{t('manager.webcastSetup.summary.basicInfo.ecdnLabel')}:</span>
          <span data-testid="event-ecdn">{t(`manager.webcastSetup.summary.basicInfo.ecdnOptions.${ecdnEnabled}`)}</span>
        </div>
      )}
    </section>
  );
}
