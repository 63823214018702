import { useRef, useState, Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { FullscreenButton } from '../../fullscreen-button';
import { MobileOverlay } from '../../mobile-overlay';
import { Subtitles } from '../../subtitles';
import { Timeline } from '../../timeline';
import { Timer } from '../../timer';
import { Trimmer } from '../../trimmer';
import { SettingsMenu, SettingsMenuButton } from '../../settings-menu';
import { Spinner } from '../../spinner/spinner';
import { WatermarkOverlay } from '../../watermark-overlay';
import { useHover } from '../../../hooks/use-hover';
import { useMouseMoving } from '../../../hooks/use-mouse-moving';
import { useDynamicSize } from '../../../hooks/use-dynamic-size';
import { useElementsVisibilities } from '../hooks/use-elements-visibilities';
import { AudioAnimationOverlay } from '../../audio-animation-overlay';
import { VodUIMobileProps } from '../user-interface.types';

import styles from '../user-interfaces.module.css';
import { LanguageMenuButton, LanguageMenu } from '../../language-menu';
import { DownloadSourcesMenu, DownloadSourcesMenuButton } from '../../download-sources-menu';

export function VodUIMobile({
  elementVisibilitySettings = {},
  breakpoints = [],
  isPlaying = false,
  isStopped = true,
  logoUrl = '',
  title = '',
  currentTime = 0,
  totalTime = 0,
  bufferPercentage = 0,
  isFullscreen = false,
  audioOnly = false,
  audioOnlyImageUrl = '',
  subtitlesEnabled = false,
  subtitle = '',
  subtitleOptionId = '',
  subtitleOptions = [],
  qualityId = '',
  qualities = [],
  autoQualityOptionAvailable = true,
  playbackSpeedId = '',
  playbackSpeeds = [],
  storyboard,
  downloadSources,
  isTrimmable = false,
  trimStartTime = 0,
  trimEndTime = 0,
  watermark,
  onPlaybackToggle,
  onTimeChange,
  onTimeDeltaChange,
  onFullscreenToggle,
  onSetSubtitle,
  onSetQuality,
  onSetPlaybackSpeed,
  onTrimStartTimeChange,
  onTrimEndTimeChange,
  audioTracks = [],
  audioTrackId = '',
  onSetAudioTrack,
  onOverlayVisibilityChange,
}: VodUIMobileProps) {
  const { t } = useTranslation();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const bottomBarRef = useRef(null);
  const isMouseMovingOverWrapper = useMouseMoving(wrapperRef);
  const isBottomBarHovered = useHover(bottomBarRef);
  const { width: wrapperWidth } = useDynamicSize(wrapperRef);

  const {
    showLogo,
    showTitle,
    showTimeline,
    showVideoLength,
    showSubtitles,
    showSettingsButton,
    showFullscreenButton,
    showPlaybackSpeed,
    showBigPlayButton,
  } = useElementsVisibilities(wrapperWidth, elementVisibilitySettings, breakpoints);

  const [settingsMenuIsOpen, setSettingsMenuIsOpen] = useState(false);
  const [languageMenuIsOpen, setLanguageMenuIsOpen] = useState(false);
  const [dlSourcesMenuMenuIsOpen, setDlSourcesMenuIsOpen] = useState(false);

  const closeDlSourcesMenu = () => {
    setDlSourcesMenuIsOpen(false);
  };

  const toggleDlSourcesMenu = () => {
    setDlSourcesMenuIsOpen((state) => !state);
  };

  const toggleSettingsMenu = () => {
    setSettingsMenuIsOpen((state) => !state);
  };

  const closeSettingsMenu = () => {
    setSettingsMenuIsOpen(false);
  };

  const toggleLanguageMenu = () => {
    setLanguageMenuIsOpen((state) => !state);
  };

  const closeLanguageMenu = () => {
    setLanguageMenuIsOpen(false);
  };

  const onBackButtonClick = () => {
    onTimeDeltaChange?.(-10000);
  };

  const onForwardButtonClick = () => {
    onTimeDeltaChange?.(10000);
  };

  const controlsAreHidden = isPlaying && !isMouseMovingOverWrapper;
  const logoIsVisible = showLogo && logoUrl !== '';
  const titleIsVisible = showTitle && title !== '';
  const downloadSourcesExist = downloadSources && downloadSources.length > 0;
  const timelineIsVisible = showTimeline && totalTime > 0;
  const subtitlesAreVisible = subtitlesEnabled && subtitle !== '';
  const subtitlesHaveSpaceForBottomBar = isBottomBarHovered || !controlsAreHidden;
  const languageMenuIsVisible =
    showSubtitles && ((audioTracks && audioTracks?.length > 0) || subtitleOptions.length > 0);
  const topBarIsVisible =
    logoIsVisible || titleIsVisible || languageMenuIsVisible || showSettingsButton || downloadSourcesExist;

  useEffect(() => {
    onOverlayVisibilityChange?.(controlsAreHidden);
  }, [controlsAreHidden, onOverlayVisibilityChange]);

  return (
    <div
      ref={wrapperRef}
      className={classNames(styles.wrapper, 'mobile', { settingsMenuIsOpen })}
      data-testid="player-wrapper"
      tabIndex={-1}
    >
      <Spinner />

      <WatermarkOverlay watermark={watermark} />

      {topBarIsVisible && (
        <div
          className={classNames(styles.topBar, { [styles.hidden]: !isTrimmable && controlsAreHidden })}
          data-testid="top-bar"
        >
          <div className={styles.topBarLogoTitle}>
            {logoIsVisible && <img src={logoUrl} alt={t('ui.video-logo.alt')} data-testid="video-logo" />}
            {titleIsVisible && <span data-testid="video-title">{title}</span>}
          </div>
          <div className={styles.menuButtons}>
            {languageMenuIsVisible && (
              <>
                <LanguageMenuButton isActive={languageMenuIsOpen} onClick={toggleLanguageMenu} />
                <LanguageMenu
                  isOpen={languageMenuIsOpen}
                  chosenSubtitleId={subtitleOptionId}
                  subtitles={subtitleOptions}
                  subtitlesEnabled={subtitlesEnabled}
                  playerWrapperRef={wrapperRef}
                  bottomBarRef={bottomBarRef}
                  onSetSubtitle={onSetSubtitle}
                  onClose={closeLanguageMenu}
                  audioTracks={audioTracks}
                  onSetAudioTrack={onSetAudioTrack}
                  chosenAudioTrackId={audioTrackId}
                />
              </>
            )}
            {showSettingsButton && (
              <>
                <SettingsMenuButton isActive={settingsMenuIsOpen} onClick={toggleSettingsMenu} />
                <SettingsMenu
                  isOpen={settingsMenuIsOpen}
                  chosenQualityId={qualityId}
                  qualities={qualities}
                  autoQualityOptionAvailable={autoQualityOptionAvailable}
                  chosenPlaybackSpeedId={playbackSpeedId}
                  playbackSpeeds={playbackSpeeds}
                  playerWrapperRef={wrapperRef}
                  hidePlaybackSpeed={!showPlaybackSpeed}
                  onSetQuality={onSetQuality}
                  onSetPlaybackSpeed={onSetPlaybackSpeed}
                  onClose={closeSettingsMenu}
                />
              </>
            )}
            {downloadSourcesExist && (
              <>
                <DownloadSourcesMenuButton isActive={dlSourcesMenuMenuIsOpen} onClick={toggleDlSourcesMenu} />
                <DownloadSourcesMenu
                  options={downloadSources}
                  isOpen={dlSourcesMenuMenuIsOpen}
                  playerWrapperRef={wrapperRef}
                  onClose={closeDlSourcesMenu}
                />
              </>
            )}
          </div>
        </div>
      )}

      {audioOnly && <AudioAnimationOverlay imageUrl={audioOnlyImageUrl} isPlaying={isPlaying} isStopped={isStopped} />}

      <div
        ref={bottomBarRef}
        className={classNames(styles.bottomBar, { [styles.hidden]: !isTrimmable && controlsAreHidden })}
        data-testid="bottom-bar"
      >
        <div className={styles.buttonsSection} data-testid="buttons-bottom-bar">
          {showVideoLength && <Timer currentTime={currentTime} totalTime={totalTime} />}

          <div className={styles.timelineWrapper}>
            {timelineIsVisible && (
              <Suspense fallback={null}>
                {isTrimmable ? (
                  <Trimmer
                    wrapper={wrapperRef.current}
                    isPlaying={isPlaying}
                    startTime={trimStartTime}
                    endTime={trimEndTime}
                    currentTime={currentTime}
                    totalTime={totalTime}
                    storyboard={storyboard}
                    onChange={onTimeChange}
                    onTrimStartTimeChange={onTrimStartTimeChange}
                    onTrimEndTimeChange={onTrimEndTimeChange}
                  />
                ) : (
                  <Timeline
                    currentTime={currentTime}
                    totalTime={totalTime}
                    bufferPercentage={bufferPercentage}
                    liveUI={false}
                    isMobile={true}
                    storyboard={storyboard}
                    onChange={onTimeChange}
                  />
                )}
              </Suspense>
            )}
            {showFullscreenButton && <FullscreenButton isFullscreen={isFullscreen} onClick={onFullscreenToggle} />}
          </div>
        </div>
      </div>

      {subtitlesAreVisible && (
        <Subtitles withSpaceForBottomBar={subtitlesHaveSpaceForBottomBar} wrapperWidth={wrapperWidth}>
          {subtitle}
        </Subtitles>
      )}

      <MobileOverlay
        isPlaying={isPlaying}
        liveUI={false}
        isHidden={controlsAreHidden}
        onPlayClick={onPlaybackToggle}
        onBackButtonClick={onBackButtonClick}
        onForwardButtonClick={onForwardButtonClick}
        showBigPlayButton={showBigPlayButton}
      />
    </div>
  );
}
