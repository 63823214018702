import { useRef, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { default as detectMobile } from 'ismobilejs';

import type { MutableRefObject } from 'react';

import { useOnClickOutside } from '../../hooks/use-on-click-outside';
import { useDynamicSize } from '../../hooks/use-dynamic-size';

import styles from './language-menu.module.css';
import { SubtitleOption } from '../settings-menu';
import { LanguageSubMenu } from './language-sub-menu';
import { AudioTrack } from './language-menu.types';
import { useKeyboardMenuFocus } from '../../hooks/use-keyboard-menu-focus';
import { useLanguageMenuItems } from './hooks/use-language-menu-items';
import { CloseIcon } from '../../icons/close';
// import { calculateSubmenuHeight } from './language-menu.utils';

type Props = {
  isOpen?: boolean;
  chosenSubtitleId?: string;
  chosenAudioTrackId?: string;
  subtitles: SubtitleOption[];
  audioTracks: AudioTrack[];
  subtitlesEnabled: boolean;
  playerWrapperRef: MutableRefObject<HTMLElement | null>;
  bottomBarRef: MutableRefObject<HTMLElement | null>;
  onSetSubtitle?: (subtitleId: string) => void;
  onSetAudioTrack?: (audioTrackId: string) => void;
  onClose: () => void;
};

export function LanguageMenu({
  isOpen,
  chosenSubtitleId,
  chosenAudioTrackId,
  subtitles = [],
  audioTracks = [],
  subtitlesEnabled,
  playerWrapperRef,
  bottomBarRef,
  onSetSubtitle,
  onSetAudioTrack,
  onClose,
}: Props) {
  const { t } = useTranslation();

  const wrapperRef = useRef<HTMLDivElement>(null);

  const { height: playerWrapperHeight, width: playerWrapperWidth } = useDynamicSize(playerWrapperRef);
  const { height: bottomBarHeight } = useDynamicSize(bottomBarRef);

  const hasBothMenus = audioTracks.length && subtitles.length;
  const maxMenuHeight = playerWrapperHeight - bottomBarHeight;
  const isSmallScreenDesktop = playerWrapperWidth < 480;

  const { menuItems } = useLanguageMenuItems(audioTracks, subtitles, chosenSubtitleId);

  useOnClickOutside(wrapperRef, onClose, 'language-menu-button');
  useKeyboardMenuFocus(wrapperRef, isOpen, onClose);

  const isPhone = useMemo(() => {
    return process.env.STORYBOOK_BUILD
      ? detectMobile(window.sessionStorage.getItem('storyBookUserAgent') ?? '').phone
      : detectMobile(window.navigator).phone;
  }, []);

  // Set focus on the menu when it's open so that
  // the keydown listener can start capturing key events
  useEffect(() => {
    isOpen && wrapperRef?.current?.focus();
  }, [isOpen]);

  const audioMenuOptions = menuItems.filter((item) => item.type === 'audio');
  const subtitlesMenuOptions = menuItems.filter((item) => item.type === 'subtitle');
  const isMobileUI = isPhone || isSmallScreenDesktop;

  return (
    <div
      ref={wrapperRef}
      tabIndex={-1}
      className={classNames(styles.wrapper, !isOpen && styles.closed, hasBothMenus && styles.bothMenus)}
      style={{ maxHeight: isMobileUI ? '100%' : maxMenuHeight }}
    >
      <div role="menu" className={styles.menuWrapper} data-testid="language-menu">
        {audioTracks && audioTracks.length > 0 && (
          <LanguageSubMenu
            label={t('ui.menu.item.audio')}
            chosenItemId={chosenAudioTrackId || ''}
            menuOptions={audioMenuOptions}
            dataTestId="audiotrack-menu"
            onChooseItem={onSetAudioTrack}
            isOpen={isOpen}
            height={!isMobileUI ? maxMenuHeight : undefined}
          />
        )}
        {subtitles && subtitles.length > 0 && (
          <LanguageSubMenu
            label={t('ui.menu.item.subtitles')}
            chosenItemId={subtitlesEnabled ? chosenSubtitleId : 'off'}
            menuOptions={subtitlesMenuOptions}
            dataTestId="language-subtitle-menu"
            onChooseItem={onSetSubtitle}
            isOpen={isOpen}
            height={!isMobileUI ? maxMenuHeight : undefined}
          />
        )}
        <div className={styles.close} onClick={onClose}>
          <CloseIcon />
        </div>
      </div>
    </div>
  );
}
