import { MutableRefObject, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { default as detectMobile } from 'ismobilejs';
import { useDynamicSize } from '../../hooks/use-dynamic-size';
import { useKeyboardMenuFocus } from '../../hooks/use-keyboard-menu-focus';
import { useOnClickOutside } from '../../hooks/use-on-click-outside';
import { downloadFile, formatFileSize, noop } from '../../utils';
import { DownloadIcon } from '../../icons/download';
import { DownloadSourcesMenuButtonName } from './download-sources-menu-button';
import { CloseIcon } from '../../icons/close';

import styles from './download-sources-menu.module.css';

type DownloadSourcesMenuItem = {
  id: string;
  label: string;
  fileSize: number;
  url: string;
};

type DownloadSourcesMenuProps = {
  isOpen?: boolean;
  options: DownloadSourcesMenuItem[];
  playerWrapperRef: MutableRefObject<HTMLElement | null>;
  onClose?: () => void;
};

export function DownloadSourcesMenu({ isOpen, options, playerWrapperRef, onClose }: DownloadSourcesMenuProps) {
  const { t, i18n } = useTranslation();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { height: playerWrapperHeight, width: playerWrapperWidth } = useDynamicSize(playerWrapperRef);
  const maxMenuHeight = playerWrapperHeight * 0.68; // 68% of the player's height
  const isSmallScreenDesktop = playerWrapperWidth < 480;
  const isPhone = useMemo(() => {
    return process.env.STORYBOOK_BUILD
      ? detectMobile(window.sessionStorage.getItem('storyBookUserAgent') ?? '').phone
      : detectMobile(window.navigator).phone;
  }, []);
  const isMobileUI = isPhone || isSmallScreenDesktop;

  useKeyboardMenuFocus(wrapperRef, isOpen, onClose);
  useOnClickOutside(wrapperRef, onClose || noop, DownloadSourcesMenuButtonName);

  const handleMenuItemClick = (option: DownloadSourcesMenuItem) => {
    downloadFile(option.url);
    onClose?.();
  };

  return (
    <div
      ref={wrapperRef}
      role="menu"
      className={classNames(styles.wrapper, { [styles.closed]: !isOpen })}
      tabIndex={-1}
      data-testid="download-sources-menu"
    >
      <div className={styles.menuHeading} data-testid="menu-heading">
        {t('ui.menu.item.downloads')}
      </div>

      <div
        className={classNames(styles.scroll, 'player-scrollbar')}
        style={{ maxHeight: isMobileUI ? '100%' : maxMenuHeight || undefined }}
        tabIndex={-1}
      >
        <div className={styles.menu} tabIndex={-1}>
          {options.map((option) => (
            <button
              type="button"
              role="menuitem"
              key={option.id}
              className={styles.menuItem}
              data-testid={`download-sources-item-${option.id}`}
              onClick={() => handleMenuItemClick(option)}
            >
              <div className={styles.labelWrapper}>
                <div className={styles.label}>{option.label}</div>
                <div className={styles.labelDetails}>
                  ({formatFileSize(option.fileSize, i18n.language)})
                  <DownloadIcon />
                </div>
              </div>
            </button>
          ))}
        </div>
      </div>

      <div className={styles.close} onClick={onClose}>
        <CloseIcon />
      </div>
    </div>
  );
}
