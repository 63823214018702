import type { RtmpParametersInput, UpdateWebcastInput } from '../../../../generated/graphql-manager';

import {
  Heading,
  InfoCircleIcon,
  Paragraph,
  Radio,
  SelectionInputLayout,
  Toggle,
  Tooltip,
} from '@movingimage-evp/mi-ui-component-library';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  IngestProtocol,
  State,
  useGetWebcastProtocolQuery,
  useUpdateWebcastProtocolMutation,
} from '../../../../generated/graphql-manager';
import { useAbsoluteRoutes } from '../../../../routes';
import { SetupPageFooter } from '../../../components/setup-page-footer';
import { useUserPermissions } from '../../../hooks/user-permissions';
import managerStyles from '../../../manager.module.css';

import styles from './protocol-settings.module.css';

export function ProtocolSettingsPage() {
  const { t } = useTranslation();
  const { webcastId = '' } = useParams();
  const routes = useAbsoluteRoutes();
  const { isEventEditingAllowed } = useUserPermissions();

  const { data, loading } = useGetWebcastProtocolQuery({
    variables: { webcastId },
    skip: !webcastId,
  });

  const ecdnAvailable = !loading && !!data?.webcast.ecdnSettings;
  const isMultilanguage = Number(data?.webcast?.additionalLanguages?.length) > 0;
  const aiSubtitlesEnabled = data?.webcast?.subtitleSettings?.aiLiveTranscriptGeneration === true;

  const ingestProtocol = data?.webcast?.streaming?.live?.ingestProtocol;
  const defaultIngestProtocol = IngestProtocol.RTMP;
  const [selectedProtocol, setSelectedProtocol] = useState(ingestProtocol || defaultIngestProtocol);
  const streamingParameters = data?.webcast?.streaming?.live?.parameters;
  const rtmpAuthenticationEnabled =
    streamingParameters?.__typename === 'RtmpLiveStreamingParameters' && streamingParameters?.authenticationEnabled;

  useEffect(() => {
    if (ingestProtocol) setSelectedProtocol(ingestProtocol);
  }, [ingestProtocol]);

  const [updateWebcastProtocol, updateWebcastProtocolResponse] = useUpdateWebcastProtocolMutation();

  function handleProtocolChange(ingestProtocol: IngestProtocol, authenticationEnabled?: boolean) {
    const input: UpdateWebcastInput = { id: webcastId };

    if (ingestProtocol === IngestProtocol.RTMP) {
      const parameters: RtmpParametersInput = {};

      if (authenticationEnabled !== undefined) {
        parameters.authenticationEnabled = authenticationEnabled;
      }

      input.ingest = { rtmp: { parameters } };
    }

    if (ingestProtocol === IngestProtocol.SRT) {
      input.ingest = { srt: { parameters: {} } };
    }

    updateWebcastProtocol({ variables: { input } });
  }

  const changesLoading = updateWebcastProtocolResponse.loading;
  const formDisabled = data?.webcast.state !== State.PRELIVE || !isEventEditingAllowed;

  return (
    <main className={managerStyles.main} data-testid="protocol-settings-page">
      <Heading className={managerStyles.grayText}>{t('manager.webcastSetup.protocolSettings.title')}</Heading>

      <Paragraph className={managerStyles.grayText}>{t('manager.webcastSetup.protocolSettings.description')}</Paragraph>

      <div className={styles.options}>
        <SelectionInputLayout checked={selectedProtocol === IngestProtocol.RTMP} disabled={formDisabled}>
          <div className={styles.option}>
            <Radio
              data-testid="rtmp-radio"
              name="protocol"
              value={IngestProtocol.RTMP}
              checked={selectedProtocol === IngestProtocol.RTMP}
              disabled={formDisabled}
              onChange={() => handleProtocolChange(IngestProtocol.RTMP)}
            >
              {t('manager.webcastSetup.protocolSettings.options.rtmps.label')}
            </Radio>

            <Tooltip label={t('manager.webcastSetup.protocolSettings.tooltip.rtmps.title')} delay={0}>
              <InfoCircleIcon className={styles.infoIcon} />
            </Tooltip>
          </div>
        </SelectionInputLayout>

        {ingestProtocol === IngestProtocol.RTMP && (
          <SelectionInputLayout
            disabled={formDisabled}
            className={styles.inputLayout}
            hintMessage={<span>{t('manager.webcastSetup.protocolSettings.options.rtmps.authentication.hint')}</span>}
          >
            <Toggle
              data-testid="rtmp-authentication-toggle"
              checked={rtmpAuthenticationEnabled}
              disabled={formDisabled}
              onChange={() => handleProtocolChange(IngestProtocol.RTMP, !rtmpAuthenticationEnabled)}
            >
              {t('manager.webcastSetup.protocolSettings.options.rtmps.authentication.label')}
            </Toggle>
          </SelectionInputLayout>
        )}

        <SelectionInputLayout
          checked={selectedProtocol === IngestProtocol.SRT}
          disabled={formDisabled || isMultilanguage || aiSubtitlesEnabled}
        >
          <div className={styles.option}>
            <Tooltip
              hidden={!isMultilanguage && !aiSubtitlesEnabled}
              label={
                isMultilanguage
                  ? t('manager.webcastSetup.protocolSettings.tooltip.srt.disabledInfo.multilanguage')
                  : t('manager.webcastSetup.protocolSettings.tooltip.srt.disabledInfo.aiSubtitles')
              }
              delay={0}
            >
              <Radio
                data-testid="srt-radio"
                name="protocol"
                value={IngestProtocol.SRT}
                checked={selectedProtocol === IngestProtocol.SRT}
                disabled={formDisabled || isMultilanguage || aiSubtitlesEnabled}
                onChange={() => handleProtocolChange(IngestProtocol.SRT)}
              >
                {t('manager.webcastSetup.protocolSettings.options.srt.label')}
              </Radio>
            </Tooltip>

            <Tooltip label={t('manager.webcastSetup.protocolSettings.tooltip.srt.title')} delay={0}>
              <InfoCircleIcon className={styles.infoIcon} />
            </Tooltip>
          </div>
        </SelectionInputLayout>
      </div>

      <SetupPageFooter
        nextLabel={
          ecdnAvailable
            ? t('manager.webcastSetup.protocolSettings.nextStep.labelEcdn')
            : t('manager.webcastSetup.protocolSettings.nextStep.labelSummary')
        }
        route={ecdnAvailable ? routes.webcastSetup_ecdn : routes.webcastSetup_setupSummary}
        saving={changesLoading}
        nextButtonDisabled={loading}
      />
    </main>
  );
}
