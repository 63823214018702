import { i18n, TFunction } from 'i18next';
import { QualityLevelOption, MenuOption, AutoQualityId, SubtitleOption } from './settings-menu.types';

export function getQualityMenuOption(
  t: TFunction<'translation', undefined>,
  qualityOption: QualityLevelOption | undefined
): MenuOption {
  return qualityOption
    ? {
        id: qualityOption.id,
        label: t('ui.menu.item.quality.item', { height: qualityOption.height }),
      }
    : {
        id: AutoQualityId,
        label: t('ui.menu.item.quality.auto'),
      };
}

export function getQualityMenuOptions(
  t: TFunction<'translation', undefined>,
  qualities: QualityLevelOption[],
  autoQualityOptionAvailable?: boolean
): MenuOption[] {
  const qualityItems = [
    ...qualities.map(({ id, height }) => {
      return {
        id,
        label: t('ui.menu.item.quality.item', { height }),
      };
    }),
  ];

  if (autoQualityOptionAvailable) {
    qualityItems.push({
      id: AutoQualityId,
      label: t('ui.menu.item.quality.auto'),
    });
  }

  return qualityItems;
}

export function getSubtitleMenuOption(
  t: TFunction<'translation', undefined>,
  i18n: i18n,
  subtitleOption: SubtitleOption | undefined,
  subtitlesEnabled: boolean | undefined
): MenuOption {
  let option: MenuOption = {
    id: '',
    label: t('ui.subtitles.menu-item.label.off'),
  };

  if (subtitlesEnabled && subtitleOption) {
    const { label, languageCode } = getSubtitleTranslations(t, i18n, subtitleOption);

    option = {
      id: subtitleOption.languageCode,
      label,
      languageCode,
    };
  }

  return option;
}

export function getSubtitleMenuOptions(
  t: TFunction<'translation', undefined>,
  i18n: i18n,
  items: SubtitleOption[]
): MenuOption[] {
  const subtitleItems = items.map((item: SubtitleOption) => {
    const { label, languageCode } = getSubtitleTranslations(t, i18n, item);

    return {
      id: item.id,
      label,
      languageCode,
    };
  });

  if (subtitleItems.length > 0) {
    subtitleItems.unshift({
      id: '',
      label: t('ui.subtitles.menu-item.label.off'),
      languageCode: undefined,
    });
  }

  return subtitleItems;
}

export function getSubtitleTranslations(t: TFunction<'translation', undefined>, i18n: i18n, item: SubtitleOption) {
  const key = `lang.${item.languageCode}`;
  const lowerCaseKey = key.toLowerCase();
  const keyExists = i18n.exists(lowerCaseKey);

  const label = keyExists ? t(lowerCaseKey) : item.englishLabel;
  const languageCode = keyExists ? undefined : 'en';
  return { label, languageCode };
}
