(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-dom/client"), require("video.js"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "react-dom/client", "video.js"], factory);
	else if(typeof exports === 'object')
		exports["main"] = factory(require("react"), require("react-dom/client"), require("video.js"));
	else
		root["main"] = factory(root["react"], root["react-dom/client"], root["video.js"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__9155__, __WEBPACK_EXTERNAL_MODULE__2236__, __WEBPACK_EXTERNAL_MODULE__8324__) => {
return 