/**
 * Converts milliseconds to a more human readable time string.
 * @param {number} time Time in milliseconds.
 * @param {boolean} dynamic Whether to dynamically switch between mm:ss and hh:mm:ss formats.
 * @returns {string} Time in mm:ss or hh:mm:ss format.
 * @example
 * getFormattedTime(60000) // '01:00'
 * getFormattedTime(3600000) // '01:00:00'
 */
export function getFormattedTime(time = 0, dynamic = true): string {
  const milliseconds = isFinite(time) ? time : 0;
  const date = new Date(0);
  date.setMilliseconds(milliseconds);
  const hhmmss = date.toISOString().substr(11, 8);
  return dynamic && hhmmss.startsWith('00') ? hhmmss.substr(3) : hhmmss;
}

/**
 * Converts milliseconds to seconds
 * @param {number} ms Time in milliseconds.
 * @returns {number} Time in seconds.
 */
export function msToS(ms = 0): number {
  return isFinite(ms) ? ms / 1000 : 0;
}

/**
 * Converts seconds to milliseconds
 * @param {number} ms Time in seconds.
 * @returns {number} Time in milliseconds.
 */
export function sToMs(s = 0): number {
  return isFinite(s) ? Math.floor(s * 1000) : 0;
}
