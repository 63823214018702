import type { UpdateEmailItemsInput } from '../../../../generated/graphql-manager';
import type { ChangeEvent } from 'react';

import { NetworkStatus } from '@apollo/client';
import {
  CopyToClipboard,
  Heading,
  InfoCircleIcon,
  InputDescription,
  Notification,
  Paragraph,
  PrimaryButton,
  Radio,
  SelectionInputLayout,
  Spinner,
  TextInput,
  Toggle,
  Tooltip,
  classNames,
} from '@movingimage-evp/mi-ui-component-library';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { EmailWhitelistModal } from './email-whitelist-modal';
import { EmailsList } from './emails-list';
import { validateAccessCode } from './validate-access-code';
import {
  ItemListAction,
  State,
  useDisableSingleSignOnPolicyMutation,
  useEnableSingleSignOnPolicyMutation,
  useGetWebcastSecurityQuery,
  useUpdateAccessCodePolicyMutation,
  useUpdateEmailRegistrationPolicyMutation,
  useUpdateEmailWhitelistPolicyMutation,
} from '../../../../generated/graphql-manager';
import { useAbsoluteRoutes } from '../../../../routes';
import {
  findAccessCodePolicy,
  findEmailRegistrationPolicy,
  findEmailWhitelistPolicy,
  findSingleSignOnPolicy,
} from '../../../../utils/graphql-helpers';
import { SetupPageFooter } from '../../../components/setup-page-footer';
import { useCurrentUser } from '../../../hooks/current-user';
import { useUserPermissions } from '../../../hooks/user-permissions';
import managerStyles from '../../../manager.module.css';

import styles from './viewer-access.module.css';

export type SecuritySettingsData = {
  sharingUrl: string;
  securedLink: boolean;
  accessCode: string;
};

export function ViewerAccessPage() {
  const { t } = useTranslation();
  const { lsproId } = useCurrentUser();
  const { isEventEditingAllowed } = useUserPermissions();
  const { webcastId = '' } = useParams();
  const routes = useAbsoluteRoutes();

  const { data, networkStatus, refetch } = useGetWebcastSecurityQuery({
    variables: { lsproId, webcastId },
    skip: !lsproId || !webcastId,
    notifyOnNetworkStatusChange: true,
  });

  const state = data?.webcast.state;
  const idps = data?.identityProviders;
  const securityPolicies = data?.webcast.securityPolicies;

  const accessCodePolicy = findAccessCodePolicy(securityPolicies);
  const singleSignOnPolicy = findSingleSignOnPolicy(securityPolicies);
  const emailRegistrationPolicy = findEmailRegistrationPolicy(securityPolicies);
  const emailWhitelistPolicy = findEmailWhitelistPolicy(securityPolicies);

  const [emailWhitelistModalOpen, setEmailWhitelistModalOpen] = useState(false);
  const [numberOfEmailsAdded, setNumberOfEmailsAdded] = useState<number>();

  const whitelistedEmails = emailWhitelistPolicy?.emails || [];

  const [updateAccessCodePolicyMutation, { loading: updateAccessCodePolicyLoading }] =
    useUpdateAccessCodePolicyMutation();

  const [enableSingleSignOnPolicyMutation, { loading: enableSingleSignOnPolicyLoading }] =
    useEnableSingleSignOnPolicyMutation();

  const [disableSingleSignOnPolicyMutation, { loading: disableSingleSignOnPolicyLoading }] =
    useDisableSingleSignOnPolicyMutation();

  const [updateEmailRegistrationPolicyMutation, { loading: updateEmailRegistrationPolicyLoading }] =
    useUpdateEmailRegistrationPolicyMutation();

  const [updateEmailWhitelistPolicyMutation, { loading: updateEmailWhitelistPolicyLoading }] =
    useUpdateEmailWhitelistPolicyMutation();

  const mutationInProgress = [
    updateAccessCodePolicyLoading,
    enableSingleSignOnPolicyLoading,
    disableSingleSignOnPolicyLoading,
    updateEmailRegistrationPolicyLoading,
    updateEmailWhitelistPolicyLoading,
  ].some(Boolean);

  const disableOtherPolicies = async (
    newPolicy?: 'AccessCodePolicy' | 'SingleSignOnPolicy' | 'EmailRegistrationPolicy' | 'EmailWhitelistPolicy'
  ) => {
    if (newPolicy !== 'AccessCodePolicy' && accessCodePolicy?.enabled) {
      await updateAccessCodePolicy({ enabled: false });
    }

    if (newPolicy !== 'EmailRegistrationPolicy' && emailRegistrationPolicy?.enabled) {
      await updateEmailRegistrationPolicy({ enabled: false, requireNameFields: false });
    }

    if (newPolicy !== 'SingleSignOnPolicy' && singleSignOnPolicy?.enabled) {
      await disableSingleSignOnPolicy();
    }

    if (newPolicy !== 'EmailWhitelistPolicy' && emailWhitelistPolicy?.enabled) {
      await updateEmailWhitelistPolicy({ enabled: false, requireNameFields: false });
    }
  };

  const updateAccessCodePolicy = async ({
    enabled = Boolean(accessCodePolicy?.enabled),
    accessCode = accessCodePolicy?.accessCode || '',
  }: { enabled?: boolean; accessCode?: string } = {}) => {
    if (mutationInProgress) return;

    if (enabled) await disableOtherPolicies('AccessCodePolicy');

    return updateAccessCodePolicyMutation({
      variables: {
        input: {
          webcastId,
          enabled,
          accessCode,
        },
      },
      update: (_, { data }) => {
        if (data?.updateAccessCodePolicy.__typename === 'ValidationErrors') {
          setCustomAccessCodeValidationError(data.updateAccessCodePolicy?.errors?.[0]?.message);
        }

        if (data?.updateAccessCodePolicy.__typename === 'UpdateAccessCodePolicySuccess') {
          setCustomAccessCodeValidationError(undefined);
        }
      },
      onCompleted: () => refetch(),
    });
  };

  const enableSingleSignOnPolicy = async () => {
    if (mutationInProgress || !idps) return;

    await disableOtherPolicies('SingleSignOnPolicy');

    return enableSingleSignOnPolicyMutation({
      variables: {
        input: {
          webcastId,
          idps: idps.map(({ alias }) => alias),
        },
      },
      onCompleted: () => refetch(),
    });
  };

  const disableSingleSignOnPolicy = () => {
    if (mutationInProgress) return;

    return disableSingleSignOnPolicyMutation({
      variables: { input: { webcastId } },
      onCompleted: () => refetch(),
    });
  };

  const updateEmailRegistrationPolicy = async ({
    enabled = Boolean(emailRegistrationPolicy?.enabled),
    requireNameFields = Boolean(emailRegistrationPolicy?.requireNameFields),
  }: { enabled?: boolean; requireNameFields?: boolean } = {}) => {
    if (mutationInProgress) return;

    if (enabled) await disableOtherPolicies('EmailRegistrationPolicy');

    return updateEmailRegistrationPolicyMutation({
      variables: {
        input: {
          webcastId,
          enabled,
          requireNameFields: enabled ? requireNameFields : false,
        },
      },
      onCompleted: () => refetch(),
    });
  };

  const updateEmailWhitelistPolicy = async ({
    enabled,
    requireNameFields,
  }: { enabled?: boolean; requireNameFields?: boolean } = {}) => {
    if (mutationInProgress) return;

    if (enabled) await disableOtherPolicies('EmailWhitelistPolicy');

    return updateEmailWhitelistPolicyMutation({
      variables: {
        input: {
          webcastId,
          enabled: enabled ?? null,
          emails: null,
          requireNameFields: requireNameFields ?? null,
        },
      },
      onCompleted: () => refetch(),
    });
  };

  const addWhitelistEmails = (emails: string[]) => {
    if (mutationInProgress) return;

    const lowercaseEmails = emails.map((email) => email.toLowerCase());
    const updateEmailItems: UpdateEmailItemsInput = {
      action: ItemListAction.ADD,
      items: lowercaseEmails,
    };

    return updateEmailWhitelistPolicyMutation({
      variables: {
        input: {
          webcastId,
          enabled: null,
          emails: [updateEmailItems],
          requireNameFields: null,
        },
      },
      onCompleted: () => {
        setNumberOfEmailsAdded(lowercaseEmails.length);
        refetch();
      },
    });
  };

  const removeWhitelistEmails = (emails: string[]) => {
    if (mutationInProgress) return;

    const lowercaseEmails = emails.map((email) => email.toLowerCase());
    const updateEmailItems: UpdateEmailItemsInput = {
      action: ItemListAction.REMOVE,
      items: lowercaseEmails,
    };

    return updateEmailWhitelistPolicyMutation({
      variables: {
        input: {
          webcastId,
          enabled: null,
          emails: [updateEmailItems],
          requireNameFields: null,
        },
      },
      onCompleted: () => refetch(),
    });
  };

  const [customAccessCode, setCustomAccessCode] = useState(accessCodePolicy?.accessCode || '');
  const [customAccessCodeValidationError, setCustomAccessCodeValidationError] = useState<string>();

  useEffect(() => {
    if (accessCodePolicy?.enabled) setCustomAccessCode(accessCodePolicy?.accessCode || '');
  }, [accessCodePolicy?.enabled, accessCodePolicy?.accessCode]);

  const handleAccessCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCustomAccessCodeValidationError(undefined);
    setCustomAccessCode(event.target.value.trim());
  };

  const saveCustomAccessCode = () => {
    const error = validateAccessCode(customAccessCode);

    if (error) return setCustomAccessCodeValidationError(error);

    updateAccessCodePolicy({ accessCode: customAccessCode });
  };

  const loading = networkStatus === NetworkStatus.loading;
  const refetching = networkStatus === NetworkStatus.refetch;
  const showActiveSaveButton = accessCodePolicy?.enabled && customAccessCode !== accessCodePolicy?.accessCode;
  const formDisabled = state !== State.PRELIVE || !isEventEditingAllowed || mutationInProgress || refetching;
  const publicEventChecked = [accessCodePolicy, singleSignOnPolicy, emailWhitelistPolicy].every(
    (policy) => !policy?.enabled
  );

  return (
    <main className={classNames(managerStyles.main, styles.wrapper)} data-testid="viewer-access-page">
      <Heading className={managerStyles.grayText}>{t('manager.webcastSetup.viewerAccess.title')}</Heading>
      <Paragraph className={managerStyles.grayText}>{t('manager.webcastSetup.viewerAccess.heading')}</Paragraph>

      {loading && <Spinner style={{ margin: 'auto' }} />}

      {!loading && (
        <fieldset className={styles.options} disabled={formDisabled}>
          <SelectionInputLayout
            hintMessage={t('manager.webcastSetup.viewerAccess.options.off.description')}
            checked={publicEventChecked}
          >
            <Radio
              data-testid="no-security-policy-radio"
              name="viewer-access-type"
              checked={publicEventChecked}
              onChange={() => disableOtherPolicies()}
            >
              {t('manager.webcastSetup.viewerAccess.options.off.label')}
            </Radio>

            {publicEventChecked && (
              <div className={classNames(styles.expandableSection, formDisabled && styles.disabled)}>
                <div className={styles.expandableSectionLabel}>
                  {t('manager.webcastSetup.viewerAccess.options.off.inputLabel')}

                  <Tooltip label={t('manager.webcastSetup.viewerAccess.options.off.hint')} position="above" delay={0}>
                    <InfoCircleIcon />
                  </Tooltip>
                </div>

                <div className={styles.expandableSectionOption}>
                  <span>{t('manager.webcastSetup.viewerAccess.options.off.fields.email')}</span>

                  <Toggle
                    data-testid="no-security-policy-require-email-toggle"
                    checked={emailRegistrationPolicy?.enabled === true}
                    onChange={() => updateEmailRegistrationPolicy({ enabled: !emailRegistrationPolicy?.enabled })}
                  />
                </div>

                <Tooltip label={t('manager.webcastSetup.viewerAccess.options.off.disabledTooltip')}>
                  <div className={styles.expandableSectionOption}>
                    <span className={classNames(emailRegistrationPolicy?.enabled === false && styles.disabled)}>
                      {t('manager.webcastSetup.viewerAccess.options.off.fields.name')}
                    </span>

                    <Toggle
                      data-testid="no-security-policy-require-name-fields-toggle"
                      checked={emailRegistrationPolicy?.requireNameFields === true}
                      disabled={!emailRegistrationPolicy?.enabled}
                      onChange={() =>
                        updateEmailRegistrationPolicy({
                          requireNameFields: !emailRegistrationPolicy?.requireNameFields,
                        })
                      }
                    />
                  </div>
                </Tooltip>
              </div>
            )}
          </SelectionInputLayout>

          <SelectionInputLayout
            hintMessage={t('manager.webcastSetup.viewerAccess.options.accessCode.description')}
            checked={accessCodePolicy?.enabled === true}
          >
            <Radio
              data-testid="access-code-radio"
              name="viewer-access-type"
              checked={accessCodePolicy?.enabled === true}
              onChange={() => updateAccessCodePolicy({ enabled: true })}
            >
              {t('manager.webcastSetup.viewerAccess.options.accessCode.label')}
            </Radio>

            {accessCodePolicy?.enabled && (
              <div className={styles.expandableSection}>
                <InputDescription
                  inputId="access-code-input"
                  label={t('manager.webcastSetup.viewerAccess.options.accessCode.inputLabel')}
                  helpMessage={t('manager.webcastSetup.viewerAccess.options.accessCode.hint')}
                  errorMessage={customAccessCodeValidationError}
                >
                  <TextInput data-testid="access-code-input" value={customAccessCode} onChange={handleAccessCodeChange}>
                    <CopyToClipboard value={customAccessCode} />
                  </TextInput>
                </InputDescription>
              </div>
            )}
          </SelectionInputLayout>

          <SelectionInputLayout
            hintMessage={t('manager.webcastSetup.viewerAccess.options.emailWhitelist.description')}
            checked={emailWhitelistPolicy?.enabled === true}
          >
            <Radio
              data-testid="email-whitelist-radio"
              name="viewer-access-type"
              checked={emailWhitelistPolicy?.enabled === true}
              onChange={() => updateEmailWhitelistPolicy({ enabled: true })}
            >
              {t('manager.webcastSetup.viewerAccess.options.emailWhitelist.label')}
            </Radio>

            {emailWhitelistPolicy?.enabled && (
              <div className={classNames(styles.expandableSection, formDisabled && styles.disabled)}>
                <div className={styles.expandableSectionLabel}>
                  {t('manager.webcastSetup.viewerAccess.options.emailWhitelist.inputLabel')}

                  <Tooltip
                    label={t('manager.webcastSetup.viewerAccess.options.emailWhitelist.hint')}
                    position="above"
                    delay={0}
                  >
                    <InfoCircleIcon />
                  </Tooltip>
                </div>

                <div className={styles.expandableSectionOption}>
                  <span>{t('manager.webcastSetup.viewerAccess.options.emailWhitelist.fields.name')}</span>

                  <Toggle
                    data-testid="email-whitelist-require-name-fields-toggle"
                    checked={emailWhitelistPolicy?.requireNameFields === true}
                    onChange={() =>
                      updateEmailWhitelistPolicy({ requireNameFields: !emailWhitelistPolicy?.requireNameFields })
                    }
                  />
                </div>

                <div className={styles.expandableSectionLabel}>
                  {t('manager.webcastSetup.viewerAccess.options.emailWhitelist.attendeeList.description')}
                </div>

                <div className={styles.expandableSectionOption}>
                  {whitelistedEmails.length === 0 && (
                    <PrimaryButton
                      data-testid="add-attendees-button"
                      type="button"
                      small
                      onClick={() => setEmailWhitelistModalOpen(true)}
                    >
                      {t('manager.webcastSetup.viewerAccess.options.emailWhitelist.attendeeList.addAttendees')}
                    </PrimaryButton>
                  )}

                  <EmailsList
                    emails={whitelistedEmails}
                    onAddAttendees={() => setEmailWhitelistModalOpen(true)}
                    onRemoveEmail={(email) => removeWhitelistEmails([email])}
                    onRemoveAll={() => removeWhitelistEmails(whitelistedEmails)}
                  />
                </div>
              </div>
            )}
          </SelectionInputLayout>

          {idps && idps.length > 0 && (
            <SelectionInputLayout
              hintMessage={t('manager.webcastSetup.viewerAccess.options.sso.description')}
              checked={singleSignOnPolicy?.enabled === true}
            >
              <Radio
                data-testid="single-sign-on-radio"
                name="viewer-access-type"
                checked={singleSignOnPolicy?.enabled === true}
                onChange={() => enableSingleSignOnPolicy()}
              >
                {t('manager.webcastSetup.viewerAccess.options.sso.label')}
              </Radio>
            </SelectionInputLayout>
          )}
        </fieldset>
      )}

      <SetupPageFooter
        nextLabel={t('manager.webcastSetup.viewerAccess.nextStep.label')}
        route={routes.webcastSetup_sharingAndEmbedding}
        saving={mutationInProgress}
        showActiveSaveButton={showActiveSaveButton}
        saveButtonDisabled={customAccessCodeValidationError !== undefined}
        onSaveButtonClick={saveCustomAccessCode}
      />

      <EmailWhitelistModal
        isOpen={emailWhitelistModalOpen}
        onConfirm={addWhitelistEmails}
        onClose={() => setEmailWhitelistModalOpen(false)}
      />

      {numberOfEmailsAdded && (
        <Notification
          data-testid="emails-added-notification"
          status="success"
          text={t('manager.webcastSetup.viewerAccess.options.emailWhitelist.numberOfEmailsAdded', {
            count: numberOfEmailsAdded,
          })}
          className={styles.emailsAddedNotification}
          onAnimationEnd={() => setNumberOfEmailsAdded(undefined)}
        />
      )}
    </main>
  );
}
