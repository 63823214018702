import { CueData } from 'components/player/player.config';

interface WebkitDataCue extends TextTrackCue {
  type: string;
  value: {
    data: string;
  };
}

interface MSEDataCue extends TextTrackCue {
  value: {
    data: Uint8Array | string;
  };
}

const isWebkitDataCueType = (obj: any): obj is WebkitDataCue => {
  return obj?.type === 'org.id3';
};

const isMSEDataCueType = (obj: any): obj is MSEDataCue => {
  return !!obj?.value?.data;
};

export const parseDataCues = (cues: TextTrackCueList): CueData[] => {
  const cueDatas: CueData[] = [];

  Array.from(cues).forEach((cue) => {
    if (isMSEDataCueType(cue)) {
      const value = getDecodedValue(cue.value.data);

      cueDatas.push({
        value,
        start: cue.startTime,
        end: cue.endTime,
      });
    }
  });

  return cueDatas;
};

export const parseWebkitDataCues = (cues: TextTrackCueList): CueData[] => {
  const cueDatas: CueData[] = [];

  Array.from(cues).forEach((cue) => {
    if (isWebkitDataCueType(cue)) {
      cueDatas.push({
        value: cue.value.data,
        start: cue.startTime,
        end: cue.endTime,
      });
    }
  });

  return cueDatas;
};

/**
 * Decodes a given data into a string. If the data is already a string, it returns the data as is.
 * If the data is a Uint8Array, it decodes the data using TextDecoder and removes any null and end of text characters.
 * If an error occurs during the process, it returns an empty string.
 *
 * @param {string | Uint8Array} data - The data to be decoded.
 * @returns {string} The decoded string or an empty string if an error occurs.
 */
function getDecodedValue(data: string | Uint8Array): string {
  try {
    if (typeof data === 'string') {
      return data;
    } else {
      const decoder = new TextDecoder();
      // eslint-disable-next-line no-control-regex
      return decoder.decode(data).replace(/\x03|\x00/g, '');
    }
  } catch (e) {
    return '';
  }
}
