import type { HiveToken } from '../../../../../generated/graphql-viewer';
import type { LsproPlayerConfig } from '../../use-player-config';
import type { PlayerApi, TimedMetadataEvent } from '@movingimage-evp/player-library';
import type { HTMLAttributes } from 'react';

import { Player, PlayerEvent } from '@movingimage-evp/player-library';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSelectedLanguage } from '../../../../providers/selected-language-provider';
import { usePlayerConfig } from '../../use-player-config';
import { UnmutePopup } from '../unmute-popup';

type ViewerPlayerProps = {
  webcastId: string;
  title?: string;
  accountId?: string;
  lsproId?: string;
  primaryColor?: string;
  logoUrl?: string;
  muted?: boolean;
  autoplay?: boolean;
  currentStream?: {
    playback: {
      primaryUrl: string;
      backupUrl?: string | null;
      token: string;
      ecdnSettings?: {
        hive?: {
          primary: HiveToken;
          backup?: HiveToken | null;
        } | null;
      } | null;
    };
  };
  onTimedMetadata?: (event: TimedMetadataEvent) => void;
  onPlayerRetry?: () => void;
  onOverlayVisibilityChange?: (visible: boolean) => void;
} & HTMLAttributes<HTMLDivElement>;

export function ViewerPlayer({
  webcastId,
  title,
  accountId,
  lsproId,
  primaryColor,
  logoUrl,
  currentStream,
  muted = false,
  autoplay,
  onTimedMetadata,
  onPlayerRetry,
  onOverlayVisibilityChange,
  ...props
}: ViewerPlayerProps) {
  const { t } = useTranslation();
  const timeoutRef = useRef(0);
  const [shouldUseBackupUrl, setShouldUseBackupUrl] = useState(false);
  const [delayActive, setDelayActive] = useState(false);
  const [unmutePopupVisible, setUnmutePopupVisible] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const { selectedLanguage } = useSelectedLanguage();

  const streamPlaybackUrl = currentStream?.playback.primaryUrl || '';
  const streamBackupPlaybackUrl = currentStream?.playback.backupUrl || '';
  const streamPlaybackToken = currentStream?.playback.token ? `?hdnts=${currentStream?.playback.token}` : '';
  const playbackUrl = `${shouldUseBackupUrl ? streamBackupPlaybackUrl : streamPlaybackUrl}${streamPlaybackToken}`;

  const hiveConfiguration = !shouldUseBackupUrl
    ? currentStream?.playback.ecdnSettings?.hive?.primary
    : currentStream?.playback.ecdnSettings?.hive?.backup;

  const lsproPlayerConfig = useMemo(
    (): LsproPlayerConfig => ({
      webcastId,
      contentId: webcastId,
      playbackUrl,
      title,
      accountId,
      lsproId,
      primaryColor,
      logoUrl,
      muted,
      autoplay,
      hiveConfiguration,
      state: 'Live',
      language: selectedLanguage,
    }),
    [
      accountId,
      autoplay,
      hiveConfiguration,
      logoUrl,
      lsproId,
      muted,
      playbackUrl,
      primaryColor,
      selectedLanguage,
      title,
      webcastId,
    ]
  );

  const playerConfig = usePlayerConfig(lsproPlayerConfig);
  const playerApiRef = useRef<PlayerApi | null>(null);

  const handlePlayerError = useCallback(() => {
    // Hide the unmute popup if there is a problem with the stream
    setUnmutePopupVisible(false);

    /**
     * Delay the next request to change the URL by 15 seconds
     * to avoid too many switches in case both URLs are currently unstable/not available
     */
    if (!delayActive) {
      setShouldUseBackupUrl(!shouldUseBackupUrl);
      setDelayActive(true);
      setRetryCount((state) => state + 1);

      timeoutRef.current = window.setTimeout(() => setDelayActive(false), 15000);
    }

    if (retryCount > 0) onPlayerRetry?.();
  }, [delayActive, onPlayerRetry, retryCount, shouldUseBackupUrl]);

  const handleVolumeChange = useCallback(() => {
    if (!playerApiRef.current?.getMuted()) {
      setUnmutePopupVisible(false);
    }
  }, []);

  const playVideo = useCallback(
    async (api: PlayerApi) => {
      try {
        if (api.getPaused()) await api.play();
      } catch {
        try {
          api.setMuted(true);
          setUnmutePopupVisible(true);
          api.addEventListener(PlayerEvent.VOLUME_CHANGE, handleVolumeChange);

          await api.play();
        } catch {
          return;
        }
      }
    },
    [handleVolumeChange]
  );

  useEffect(() => {
    if (!unmutePopupVisible) {
      playerApiRef.current?.removeEventListener(PlayerEvent.VOLUME_CHANGE, handleVolumeChange);
    }
  }, [handleVolumeChange, unmutePopupVisible]);

  const playerReadyHandler = useCallback(
    (api: PlayerApi) => {
      // Player ready event can be called multiple times,
      // so we need to make sure we only add the event listeners once
      if (playerApiRef.current !== api) {
        if (onTimedMetadata) playerApiRef.current?.removeEventListener(PlayerEvent.TIMED_METADATA, onTimedMetadata);
        playerApiRef.current?.removeEventListener(PlayerEvent.ERROR, handlePlayerError);
        setRetryCount(0);
      }

      if (onTimedMetadata) api.addEventListener(PlayerEvent.TIMED_METADATA, onTimedMetadata);
      api.addEventListener(PlayerEvent.ERROR, handlePlayerError);
      playerApiRef.current = api;

      if (autoplay) playVideo(api);
    },
    [autoplay, playVideo, onTimedMetadata, handlePlayerError]
  );

  useEffect(() => () => clearTimeout(timeoutRef.current), []);

  const unmuteVideo = () => {
    setUnmutePopupVisible(false);
    playerApiRef.current?.setMuted(false);
  };

  if (!playerConfig) return null;

  return (
    <div {...props}>
      <Player {...playerConfig} onReady={playerReadyHandler} onOverlayVisibilityChange={onOverlayVisibilityChange} />

      {unmutePopupVisible && (
        <UnmutePopup
          title={t('viewer.views.unmutePopup.title.eventStarted')}
          onClose={() => setUnmutePopupVisible(false)}
          onUnMute={unmuteVideo}
        />
      )}
    </div>
  );
}
