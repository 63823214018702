import type { HTMLAttributes } from 'react';

import {
  CheckIcon,
  ChevronFilledIcon,
  IconButton,
  PictureInPictureIcon,
  Tooltip,
  classNames,
  useOnClickOutside,
} from '@movingimage-evp/mi-ui-component-library';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './layout-changer.module.css';

export type LayoutType = 'sideBySide' | 'pictureInPicture';

type LayoutChangerProps = {
  layoutType: LayoutType;
  onLayoutChange?: (layoutType: LayoutType) => void;
} & HTMLAttributes<HTMLDivElement>;

export function LayoutChanger({ layoutType, className, onLayoutChange }: LayoutChangerProps) {
  const { t } = useTranslation();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useOnClickOutside(wrapperRef, () => setIsMenuOpen(false));

  const handleChange = (layoutType: LayoutType) => {
    setIsMenuOpen(false);
    onLayoutChange?.(layoutType);
  };

  return (
    <div ref={wrapperRef} className={classNames(styles.wrapper, className)}>
      <Tooltip label={t('viewer.views.stream.tooltips.changeLayout')} hidden={isMenuOpen}>
        <IconButton
          data-testid="change-layout-button"
          aria-label={t('viewer.views.stream.tooltips.changeLayout')}
          className={styles.menuButton}
          onClick={() => setIsMenuOpen((state) => !state)}
        >
          <PictureInPictureIcon />
          <span>{t('viewer.views.stream.view')}</span>
          <ChevronFilledIcon />
        </IconButton>
      </Tooltip>

      {isMenuOpen && (
        <div className={styles.menu}>
          <label>
            {t('viewer.views.stream.layout.sideBySide')}

            <svg width="55" height="27">
              <path fill="var(--mi-gray_90)" stroke="var(--mi-gray_20)" d="M.5.5h25v26H.5zM29.5.5h25v26h-25z" />
            </svg>

            <input
              type="radio"
              name="layout"
              value="side-by-side"
              data-testid="side-by-side-layout-radio-button"
              checked={layoutType === 'sideBySide'}
              onChange={() => handleChange('sideBySide')}
            />

            <CheckIcon />
          </label>

          <label>
            {t('viewer.views.stream.layout.pictureInPicture')}

            <svg width="55" height="27">
              <path fill="var(--mi-gray_90)" stroke="var(--mi-gray_20)" d="M.5.5h54v26H.5z" />
              <path fill="var(--mi-gray_90)" stroke="var(--mi-gray_20)" d="M38.1 12.9h12.5v8.8H38.1z" />
            </svg>

            <input
              type="radio"
              name="layout"
              value="picture-in-picture"
              data-testid="picture-in-picture-layout-radio-button"
              checked={layoutType === 'pictureInPicture'}
              onChange={() => handleChange('pictureInPicture')}
            />

            <CheckIcon />
          </label>
        </div>
      )}
    </div>
  );
}
