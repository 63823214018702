import type { ViewerControls } from './mocked-viewer-controls/mocked-viewer-controls';
import type { ViewerState } from './mocked-viewer-state/mocked-viewer-state';
import type { ChatType } from '../generated/graphql-viewer';

import { type ReactNode, createContext, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { MockedViewerChat } from './mocked-viewer-chat/mocked-viewer-chat';
import { MockedViewerControls } from './mocked-viewer-controls/mocked-viewer-controls';
import { MockedViewerState } from './mocked-viewer-state/mocked-viewer-state';
import { pathsWithoutChannelId } from '../routes';
import { FeatureToggle } from '../service/feature-toggle/feature-toggle';

import styles from './dev-tools.module.css';

type DevToolsContextValue = {
  mockedViewerState?: ViewerState;
  mockedViewerChat?: ChatType;
  mockedViewerControls: ViewerControls;
};

const initialDevToolsContext: DevToolsContextValue = {
  mockedViewerState: undefined,
  mockedViewerChat: undefined,
  mockedViewerControls: {
    headerButtonsVisible: false,
    mockedSlide: undefined,
  },
};

export const DevToolsContext = createContext<DevToolsContextValue>(initialDevToolsContext);

export function DevToolsProvider({ children }: { children: ReactNode }) {
  const { pathname } = useLocation();

  const [menuVisible, setMenuVisible] = useState(false);
  const [mockedViewerState, setMockedViewerState] = useState(initialDevToolsContext.mockedViewerState);
  const [mockedViewerChat, setMockedViewerChat] = useState(initialDevToolsContext.mockedViewerChat);
  const [mockedViewerControls, setMockedViewerControls] = useState(initialDevToolsContext.mockedViewerControls);

  const isDevelopment = process.env.REACT_APP_MODE === 'development';
  const isIframePage = pathname.includes(pathsWithoutChannelId.iFrame);

  if (!isDevelopment || isIframePage) return <>{children}</>;

  return (
    <DevToolsContext.Provider value={{ mockedViewerState, mockedViewerChat, mockedViewerControls }}>
      {children}

      <FeatureToggle featureName="devTools">
        <div className={styles.wrapper}>
          {menuVisible && (
            <div className={styles.menus}>
              <MockedViewerState value={mockedViewerState} onChange={setMockedViewerState} />
              <MockedViewerChat value={mockedViewerChat} onChange={setMockedViewerChat} />
              <MockedViewerControls
                value={mockedViewerControls}
                onChange={(name, value) => setMockedViewerControls({ ...mockedViewerControls, [name]: value })}
              />
            </div>
          )}

          <button className={styles.button} onClick={() => setMenuVisible((state) => !state)}>
            ☰
          </button>
        </div>
      </FeatureToggle>
    </DevToolsContext.Provider>
  );
}

export const useDevTools = () => useContext(DevToolsContext);
