import { useRef } from 'react';
import type { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { getFormattedTime } from '../../utils/time';
import { isArrayOfNumbers } from '../../utils/is-array-of-numbers';
import { useCursorPosition } from '../../hooks/use-cursor-position';
import { TimelinePreview } from '../timeline-preview';
import styles from './timeline.module.css';
import commonStyles from '../../styles/common.module.css';
import type { Storyboard } from './timeline.types';

type Props = {
  currentTime?: number;
  totalTime?: number;
  min?: number;
  max?: number;
  step?: number;
  bufferPercentage?: number;
  liveUI?: boolean;
  isMobile?: boolean;
  storyboard?: Storyboard;
  onChange?: (value: number) => void;
};

export function Timeline({
  currentTime = 0,
  totalTime = 100,
  min = 0,
  max = totalTime,
  step = 100,
  bufferPercentage = 0,
  liveUI = false,
  isMobile = false,
  storyboard,
  onChange,
}: Props) {
  const ref = useRef(null);
  const { t } = useTranslation();
  const [{ x: hoverPercentage }] = useCursorPosition(ref, true);

  if (!isArrayOfNumbers([min, max, step, currentTime, totalTime])) return null;

  const timelinePercentage = liveUI ? 100 : max - min <= 0 ? 0 : ((currentTime - min) * 100) / (max - min);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    !liveUI && onChange?.(event.target.valueAsNumber);
  };

  return (
    <div ref={ref} className={styles.wrapper}>
      <div className={styles.indicators}>
        <div style={{ width: `${bufferPercentage * 100}%` }} />
        <div style={{ width: `${hoverPercentage}%` }} />
        <div style={{ width: `${timelinePercentage}%` }} />
      </div>

      <input
        type="range"
        name="timeline-slider"
        aria-label={t('ui.timeline.slider.label')}
        aria-valuemin={min}
        aria-valuemax={max}
        aria-valuenow={currentTime}
        aria-valuetext={`${getFormattedTime(currentTime)} / ${getFormattedTime(totalTime)}`}
        min={min}
        max={liveUI ? 1 : max}
        step={step}
        value={liveUI ? 1 : currentTime}
        onChange={handleChange}
        className={classNames(commonStyles.rangeInput, { [commonStyles.animated]: !isMobile }, styles.input)}
      />

      <TimelinePreview timelineRef={ref} totalTime={totalTime} storyboard={storyboard} />
    </div>
  );
}
