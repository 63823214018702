import type { MouseEvent } from 'react';

import styles from './theme-toggle.module.css';

export function ThemeToggle() {
  const toggleTheme = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    document.body.setAttribute('data-theme', document.body.getAttribute('data-theme') === 'dark' ? 'light' : 'dark');
  };

  return <button className={styles.themeToggle} onMouseDown={toggleTheme} />;
}
