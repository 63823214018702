import { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import type { MutableRefObject } from 'react';
import type { Screen, Submenu, MenuOption, QualityLevelOption } from './settings-menu.types';

import { useOnClickOutside } from '../../hooks/use-on-click-outside';
import { usePrevious } from '../../hooks/use-previous';
import { useDynamicSize } from '../../hooks/use-dynamic-size';
import { MainScreenButton } from './main-screen-button';
import { SubMenu } from './sub-menu';
import { getQualityMenuOptions, getQualityMenuOption } from './settings-menu.utils';
import { Key } from '../../utils/key';

import styles from './settings-menu.module.css';

const supportedKeys: string[] = [Key.ArrowUp, Key.ArrowDown, Key.ArrowLeft, Key.ArrowRight, Key.Escape];

type Props = {
  isOpen?: boolean;
  chosenQualityId?: string;
  qualities?: QualityLevelOption[];
  autoQualityOptionAvailable?: boolean;
  chosenPlaybackSpeedId?: string;
  playbackSpeeds?: MenuOption[];
  playerWrapperRef: MutableRefObject<HTMLElement | null>;
  hidePlaybackSpeed?: boolean;
  onSetQuality?: (qualityId: string) => void;
  onSetPlaybackSpeed?: (playbackSpeedId: string) => void;
  onClose: () => void;
};

export function SettingsMenu({
  isOpen,
  chosenQualityId,
  qualities,
  autoQualityOptionAvailable,
  chosenPlaybackSpeedId,
  playbackSpeeds,
  playerWrapperRef,
  hidePlaybackSpeed,
  onSetQuality,
  onSetPlaybackSpeed,
  onClose,
}: Props) {
  const { t } = useTranslation();

  const wrapperRef = useRef<HTMLDivElement>(null);

  const { height: playerWrapperHeight } = useDynamicSize(playerWrapperRef);

  const distanceWrapperBottomOfPlayer = wrapperRef.current
    ? window.getComputedStyle(wrapperRef.current).getPropertyValue('bottom')
    : '62'; /** Use the minimum bottom position of the menu wrapper if the ref is not available */

  /**
   * Subtract the css bottom position of the menu wrapper from the player wrapper height
   */
  const maxHeight = playerWrapperHeight - parseFloat(distanceWrapperBottomOfPlayer);

  const [currentScreen, setCurrentScreen] = useState<Screen>('main');
  const previousScreen = usePrevious(currentScreen);

  const chosenQualityOption = qualities?.find((quality) => quality.id === chosenQualityId);
  const chosenPlaybackSpeedOption = playbackSpeeds?.find(
    (playbackSpeed) => playbackSpeed.id === chosenPlaybackSpeedId
  )!;

  useOnClickOutside(wrapperRef, onClose, 'settings-menu-button');

  const goToMainScreen = () => {
    setCurrentScreen('main');
  };

  const focusOnFirstButton = () => {
    wrapperRef.current?.querySelectorAll('button')?.[0].focus();
  };

  const focusOnSpecificButton = (submenu: string) => {
    (wrapperRef.current?.querySelector(`[data-goto="${submenu}"]`) as HTMLButtonElement).focus();
  };

  useEffect(() => {
    if (!previousScreen) return;

    if (previousScreen !== 'main' && currentScreen === 'main') {
      focusOnSpecificButton(previousScreen);
    }

    if (previousScreen === 'main' && currentScreen !== 'main') {
      focusOnFirstButton();
    }
  }, [currentScreen, previousScreen]);

  // Set focus on the menu when it's open so that
  // the keydown listener can start capturing key events
  useEffect(() => {
    isOpen && wrapperRef?.current?.focus();
  }, [isOpen]);

  useEffect(() => {
    const wrapperElement = wrapperRef.current;

    if (!wrapperElement) return;

    const onKeyDown = (event: KeyboardEvent) => {
      if (document.activeElement === wrapperRef.current) {
        focusOnFirstButton();
        return;
      }

      switch (event.key) {
        case Key.ArrowUp:
          (document.activeElement?.previousElementSibling as HTMLElement)?.focus();
          break;

        case Key.ArrowDown:
          (document.activeElement?.nextElementSibling as HTMLElement)?.focus();
          break;

        case Key.ArrowLeft:
          setCurrentScreen('main');
          break;

        case Key.ArrowRight:
          const goToAttribute = document.activeElement?.getAttribute('data-goto') as Submenu;
          typeof goToAttribute === 'string' && setCurrentScreen(goToAttribute);
          break;

        case Key.Escape:
          onClose();
      }

      if (supportedKeys.includes(event.key)) event.preventDefault();
    };

    wrapperElement.addEventListener('keydown', onKeyDown);

    return () => {
      wrapperElement.removeEventListener('keydown', onKeyDown);
    };
  }, [currentScreen, onClose]);

  return (
    <div ref={wrapperRef} className={classNames(styles.wrapper, !isOpen && styles.closed)} tabIndex={-1}>
      <div
        className={classNames(styles.scroller, 'player-scrollbar')}
        style={{ maxHeight: maxHeight || undefined }}
        data-testid="settings-menu-scroll-container"
      >
        {currentScreen === 'main' && (
          <div role="menu" className={styles.menu} data-testid="settings-menu">
            {qualities && (
              <MainScreenButton
                hasSubMenu={qualities.length > 1}
                goTo={'quality'}
                label={t('ui.menu.item.quality')}
                menuOption={getQualityMenuOption(t, chosenQualityOption)}
                onClick={setCurrentScreen}
                tabIndex={isOpen ? 0 : -1}
              />
            )}
            {!hidePlaybackSpeed && (
              <MainScreenButton
                hasSubMenu={true}
                goTo={'speed'}
                label={t('ui.menu.item.speed')}
                menuOption={chosenPlaybackSpeedOption}
                onClick={setCurrentScreen}
                tabIndex={isOpen ? 0 : -1}
              />
            )}
          </div>
        )}

        {currentScreen === 'quality' && qualities && (
          <SubMenu
            label={t('ui.menu.item.quality')}
            chosenItemId={chosenQualityId}
            menuOptions={getQualityMenuOptions(t, qualities, autoQualityOptionAvailable)}
            dataTestId={'quality-menu'}
            onChooseItem={onSetQuality}
            onGoBack={goToMainScreen}
          />
        )}

        {currentScreen === 'speed' && (
          <SubMenu
            label={t('ui.menu.item.speed')}
            chosenItemId={chosenPlaybackSpeedId}
            menuOptions={playbackSpeeds}
            dataTestId={'speed-menu'}
            onChooseItem={onSetPlaybackSpeed}
            onGoBack={goToMainScreen}
          />
        )}
      </div>
    </div>
  );
}
