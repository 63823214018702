import { useRef, useState, Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { FullscreenButton } from '../../fullscreen-button';
import { LiveBadge } from '../../live-badge';
import { Subtitles } from '../../subtitles';
import { Timeline } from '../../timeline';
import { Overlay } from '../../overlay';
import { Volume } from '../../volume';
import { SettingsMenu, SettingsMenuButton } from '../../settings-menu';
import { LanguageMenuButton, LanguageMenu } from '../../language-menu';
import { Spinner } from '../../spinner/spinner';
import { WatermarkOverlay } from '../../watermark-overlay';
import { useKeyboardShortcuts } from '../hooks/use-keyboard-shortcuts';
import { useHover } from '../../../hooks/use-hover';
import { useMouseMoving } from '../../../hooks/use-mouse-moving';
import { useDynamicSize } from '../../../hooks/use-dynamic-size';
import { useKeyboardNavigating } from '../../../hooks/use-keyboard-navigating';
import { useElementsVisibilities } from '../hooks/use-elements-visibilities';
import { LivePlayButton } from '../../live-play-button/live-play-button';
import { AudioAnimationOverlay } from '../../audio-animation-overlay';
import { LiveUIProps } from '../user-interface.types';

import styles from '../user-interfaces.module.css';

export function LiveUI({
  elementVisibilitySettings = {},
  breakpoints = [],
  isPlaying = false,
  isStopped = true,
  logoUrl = '',
  title = '',
  currentTime = 0,
  totalTime = 0,
  bufferPercentage = 0,
  volume = 1,
  isFullscreen = false,
  audioOnly = false,
  audioOnlyImageUrl = '',
  subtitlesEnabled = false,
  subtitle = '',
  subtitleOptionId = '',
  subtitleOptions = [],
  qualityId = '',
  qualities = [],
  autoQualityOptionAvailable = true,
  playbackSpeedId = '',
  playbackSpeeds = [],
  storyboard,
  isMuted = false,
  watermark,
  onPlaybackToggle,
  onTimeChange,
  onVolumeChange,
  onVolumeDeltaChange,
  onMuteChange,
  onFullscreenToggle,
  onSetSubtitle,
  onSetQuality,
  audioTracks = [],
  audioTrackId = '',
  onSetAudioTrack,
  onOverlayVisibilityChange,
}: LiveUIProps) {
  const { t } = useTranslation();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const bottomBarRef = useRef(null);
  const isMouseMovingOverWrapper = useMouseMoving(wrapperRef);
  const isBottomBarHovered = useHover(bottomBarRef);
  const isKeyboardFocusOverBottomBar = useKeyboardNavigating(bottomBarRef);
  const { width: wrapperWidth } = useDynamicSize(wrapperRef);
  const smallScreen = wrapperWidth < 480;
  const {
    showLogo,
    showTitle,
    showPlayButton,
    showTimeline,
    showVolumeInput,
    showVolumeSlider,
    showVideoLength,
    showSubtitles,
    showSettingsButton,
    showFullscreenButton,
    showBigPlayButton,
    verticalVolumeSlider,
  } = useElementsVisibilities(wrapperWidth, elementVisibilitySettings, breakpoints);

  const [settingsMenuIsOpen, setSettingsMenuIsOpen] = useState(false);
  const [languageMenuIsOpen, setLanguageMenuIsOpen] = useState(false);
  const menuIsOpen = languageMenuIsOpen || settingsMenuIsOpen;

  const lastKeyboardAction = useKeyboardShortcuts(
    wrapperRef,
    onVolumeDeltaChange,
    undefined,
    onMuteChange,
    isMuted,
    menuIsOpen,
    onPlaybackToggle,
    onFullscreenToggle
  );

  const toggleSettingsMenu = () => {
    setSettingsMenuIsOpen((state) => !state);
  };

  const closeSettingsMenu = () => {
    setSettingsMenuIsOpen(false);
  };

  const toggleLanguageMenu = () => {
    setLanguageMenuIsOpen((state) => !state);
  };

  const closeLanguageMenu = () => {
    setLanguageMenuIsOpen(false);
  };

  const controlsAreHidden = isPlaying && !isMouseMovingOverWrapper && !isKeyboardFocusOverBottomBar;
  const logoIsVisible = showLogo && logoUrl !== '';
  const titleIsVisible = showTitle && title !== '';
  const timelineIsVisible = showTimeline && totalTime > 0;
  const topBarIsVisible = logoIsVisible || titleIsVisible;
  const subtitlesAreVisible = subtitlesEnabled && subtitle !== '';
  const subtitlesHaveSpaceForBottomBar = isBottomBarHovered || !controlsAreHidden;
  const languageMenuIsVisible =
    showSubtitles && ((audioTracks && audioTracks?.length > 0) || subtitleOptions.length > 0);

  useEffect(() => {
    onOverlayVisibilityChange?.(controlsAreHidden);
  }, [controlsAreHidden, onOverlayVisibilityChange]);

  return (
    <div
      ref={wrapperRef}
      className={classNames(styles.wrapper, 'desktop', { smallScreen }, { settingsMenuIsOpen })}
      data-testid="player-wrapper"
      tabIndex={-1}
    >
      <Spinner />

      <WatermarkOverlay watermark={watermark} />

      {topBarIsVisible && (
        <div className={classNames(styles.topBar, { [styles.hidden]: controlsAreHidden })} data-testid="top-bar">
          {logoIsVisible && <img src={logoUrl} alt={t('ui.video-logo.alt')} data-testid="video-logo" />}
          {titleIsVisible && <span data-testid="video-title">{title}</span>}
        </div>
      )}
      {audioOnly && <AudioAnimationOverlay imageUrl={audioOnlyImageUrl} isPlaying={isPlaying} isStopped={isStopped} />}
      <Overlay
        key={showBigPlayButton ? 'big-play-button' : 'big-play-button-disabled'} // To force re-mount when showBigPlayButton changes
        isPlaying={isPlaying}
        isStopped={isStopped}
        isTrimmable={false}
        volume={volume}
        isMuted={isMuted}
        wrapperRef={wrapperRef}
        onClick={onPlaybackToggle}
        lastKeyboardAction={lastKeyboardAction}
        enableBigPlayButton={showBigPlayButton}
      />

      <div
        ref={bottomBarRef}
        className={classNames(styles.bottomBar, { [styles.hidden]: controlsAreHidden })}
        data-testid="bottom-bar"
      >
        {timelineIsVisible && (
          <Suspense fallback={null}>
            <Timeline
              currentTime={currentTime}
              totalTime={totalTime}
              bufferPercentage={bufferPercentage}
              liveUI={true}
              isMobile={false}
              storyboard={storyboard}
              onChange={onTimeChange}
            />
          </Suspense>
        )}

        <div className={styles.buttonsSection} data-testid="buttons-bottom-bar">
          {showPlayButton && <LivePlayButton className="left-side" isPlaying={isPlaying} onClick={onPlaybackToggle} />}
          {showVolumeInput && (
            <Volume
              className="left-side"
              value={volume}
              isMuted={isMuted}
              showVolumeSlider={showVolumeSlider}
              verticalVolumeSlider={verticalVolumeSlider}
              onChange={onVolumeChange}
              onMuteChange={onMuteChange}
            />
          )}
          {showVideoLength && <LiveBadge className="left-side" />}

          {languageMenuIsVisible && (
            <>
              <LanguageMenuButton className="right-side" isActive={languageMenuIsOpen} onClick={toggleLanguageMenu} />
              <LanguageMenu
                isOpen={languageMenuIsOpen}
                chosenSubtitleId={subtitleOptionId}
                subtitles={subtitleOptions}
                subtitlesEnabled={subtitlesEnabled}
                playerWrapperRef={wrapperRef}
                bottomBarRef={bottomBarRef}
                onSetSubtitle={onSetSubtitle}
                onClose={closeLanguageMenu}
                audioTracks={audioTracks}
                onSetAudioTrack={onSetAudioTrack}
                chosenAudioTrackId={audioTrackId}
              />
            </>
          )}

          {showSettingsButton && (
            <>
              <SettingsMenuButton className="right-side" isActive={settingsMenuIsOpen} onClick={toggleSettingsMenu} />

              <SettingsMenu
                isOpen={settingsMenuIsOpen}
                chosenQualityId={qualityId}
                qualities={qualities}
                autoQualityOptionAvailable={autoQualityOptionAvailable}
                chosenPlaybackSpeedId={playbackSpeedId}
                playbackSpeeds={playbackSpeeds}
                playerWrapperRef={wrapperRef}
                hidePlaybackSpeed={true}
                onSetQuality={onSetQuality}
                onClose={closeSettingsMenu}
              />
            </>
          )}

          {showFullscreenButton && (
            <FullscreenButton className="right-side" isFullscreen={isFullscreen} onClick={onFullscreenToggle} />
          )}
        </div>
      </div>

      {subtitlesAreVisible && (
        <Subtitles withSpaceForBottomBar={subtitlesHaveSpaceForBottomBar} wrapperWidth={wrapperWidth}>
          {subtitle}
        </Subtitles>
      )}
    </div>
  );
}
