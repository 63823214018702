import type { MenuPosition } from '../../../../components/menu-more/menu-more';
import type { WebcastWithTitle } from '../../types';
import type { KeyboardEvent, MouseEvent } from 'react';

import {
  ChatIcon,
  DuplicateIcon,
  EventIcon,
  GhostButton,
  IconButton,
  PlayerIcon,
  Tooltip,
  TrashIcon,
} from '@movingimage-evp/mi-ui-component-library';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { State } from '../../../../../generated/graphql-manager';
import { useAbsoluteRoutes } from '../../../../../routes';
import { addParamToPath } from '../../../../../utils';
import { MenuMore } from '../../../../components/menu-more/menu-more';
import { useUserPermissions } from '../../../../hooks/user-permissions';

import styles from './action-buttons.module.css';

type Props = {
  webcast: WebcastWithTitle;
  menuPosition?: MenuPosition;
  onDeleteWebcast: (id: WebcastWithTitle['id']) => void;
  onDuplicateWebcast: (id: WebcastWithTitle['id'], title: WebcastWithTitle['title']) => void;
};

export function ActionButtons({ webcast, menuPosition, onDeleteWebcast, onDuplicateWebcast }: Props) {
  const { t } = useTranslation();
  const routes = useAbsoluteRoutes();
  const { isEventDeletionAllowed, isEventDuplicationAllowed, isAccessToStudioAllowed, isFullAccessToStudioAllowed } =
    useUserPermissions();

  const { id, title, state, chat } = webcast;
  const isInitializing = state === State.INITIALIZING;
  const isDeleteWebcastDisabled = state === State.LIVE || state === State.LIVE_TEST || !isEventDeletionAllowed;
  const isDuplicationDisabled = isInitializing || !isEventDuplicationAllowed;
  const isMessagingAvailable = chat?.applicationId && isAccessToStudioAllowed;
  const isMoreMenuAvailable = isEventDeletionAllowed && isEventDuplicationAllowed;

  const deleteButtonDisabled =
    state === State.LIVE
      ? t('manager.webcasts.actionButtons.deleteDisabledLiveEvent')
      : t('manager.webcasts.actionButtons.deleteDisabledLiveTestEvent');

  const handleKeydown = (event: KeyboardEvent) => {
    event.stopPropagation();
  };

  const handleLinkClick = (event: MouseEvent) => {
    event.stopPropagation();
  };

  return (
    <div className={styles.actionButtons} onKeyDown={handleKeydown}>
      {isFullAccessToStudioAllowed && (
        <Tooltip data-testid="webcast-open-studio-button-tooltip" label={t('manager.webcasts.actionButtons.studio')}>
          <IconButton
            rounded
            component={NavLink}
            to={addParamToPath(routes.studio, id)}
            rel="noreferrer"
            target="_blank"
            aria-label={t('manager.webcasts.actionButtons.studio')}
            data-testid="webcast-open-studio-button"
            onClick={handleLinkClick}
          >
            <EventIcon />
          </IconButton>
        </Tooltip>
      )}

      <Tooltip data-testid="webcast-open-viewer-button-tooltip" label={t('manager.webcasts.actionButtons.viewer')}>
        <IconButton
          rounded
          component={NavLink}
          to={addParamToPath(routes.viewer, id)}
          rel="noreferrer"
          target="_blank"
          aria-label={t('manager.webcasts.actionButtons.viewer')}
          data-testid="webcast-open-viewer-button"
          onClick={handleLinkClick}
        >
          <PlayerIcon />
        </IconButton>
      </Tooltip>

      {isMessagingAvailable && (
        <Tooltip
          data-testid="webcast-open-messaging-button-tooltip"
          label={t('manager.webcasts.actionButtons.messaging')}
        >
          <IconButton
            rounded
            component={NavLink}
            to={addParamToPath(routes.studio_messaging, id)}
            rel="noreferrer"
            target="_blank"
            onClick={handleLinkClick}
            aria-label={t('manager.webcasts.actionButtons.messaging')}
            data-testid="webcast-open-messaging-button"
          >
            <ChatIcon />
          </IconButton>
        </Tooltip>
      )}

      {isMoreMenuAvailable && (
        <MenuMore menuPosition={menuPosition}>
          {isEventDeletionAllowed && (
            <Tooltip
              data-testid="webcast-delete-button-tooltip"
              label={deleteButtonDisabled}
              position="above"
              hidden={!isDeleteWebcastDisabled}
            >
              <GhostButton
                small
                onClick={() => onDeleteWebcast(id)}
                disabled={isDeleteWebcastDisabled}
                data-testid="webcast-delete-button"
              >
                <TrashIcon /> {t('manager.webcasts.actionButtons.delete')}
              </GhostButton>
            </Tooltip>
          )}

          {isEventDuplicationAllowed && (
            <Tooltip
              data-testid="webcast-duplicate-button-tooltip"
              label={t('manager.webcasts.actionButtons.duplicateDisabled')}
              hidden={!isDuplicationDisabled}
            >
              <GhostButton
                small
                onClick={() => onDuplicateWebcast(id, title)}
                disabled={isDuplicationDisabled}
                data-testid="webcast-duplicate-button"
              >
                <DuplicateIcon /> {t('manager.webcasts.actionButtons.duplicate')}
              </GhostButton>
            </Tooltip>
          )}
        </MenuMore>
      )}
    </div>
  );
}
