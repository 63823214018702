// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top-bar_wrapper__oKnqD {
  pointer-events: none;

  display: flex;
  align-items: center;
  margin-bottom: auto;
  background: linear-gradient(var(--overlayBackground), transparent); /* backwards compatible */
  color: var(--overlayForeground);
  transition: opacity 0.2s;
}

@supports (color: color-mix(in lch, red, blue)) {
.top-bar_wrapper__oKnqD {
  background: linear-gradient(color-mix(in srgb, var(--overlayBackground) 90%, transparent), transparent);
}
}

.top-bar_wrapper__oKnqD.top-bar_hidden__x5oQk {
    opacity: 0;
  }

.top-bar_wrapper__oKnqD > * {
    pointer-events: auto;
  }

.top-bar_wrapper__oKnqD .top-bar_logo__9-JLN {
    width: 34px;
    height: 34px;
    object-fit: contain;
    object-position: center;
  }

.top-bar_wrapper__oKnqD .top-bar_title__B4bHd {
    margin-left: 19px;

    font-size: 12px;
    line-height: 15px;
    white-space: nowrap;
    text-overflow: ellipsis;

    pointer-events: all;
    overflow: hidden;
  }

.top-bar_wrapper__oKnqD .top-bar_downloadSourcesButton__FEK2- {
    margin-left: auto;
    position: relative;
  }

.mobile .top-bar_wrapper__oKnqD .top-bar_downloadSourcesButton__FEK2-,
.desktop.smallScreen .top-bar_wrapper__oKnqD .top-bar_downloadSourcesButton__FEK2- {
  position: unset;
}

.desktop .top-bar_wrapper__oKnqD {
  justify-content: flex-start;
  padding: 3% 5%;
  padding: clamp(8px, 3%, 23px) clamp(16px, 5%, 40px);
}

.desktop .top-bar_wrapper__oKnqD img {
  width: 34px;
  height: 34px;
}
`, "",{"version":3,"sources":["webpack://./src/components/top-bar/top-bar.module.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;;EAEpB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,kEAAkE,EAAE,yBAAyB;EAE7F,+BAA+B;EAC/B,wBAAwB;AAiC1B;;AA1CA;AAAA;EAOE,uGAAuG;AAmCzG;AAAA;;AA/BE;IACE,UAAU;EACZ;;AAEA;IACE,oBAAoB;EACtB;;AAEA;IACE,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,uBAAuB;EACzB;;AAEA;IACE,iBAAiB;;IAEjB,eAAe;IACf,iBAAiB;IACjB,mBAAmB;IACnB,uBAAuB;;IAEvB,mBAAmB;IACnB,gBAAgB;EAClB;;AAEA;IACE,iBAAiB;IACjB,kBAAkB;EACpB;;AAGF;;EAEE,eAAe;AACjB;;AAEA;EACE,2BAA2B;EAC3B,cAAc;EACd,mDAAmD;AACrD;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".wrapper {\n  pointer-events: none;\n\n  display: flex;\n  align-items: center;\n  margin-bottom: auto;\n  background: linear-gradient(var(--overlayBackground), transparent); /* backwards compatible */\n  background: linear-gradient(color-mix(in srgb, var(--overlayBackground) 90%, transparent), transparent);\n  color: var(--overlayForeground);\n  transition: opacity 0.2s;\n\n  &.hidden {\n    opacity: 0;\n  }\n\n  > * {\n    pointer-events: auto;\n  }\n\n  .logo {\n    width: 34px;\n    height: 34px;\n    object-fit: contain;\n    object-position: center;\n  }\n\n  .title {\n    margin-left: 19px;\n\n    font-size: 12px;\n    line-height: 15px;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n\n    pointer-events: all;\n    overflow: hidden;\n  }\n\n  .downloadSourcesButton {\n    margin-left: auto;\n    position: relative;\n  }\n}\n\n:global(.mobile) .wrapper .downloadSourcesButton,\n:global(.desktop.smallScreen) .wrapper .downloadSourcesButton {\n  position: unset;\n}\n\n:global(.desktop) .wrapper {\n  justify-content: flex-start;\n  padding: 3% 5%;\n  padding: clamp(8px, 3%, 23px) clamp(16px, 5%, 40px);\n}\n\n:global(.desktop) .wrapper img {\n  width: 34px;\n  height: 34px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `top-bar_wrapper__oKnqD`,
	"hidden": `top-bar_hidden__x5oQk`,
	"logo": `top-bar_logo__9-JLN`,
	"title": `top-bar_title__B4bHd`,
	"downloadSourcesButton": `top-bar_downloadSourcesButton__FEK2-`
};
export default ___CSS_LOADER_EXPORT___;
