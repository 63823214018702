import type { HTMLAttributes } from 'react';

import {
  ChevronFilledIcon,
  LanguageIcon,
  Tooltip,
  classNames,
  getLanguageKey,
} from '@movingimage-evp/mi-ui-component-library';
import { useTranslation } from 'react-i18next';

import { useSelectedLanguage } from '../../providers/selected-language-provider';

import styles from './viewer-language-switch.module.css';

export type LanguageSwitchProps = {
  tooltip?: string;
  hidden?: boolean;
} & HTMLAttributes<HTMLDivElement>;

export function ViewerLanguageSwitch({ tooltip, hidden, className, ...props }: LanguageSwitchProps) {
  const { t } = useTranslation();
  const { availableLanguages, selectedLanguage, setSelectedLanguage } = useSelectedLanguage();

  if (!selectedLanguage || (availableLanguages && availableLanguages?.length < 2)) return null;

  return (
    <Tooltip label={tooltip || t('common.languageSwitch.tooltip')}>
      <div
        data-testid="language-switch"
        className={classNames(styles.wrapper, hidden && styles.hidden, className)}
        {...props}
      >
        <LanguageIcon />

        <div data-testid="language-switch-label" className={styles.label}>
          {t(`common.languageSwitch.options.${getLanguageKey(selectedLanguage)}.selectedLabel`)}
        </div>

        <ChevronFilledIcon />

        <select
          data-testid="language-switch-select"
          className={styles.languageSwitch}
          value={selectedLanguage}
          onChange={(event) => setSelectedLanguage(event.target.value)}
        >
          {availableLanguages?.map((language) => (
            <option key={language} value={language}>
              {t(`common.languageSwitch.options.${getLanguageKey(language)}.optionLabel`)}
            </option>
          ))}
        </select>
      </div>
    </Tooltip>
  );
}
