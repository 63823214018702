import { useCallback, useEffect, useMemo, useState } from 'react';
import { default as detectMobile } from 'ismobilejs';
import videojs, { VideoJsPlayer } from 'video.js';
import type { QualityLevelList } from 'videojs-contrib-quality-levels';
import type { BreakpointSettings, PlayerOptions } from './player.interface';
import { UserInterface, AutoQualityId, QualityLevelOption } from '../user-interfaces';
import { ErrorMessage } from '../error-message/error-message';
import { useSubtitles } from './hooks/use-subtitles';
import { useTimedMetadata } from './hooks/use-timed-metadata';
import { clamp } from '../../utils/clamp';
import { createPlayerApi } from './create-player-api';
import { useTranslation } from 'react-i18next';
import { msToS, sToMs } from '../../utils/time';
import { useQualityLevels } from './hooks/use-quality-levels';
import { useAudioTracks } from './hooks/use-audio-tracks';
import { getPlaybackSpeeds } from '../../utils/get-playback-speeds';
import { useRefState } from '../../hooks/use-ref-state';
import { ProgressiveSources } from './player.config';
import { isHLSSource } from '../../utils/is-hls-source';
import { useYouboraAnalytics } from './hooks/analytics/use-youbora-analytics';
import { VideoJsNativeEvent } from './videojs-event';
import { getInitialQualityId } from './quality.utils';
import { AudioTrack } from '../language-menu';
import { PlayerError } from './player-errors';
import { getSources } from './utils/get-sources';
import { tryToAutoplay, tryToPlay } from './utils/try-to-play';

interface Props {
  player: VideoJsPlayer;
  playerOptions: PlayerOptions;
}

export const PlayerUIConnector = ({ player, playerOptions }: Props): JSX.Element | null => {
  const { t } = useTranslation();
  const { settings } = playerOptions;
  const isPhone = useMemo(() => {
    return process.env.STORYBOOK_BUILD
      ? detectMobile(window.sessionStorage.getItem('storyBookUserAgent') ?? '').phone
      : detectMobile(window.navigator).phone;
  }, []);

  /* -- Analytics -- */
  useYouboraAnalytics(player, playerOptions.analyticsMode, playerOptions.plugins.youboraAnalytics);

  /* -- Qualities -- */
  const [qualityId, setQualityId] = useState<string | undefined>(undefined);
  const qualities = useQualityLevels(player, playerOptions.sources.progressive);

  /* -- AudioTracks -- */
  const [audioTrackId, setAudioTrackId] = useState<string | undefined>(undefined);
  const audioTracks: AudioTrack[] = useAudioTracks(player);
  const [initialAudioTrackId, setInitialAudioTrackId] = useState('');

  const handleAudioTrackSelection = (audioTrackId: string) => {
    const audioTrackList = player.audioTracks();
    if (audioTrackList) {
      const tracks = Array.from(audioTrackList);
      tracks.forEach((track) => {
        track.enabled = track.id === audioTrackId;
      });

      setAudioTrackId(audioTrackId);
    }
  };

  function getInitalAudioTrackId(audioTracks: AudioTrack[]): string {
    const track = audioTracks.find((t) => t.enabled === true);
    return track?.id || '';
  }

  useEffect(() => {
    if (audioTracks) {
      const initialId = getInitalAudioTrackId(audioTracks);
      setInitialAudioTrackId(initialId);
    }
  }, [audioTracks]);

  const currentPlayerSource = player.currentSource().src;
  const initialQualityId = useMemo(
    () => getInitialQualityId(playerOptions, currentPlayerSource),
    [playerOptions, currentPlayerSource]
  );

  const handleQualitySelection = (qualityId: string) => {
    setQuality(player, qualityId, playerOptions, qualities).then(() => {
      setQualityId(qualityId);
    });
  };

  /*
   * -- Current Time --
   * The current time visible in the UI. Updates immediately when the user changes it in the timeline. This time can deviate from the current time in VideoJS.
   * The current time in VideoJS is just updated after the video has been processed for this current timestamp (e.g. after buffering).
   */
  const [currentTime, setCurrentTime] = useState(0);
  const requestCurrentTimeChange = useCallback(
    (time: number) => {
      setCurrentTime(time);
      player.currentTime(msToS(time));
    },
    [player]
  );

  /* -- Total Time --*/
  const [totalTime, setTotalTime] = useState(() => sToMs(player.duration()));

  const requestCurrentTimeDeltaChange = useCallback(
    (deltaMs: number) => {
      requestCurrentTimeChange(clamp(sToMs(player.currentTime()) + deltaMs, 0, sToMs(player.duration())));
    },
    [player, requestCurrentTimeChange]
  );

  /* -- Volume -- between 0 and 1 */
  const [volume, setVolume] = useState(() => {
    player.volume(settings.volume);
    return settings.volume;
  });

  const handleVolumeChange = useCallback(
    (value: number) => {
      player.volume(value);

      // If volume is not zero, unmute the player.
      player.muted(value <= 0);
    },
    [player]
  );

  const handleVolumeDeltaChange = useCallback(
    (delta: number) => {
      const newVolume = Math.round((player.volume() + delta) * 10) / 10;
      if (newVolume >= 0 && newVolume <= 1) {
        handleVolumeChange(newVolume);
      }
    },
    [handleVolumeChange, player]
  );

  const [isMuted, setIsMuted] = useState(() => {
    return player.muted();
  });

  const handleMuteChange = useCallback(
    (value: boolean) => {
      player.muted(value);
      setIsMuted(value);

      // When the volume is zero & player is not muted, set the default volume.
      if (!value && player.volume() <= 0) {
        player.volume(settings.volume);
      }
    },
    [player, settings.volume]
  );

  /* -- Fullscreen -- */
  const [isFullscreen, setIsFullscreen] = useState(() => player.isFullscreen());
  const handleFullscreenToggle = () => {
    player.isFullscreen() ? player.exitFullscreen() : player.requestFullscreen();
  };

  /* -- Playing -- */
  const [isPlaying, setIsPlaying] = useState(() => !player.paused());
  const [bufferPercentage, setBufferPercentage] = useState(() => player.bufferedPercent());

  /* -- Stopped -- */
  const [isStopped, setIsStopped] = useState(() => player.ended());

  /* -- Looping -- */
  const [isLooping, setIsLooping] = useState(() => {
    player.loop(settings.looping);
    return settings.looping;
  });

  /* -- Trimmer -- */
  const [startTimeRef, startTime, setStartTime] = useRefState(0);
  const [endTimeRef, endTime, setEndTime] = useRefState(0);

  const handlePlaybackToggle = useCallback(() => {
    if (player.paused()) {
      tryToPlay(player)
        .then(() => {
          isLiveStream(player.duration()) && player.liveTracker.seekToLiveEdge();
        })
        .catch(() => {}); // Player errors are handled by the player error handler already
    } else {
      player.pause();
    }
  }, [player]);

  const handleLoopButtonClick = () => {
    setIsLooping(!isLooping);
    player.loop(!isLooping);
  };

  /* -- Subtitles -- */
  const [selectedSubtitleId, setSelectedSubtitleId] = useState<string>('');
  const { subtitle, subtitleOptions } = useSubtitles(
    player,
    playerOptions?.tracks,
    selectedSubtitleId,
    setSelectedSubtitleId
  );

  /* -- Playback Speeds -- */
  const playbackSpeeds = getPlaybackSpeeds(t);
  const [playbackSpeedId, setPlaybackSpeedId] = useState('1');
  const handlePlaybackSpeedSelection = (playbackSpeedId: string) => {
    const playbackRate = Number(playbackSpeedId);
    setPlaybackSpeedId(playbackSpeedId);
    player.playbackRate(playbackRate);
  };

  /* -- Error handling -- */
  const [mediaError, setMediaError] = useState<MediaError | null>(null);

  const reloadSources = () => {
    const autoplay = playerOptions.settings.autoplay;

    setMediaError(null);
    setPlayerSrc(player, player.currentSources(), autoplay);
  };

  useTimedMetadata(player);

  useEffect(() => {
    const { settings } = playerOptions;

    const updateDuration = () => {
      const duration = player.duration();
      const totalTime = sToMs(duration);

      setTotalTime(totalTime);
      setEndTime(totalTime);
    };

    const onVolumeChange = () => {
      setVolume(player.volume());
      setIsMuted(player.muted());
    };

    const onTimeUpdate = () => {
      setCurrentTime(sToMs(player.currentTime()));
    };

    const onFullscreenChange = () => {
      player.fluid(!player.isFullscreen() && (!settings.videoLayout || settings.videoLayout === 'fluid'));
      setIsFullscreen(player.isFullscreen());
    };

    const onPlay = () => {
      setIsPlaying(true);
      setIsStopped(false);
    };

    const onLoadStart = () => {
      setMediaError(null);
      setIsPlaying(!player.paused());
      updateDuration();
    };

    const onPause = () => setIsPlaying(false);
    const onDurationChange = () => updateDuration();
    const onProgress = () => setBufferPercentage(player.bufferedPercent());
    const onEnded = () => setIsStopped(true);
    const onError = () => setMediaError(player.error());

    player.on(VideoJsNativeEvent.PLAY, onPlay);
    player.on(VideoJsNativeEvent.PAUSE, onPause);
    player.on(VideoJsNativeEvent.VOLUME_CHANGE, onVolumeChange);
    player.on(VideoJsNativeEvent.TIME_UPDATE, onTimeUpdate);
    player.on(VideoJsNativeEvent.FULLSCREEN_CHANGE, onFullscreenChange);
    player.on(VideoJsNativeEvent.PROGRESS, onProgress);
    player.on(VideoJsNativeEvent.ENDED, onEnded);
    player.on(VideoJsNativeEvent.ERROR, onError);

    // We do not want to/need to throttle duration changes,
    // as they should always display the changed duration as
    // it has changed.
    player.on(VideoJsNativeEvent.DURATION_CHANGE, onDurationChange);

    // Listen to loadstart because the player duration is reset when a new media element is loaded,
    // but the durationchange on the user agent will not fire.
    // @see [Spec]{@link https://www.w3.org/TR/2011/WD-html5-20110113/video.html#media-element-load-algorithm}
    player.on(VideoJsNativeEvent.LOAD_START, onLoadStart);

    if (playerOptions.onReady) {
      const props = {
        handleSubtitleSelection: setSelectedSubtitleId,
        setStartTime,
        setEndTime,
        getTrimStartTime: () => startTimeRef.current,
        getTrimEndTime: () => endTimeRef.current,
        options: { defaultVolume: settings.volume },
      };
      const playerApi = createPlayerApi(player, props);
      playerOptions.onReady(playerApi);
    }

    return () => {
      player.off(VideoJsNativeEvent.PLAY, onPlay);
      player.off(VideoJsNativeEvent.PAUSE, onPause);
      player.off(VideoJsNativeEvent.VOLUME_CHANGE, onVolumeChange);
      player.off(VideoJsNativeEvent.TIME_UPDATE, onTimeUpdate);
      player.off(VideoJsNativeEvent.FULLSCREEN_CHANGE, onFullscreenChange);
      player.off(VideoJsNativeEvent.PROGRESS, onProgress);
      player.off(VideoJsNativeEvent.ENDED, onEnded);
      player.off(VideoJsNativeEvent.ERROR, onError);
      player.off(VideoJsNativeEvent.DURATION_CHANGE, onDurationChange);
      player.off(VideoJsNativeEvent.LOAD_START, onLoadStart);
    };
  }, [player, playerOptions, setStartTime, setEndTime, startTimeRef, endTimeRef]);

  // Set sources after player UI is ready
  useEffect(() => {
    async function setSources(abortSignal: AbortSignal) {
      const sources = await getSources(
        player,
        playerOptions.sources,
        playerOptions.plugins,
        abortSignal,
        playerOptions.settings.preferredQuality
      ).catch((error) => {
        if (abortSignal.aborted) {
          return;
        }

        if (error instanceof PlayerError) {
          player.error(error.message);
        }
      });

      if (sources) {
        const autoplay = playerOptions.settings.autoplay;

        setPlayerSrc(player, sources, autoplay).finally(() => autoplay && setInitAutoplay(false));
      }
    }

    const abortController = new AbortController();
    setSources(abortController.signal);

    return () => {
      abortController.abort();
    };
  }, [
    playerOptions.sources,
    playerOptions.plugins,
    playerOptions.settings.preferredQuality,
    playerOptions.settings.autoplay,
    player,
  ]);

  const [initAutoplay, setInitAutoplay] = useState(() => settings.autoplay);

  const elementVisibilitySettings = useMemo(() => {
    const visibilitySettings: BreakpointSettings = {
      hideLogo: settings.hideLogo,
      hideTitle: settings.hideTitle,
      hidePlayButton: settings.hidePlayButton,
      hideTimeline: settings.hideTimeline,
      hideVolumeInput: settings.hideVolumeInput,
      hideTimer: settings.hideTimer,
      hideLoopButton: settings.hideLoopButton,
      hideLanguageMenu: settings.hideLanguageMenu,
      hideMenu: settings.hideMenu,
      hideSettingsMenu: settings.hideSettingsMenu,
      hideFullscreenButton: settings.hideFullscreenButton,
      hidePlaybackSpeed: settings.hidePlaybackSpeed,
      hideBigPlayButton: settings.hideBigPlayButton || initAutoplay,
      stepButtonsAreVisible: settings.stepButtonsAreVisible,
      verticalVolumeSlider: settings.verticalVolumeSlider,
    };

    return visibilitySettings;
  }, [
    settings.hideLogo,
    settings.hideTitle,
    settings.hidePlayButton,
    settings.hideTimeline,
    settings.hideVolumeInput,
    settings.hideTimer,
    settings.hideLoopButton,
    settings.hideLanguageMenu,
    settings.hideMenu,
    settings.hideSettingsMenu,
    settings.hideFullscreenButton,
    settings.hidePlaybackSpeed,
    settings.hideBigPlayButton,
    settings.stepButtonsAreVisible,
    settings.verticalVolumeSlider,
    initAutoplay,
  ]);

  if (mediaError) {
    return (
      <ErrorMessage
        mediaError={mediaError}
        onReloadSources={reloadSources}
        customPrimaryColor={settings.primaryColor}
      />
    );
  }

  if (settings.noUI) {
    return null;
  }

  return (
    <UserInterface
      elementVisibilitySettings={elementVisibilitySettings}
      breakpoints={settings.breakpoints}
      customPrimaryColor={settings.primaryColor}
      isFullscreen={isFullscreen}
      isMobile={isPhone}
      liveUI={settings.liveUI}
      isPlaying={isPlaying}
      isStopped={isStopped}
      isLooping={isLooping}
      audioOnly={settings.audioOnly}
      audioOnlyImageUrl={settings.audioOnlyImageUrl}
      isTrimmable={settings.trimmable}
      bufferPercentage={bufferPercentage}
      currentTime={currentTime}
      logoUrl={settings.logoUrl}
      playbackSpeedId={playbackSpeedId}
      playbackSpeeds={playbackSpeeds}
      qualities={qualities}
      autoQualityOptionAvailable={isHLSSource(player.currentType())}
      qualityId={qualityId || initialQualityId}
      storyboard={playerOptions.storyboard}
      downloadSources={playerOptions.downloadSources}
      subtitle={subtitle?.text}
      subtitleOptionId={selectedSubtitleId}
      subtitleOptions={subtitleOptions}
      subtitlesEnabled={!!selectedSubtitleId}
      title={playerOptions.metadata.title}
      totalTime={totalTime}
      trimEndTime={endTime}
      trimStartTime={startTime}
      volume={volume}
      isMuted={isMuted}
      watermark={playerOptions.watermark}
      onFullscreenToggle={handleFullscreenToggle}
      onPlaybackToggle={handlePlaybackToggle}
      onLoopButtonClick={handleLoopButtonClick}
      onSetPlaybackSpeed={handlePlaybackSpeedSelection}
      onSetSubtitle={setSelectedSubtitleId}
      onSetQuality={handleQualitySelection}
      onTimeChange={requestCurrentTimeChange}
      onTimeDeltaChange={requestCurrentTimeDeltaChange}
      onTrimEndTimeChange={setEndTime}
      onTrimStartTimeChange={setStartTime}
      onVolumeChange={handleVolumeChange}
      onVolumeDeltaChange={handleVolumeDeltaChange}
      onMuteChange={handleMuteChange}
      onOverlayVisibilityChange={playerOptions.onOverlayVisibilityChange}
      audioTracks={audioTracks}
      audioTrackId={audioTrackId || initialAudioTrackId}
      onSetAudioTrack={handleAudioTrackSelection}
      theme={settings.theme}
      controlBarColor={settings.controlBarColor}
      iconColor={settings.iconColor}
    />
  );
};

async function setPlayerSrc(
  player: VideoJsPlayer,
  source: string | videojs.Tech.SourceObject | videojs.Tech.SourceObject[],
  autoplay: boolean
) {
  player.src(source);

  if (autoplay) {
    return tryToAutoplay(player).catch(() => {}); // Player errors are handled by the player error handler already
  }
}

function isLiveStream(duration: number): boolean {
  return duration === Infinity;
}

function setQuality(
  player: VideoJsPlayer,
  qualityId: string,
  playerOptions: PlayerOptions,
  qualities: QualityLevelOption[]
): Promise<void> {
  if (isHLSSource(player.currentType())) {
    return setAdaptiveQuality(player, qualityId);
  } else {
    const progressiveSources = playerOptions.sources.progressive!;
    const autoQuality = qualities[0].id;
    const autoplay = playerOptions.settings.autoplay;

    return setProgressiveQuality(player, qualityId, progressiveSources, autoQuality, autoplay);
  }
}

function setAdaptiveQuality(player: VideoJsPlayer, id: string) {
  const qualityLevels = Array.from(player.qualityLevels() as QualityLevelList);

  qualityLevels.forEach((quality) => {
    quality.enabled = id === AutoQualityId || `${quality.height}p` === id;
  });

  return Promise.resolve();
}

function setProgressiveQuality(
  player: VideoJsPlayer,
  id: string,
  playerOptionsSources: ProgressiveSources,
  autoSourceId: string,
  autoplay: boolean
): Promise<void> {
  return new Promise((resolve, reject) => {
    const sources = id === AutoQualityId ? playerOptionsSources[autoSourceId] : playerOptionsSources[id];

    // Save the playback state before changing the source
    const currentTime = player.currentTime();
    const playbackRate = player.playbackRate();
    const isPaused = player.paused();

    // Listen for the 'loadedmetadata' event to restore time and playback rate and resume playback if necessary
    // 'loadedmetadata' is the last event on IOS in the 'onLoad' phase.
    player.one(VideoJsNativeEvent.LOADED_METADATA, () => {
      player.currentTime(currentTime);
      player.playbackRate(playbackRate);

      isPaused ? resolve() : player.play()!.then(resolve, reject);
    });

    // Switch sources
    setPlayerSrc(player, sources, autoplay);
  });
}
