/**
 * Focuses on the next or previous element in the given list of focusable elements.
 * @param event - The keyboard event that triggered the focus change.
 * @param target - The current element that has focus.
 * @param focusableElements - The list of focusable elements.
 * @param offset - The offset to apply to the current index. Use -1 to move to the previous element, and 1 to move to the next element.
 *
 * We want to explicitly focus in order. When there's no next (or previous) element, we allow the default behavior and the focus escapes the menu.
 */
export function focusNextOrPreviousElement(
  event: KeyboardEvent,
  target: HTMLButtonElement,
  focusableElements: NodeListOf<HTMLButtonElement> | undefined,
  offset: 1 | -1 = 1
): void {
  if (!focusableElements) return;

  const currentIndex = Array.from(focusableElements).indexOf(target);
  const element = focusableElements[currentIndex + offset];

  if (element) {
    event.preventDefault();
    element.focus();
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
}
