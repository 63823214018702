import type { MutableRefObject, CSSProperties } from 'react';
import classNames from 'classnames';

import { clamp } from '../../utils/clamp';
import { getFormattedTime } from '../../utils/time';
import { useCursorPosition } from '../../hooks/use-cursor-position';
import { useDynamicSize } from '../../hooks/use-dynamic-size';
import styles from './timeline-preview.module.css';
import type { Storyboard } from '../timeline/timeline.types';

type Props = {
  timelineRef: MutableRefObject<HTMLElement | null>;
  totalTime?: number;
  storyboard?: Storyboard;
  style?: CSSProperties;
};

export function TimelinePreview({ timelineRef, totalTime, storyboard, style }: Props) {
  const { width: timelineWidth } = useDynamicSize(timelineRef);
  const [{ x: hoverPosition }, active] = useCursorPosition(timelineRef, false, false);

  if (!storyboard?.sources?.length || !timelineRef.current || !totalTime) return null;

  const { sources, interval, width, height, spritesPerRow } = storyboard;

  const wrapperPosition = clamp(0, hoverPosition - width / 2, timelineWidth - width);
  const wrapperStyle = { left: wrapperPosition };

  const hoveredTime = (hoverPosition / timelineWidth) * totalTime;
  const timeInterval = hoveredTime / interval / 1000;

  const x = Math.floor(timeInterval % spritesPerRow);
  const y = Math.floor(timeInterval / spritesPerRow);
  const backgroundPosition = `-${x * width}px -${y * height}px`;

  const imageStyle = { width, height, backgroundImage: `url(${sources[0]})`, backgroundPosition };

  return (
    <div
      data-testid="timeline-preview"
      className={classNames(styles.wrapper, { [styles.active]: active })}
      style={{ ...wrapperStyle, ...style }}
    >
      <div style={imageStyle} />
      {getFormattedTime(hoveredTime)}
    </div>
  );
}
