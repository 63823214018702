import { memo } from 'react';

export const CloseIcon = memo(() => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" data-testid="close-icon" role="img">
    <mask
      id="mask0_1650_3905"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <path
        d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
        fill="black"
      />
    </mask>
    <g mask="url(#mask0_1650_3905)">
      <rect width="23.4146" height="23.4146" fill="currentColor" />
    </g>
  </svg>
));
