import {
  ArrowRightIcon,
  CheckIcon,
  GhostButton,
  SaveButton,
  SecondaryButton,
  Spinner,
} from '@movingimage-evp/mi-ui-component-library';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useParams } from 'react-router-dom';

import { State, useGetWebcastStateQuery } from '../../../generated/graphql-manager';
import { useAbsoluteRoutes } from '../../../routes';
import { addParamToPath } from '../../../utils';
import { useUserPermissions } from '../../hooks/user-permissions';
import managerStyles from '../../manager.module.css';
import { Infobox } from '../infobox';

import styles from './setup-page-footer.module.css';

type SetupPageFooterProps = {
  nextLabel: string;
  route: string;
  saving: boolean;
  showActiveSaveButton?: boolean;
  saveButtonDisabled?: boolean;
  nextButtonDisabled?: boolean;
  savingFailed?: boolean;
  onSaveButtonClick?: () => void;
};

export function SetupPageFooter({
  nextLabel,
  route,
  showActiveSaveButton,
  saveButtonDisabled,
  nextButtonDisabled,
  saving,
  savingFailed,
  onSaveButtonClick,
}: SetupPageFooterProps) {
  const { webcastId = '' } = useParams();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { isEventEditingAllowed } = useUserPermissions();
  const routes = useAbsoluteRoutes();

  const webcastStateResponse = useGetWebcastStateQuery({ variables: { webcastId }, skip: !webcastId });
  const webcastState = webcastStateResponse.data?.webcast?.state;
  const isLiveState = webcastState && webcastState === State.LIVE;
  const isLiveTestState = webcastState && webcastState === State.LIVE_TEST;
  const isPostLiveState = webcastState && webcastState === State.POSTLIVE;
  const isEventSetup = pathname.includes(routes.webcastSetup_eventDetails);
  const isEventDescriptionPage = pathname.includes(routes.webcastSetup_eventDescription);
  const isMediaPage = pathname.includes(routes.webcastSetup_media);
  const editablePartOfWebcast = isEventSetup || isEventDescriptionPage || isMediaPage;

  let infoBoxText = '';

  if (!editablePartOfWebcast && isLiveState) infoBoxText = t('manager.webcastSetup.notEditableMessage.live');
  if (!editablePartOfWebcast && isLiveTestState) infoBoxText = t('manager.webcastSetup.notEditableMessage.liveTest');
  if (!editablePartOfWebcast && isPostLiveState) infoBoxText = t('manager.webcastSetup.notEditableMessage.postLive');
  if (!isEventEditingAllowed) infoBoxText = t('manager.webcastSetup.notEditableMessage.noPermission');

  return (
    <>
      <div className={managerStyles.buttons}>
        {showActiveSaveButton ? (
          <SaveButton
            data-testid="submit-button"
            form="event-details-form"
            disabled={saveButtonDisabled}
            saving={saving}
            savingFailed={savingFailed}
            onClick={onSaveButtonClick}
            timeout={3000}
          />
        ) : (
          <GhostButton type="button" data-testid="changes-saved-button" className={styles.staticButton} tabIndex={-1}>
            {saving ? t('manager.components.ctaPanel.button.saving') : t('manager.components.ctaPanel.button.saved')}
            {saving ? <Spinner /> : <CheckIcon />}
          </GhostButton>
        )}

        <SecondaryButton
          type="button"
          component={NavLink}
          to={addParamToPath(route, webcastId)}
          data-testid="next-step-button"
          disabled={nextButtonDisabled}
        >
          {nextLabel}
          <ArrowRightIcon />
        </SecondaryButton>
      </div>

      {infoBoxText && <Infobox>{infoBoxText}</Infobox>}
    </>
  );
}
