import { type ReactNode, createContext, useContext } from 'react';

type AccessContextValue = {
  email?: string;
  accessCode?: string;
};

const initialContext: AccessContextValue = {};

export const AccessContext = createContext<AccessContextValue>(initialContext);

type Props = {
  email?: string;
  accessCode?: string;
  children: ReactNode;
};

export function ViewerAccessProvider({ email, accessCode, children }: Props) {
  return <AccessContext.Provider value={{ email, accessCode }}>{children}</AccessContext.Provider>;
}

export const useViewerAccess = () => useContext(AccessContext);
