import type { WebcastOrder } from '../../../../../generated/graphql-manager';
import type { WebcastWithTitle } from '../../types';

import { usePrevious } from '@movingimage-evp/mi-ui-component-library';
import { useEffect } from 'react';

import { WebcastCard } from '../../../../../components/webcast-card';
import { useUiState } from '../../../../providers/ui-state-provider/ui-state-provider';

import styles from './grid.module.css';

export type Props = {
  webcasts: WebcastWithTitle[] | undefined;
  orderBy?: WebcastOrder;
  isRefetching?: boolean;
  onDeleteWebcast: (id: WebcastWithTitle['id']) => void;
  onDuplicateWebcast: (id: WebcastWithTitle['id'], title: WebcastWithTitle['title']) => void;
  onSort?: (order: WebcastOrder) => void;
};

export function GridView({ webcasts, isRefetching, onDeleteWebcast, onDuplicateWebcast }: Props) {
  const { getEventsScrollPosition, saveUiState } = useUiState();
  const wasRefetching = usePrevious(isRefetching);

  useEffect(() => {
    const handleScroll = () => {
      saveUiState({ key: 'eventsScrollPosition', value: Math.round(window.scrollY) });
    };

    window.scrollTo({ top: getEventsScrollPosition(), behavior: 'smooth' });
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [getEventsScrollPosition, saveUiState]);

  useEffect(() => {
    if (wasRefetching && !isRefetching) {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    }
  }, [isRefetching, wasRefetching]);

  return (
    <div className={styles.grid}>
      {webcasts?.map((webcast) => {
        const { id, title } = webcast;

        return (
          <WebcastCard
            key={id}
            webcast={webcast}
            onDelete={() => onDeleteWebcast(id)}
            onDuplicate={() => onDuplicateWebcast(id, title)}
          />
        );
      })}

      {/* These divs are needed to make elements in the last row aligned to left and not centered */}
      <div className={styles.webcastCardPlaceholder} />
      <div className={styles.webcastCardPlaceholder} />
      <div className={styles.webcastCardPlaceholder} />
    </div>
  );
}
