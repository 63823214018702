import type { VideoJsPlayer } from 'video.js';

import { useEffect } from 'react';

import { VideoJsCustomEvent, VideoJsNativeEvent } from '../videojs-event';
import { parseDataCues, parseWebkitDataCues } from '../utils/parse-data-cues';
import { supportsNativeHls } from '../utils/supports-native-hls';

export const useTimedMetadata = (player: VideoJsPlayer) => {
  useEffect(() => {
    if (player) {
      let currentTrack: TextTrack;
      let videoElement: HTMLVideoElement | HTMLAudioElement;

      const onNativeCueChange = () => {
        if (!currentTrack.activeCues) {
          return;
        }

        const cues = parseWebkitDataCues(currentTrack.activeCues);

        if (cues.length > 0) {
          player.trigger(VideoJsCustomEvent.TIMED_METADATA, cues);
        }
      };

      const onNativeAddTrack = ({ track }: TrackEvent) => {
        if (!(track?.kind === 'metadata')) {
          return;
        }

        currentTrack?.removeEventListener(VideoJsNativeEvent.CUE_CHANGE, onNativeCueChange);

        currentTrack = track;
        currentTrack.mode = 'hidden';
        currentTrack.addEventListener(VideoJsNativeEvent.CUE_CHANGE, onNativeCueChange);
      };

      const onCueChange = () => {
        if (!currentTrack.activeCues) {
          return;
        }

        const cues = parseDataCues(currentTrack.activeCues);

        if (cues.length > 0) {
          player.trigger(VideoJsCustomEvent.TIMED_METADATA, cues);
        }
      };

      const onAddTrack = ({ track }: TrackEvent) => {
        if (!(track?.kind === 'metadata' && track.label === 'Timed Metadata')) {
          return;
        }

        currentTrack?.removeEventListener(VideoJsNativeEvent.CUE_CHANGE, onCueChange);

        currentTrack = track;
        currentTrack.mode = 'hidden';
        currentTrack.addEventListener(VideoJsNativeEvent.CUE_CHANGE, onCueChange);
      };

      if (supportsNativeHls()) {
        // In case of Safari, videojs + http-streaming choose the native hls streaming.
        // Because of the native playback and functionality, videojs is not needed
        videoElement = player.tech({ IWillNotUseThisInPlugins: true }).el();
        videoElement.textTracks.addEventListener(VideoJsNativeEvent.ADD_TRACK, onNativeAddTrack);
      } else {
        // MSE supported browsers does not support HLS, so videojs take care about the playback
        // and the text-track handling
        player.textTracks().addEventListener(VideoJsNativeEvent.ADD_TRACK, onAddTrack);
      }

      return () => {
        videoElement?.textTracks.removeEventListener(VideoJsNativeEvent.ADD_TRACK, onNativeAddTrack);
        currentTrack?.removeEventListener(VideoJsNativeEvent.CUE_CHANGE, onNativeCueChange);

        const textTracks = player.textTracks();
        textTracks.removeEventListener(VideoJsNativeEvent.ADD_TRACK, onAddTrack);
        currentTrack?.removeEventListener(VideoJsNativeEvent.CUE_CHANGE, onCueChange);
      };
    }
  }, [player]);
};
