import type { ChatType } from '../../../../../generated/graphql-viewer';
import type { LayoutType } from '../layout-changer';

import {
  ChatIcon,
  CollapseIcon,
  ExpandIcon,
  IconButton,
  SwapIcon,
  Tooltip,
  classNames,
} from '@movingimage-evp/mi-ui-component-library';
import { type HTMLAttributes, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useMediaQuery } from '../../../../../common/media-query-provider';
import { useDevTools } from '../../../../../dev-tools';
import { addCssVariables } from '../../../../../utils';
import { ViewerLanguageSwitch } from '../../../../components/viewer-language-switch';
import { useSelectedLanguage } from '../../../../providers/selected-language-provider';
import { toggleFullscreen } from '../../utility-functions';
import { LayoutChanger } from '../layout-changer';

import styles from './header.module.css';

type ViewerHeaderProps = {
  slideVisible?: boolean;
  fullScreenButtonVisible?: boolean;
  fullscreen?: boolean;
  layoutType?: LayoutType;
  chatButtonVisible?: boolean;
  logoUrl?: string;
  chatType?: ChatType;
  languageSwitchHidden?: boolean;
  inIframe?: boolean;
  onLayoutSwapped?: () => void;
  onSetLayoutType?: (layoutType: LayoutType) => void;
  onOpenChat?: () => void;
} & HTMLAttributes<HTMLDivElement>;

export function ViewerHeader({
  slideVisible,
  fullScreenButtonVisible,
  fullscreen = false,
  layoutType = 'sideBySide',
  chatButtonVisible,
  logoUrl,
  chatType,
  className,
  languageSwitchHidden,
  inIframe,
  onLayoutSwapped,
  onSetLayoutType,
  onOpenChat,
  ...props
}: ViewerHeaderProps) {
  const { t } = useTranslation();
  const { isMobile, isDesktop } = useMediaQuery();
  const { availableLanguages } = useSelectedLanguage();
  const {
    mockedViewerControls: { headerButtonsVisible },
  } = useDevTools();

  const logoEnabled = logoUrl && !inIframe;
  const layoutChangerEnabled = (headerButtonsVisible || (slideVisible && onSetLayoutType)) && isDesktop;
  const swapLayoutEnabled = (headerButtonsVisible || slideVisible) && isDesktop;
  const fullScreenButtonEnabled = (headerButtonsVisible || fullScreenButtonVisible) && isDesktop;
  const chatButtonEnabled = chatType && (headerButtonsVisible || chatButtonVisible) && isDesktop;
  const headerLanguageSwitchEnabled = Boolean(
    logoEnabled || slideVisible || (inIframe && chatType) || (!inIframe && isDesktop)
  );
  const mobileLanguageSwitchEnabled = isMobile || !headerLanguageSwitchEnabled;

  useEffect(() => {
    addCssVariables({ '--headerHeight': isDesktop && headerLanguageSwitchEnabled ? '68px' : '0px' });
  }, [isDesktop, headerLanguageSwitchEnabled]);

  return (
    <>
      <header
        className={classNames(styles.wrapper, inIframe && styles.inIframe, chatType && styles.hasChat, className)}
        {...props}
      >
        {logoEnabled && (
          <img data-testid="logo" className={styles.logo} src={logoUrl} alt={t('viewer.views.stream.logo')} />
        )}

        {layoutChangerEnabled && <LayoutChanger layoutType={layoutType} onLayoutChange={onSetLayoutType} />}

        {swapLayoutEnabled && (
          <Tooltip label={t('viewer.views.stream.tooltips.swap')}>
            <IconButton
              data-testid="swap-player-slides"
              type="button"
              className={styles.headerButton}
              aria-label={t('viewer.views.stream.tooltips.swap')}
              onClick={onLayoutSwapped}
            >
              <SwapIcon />
            </IconButton>
          </Tooltip>
        )}

        {fullScreenButtonEnabled && (
          <Tooltip label={t(`viewer.views.stream.tooltips.${fullscreen ? 'collapse' : 'expand'}`)}>
            <IconButton
              data-testid="toggle-fullscreen"
              type="button"
              aria-label={t(`viewer.views.stream.tooltips.${fullscreen ? 'collapse' : 'expand'}`)}
              onClick={() => toggleFullscreen(fullscreen, 'viewer-main')}
              style={{ marginRight: 'auto' }}
            >
              {fullscreen ? <CollapseIcon /> : <ExpandIcon />}
            </IconButton>
          </Tooltip>
        )}

        {headerLanguageSwitchEnabled && (
          <ViewerLanguageSwitch
            tooltip={t('viewer.views.stream.tooltips.languageSwitch')}
            style={{ marginLeft: 'auto' }}
          />
        )}

        {chatButtonEnabled && (
          <Tooltip label={t(`viewer.views.stream.chat.buttons.open.tooltip.${chatType}`)}>
            <IconButton
              data-testid="open-chat"
              aria-label={t(`viewer.views.stream.chat.buttons.open.tooltip.${chatType}`)}
              type="button"
              onClick={onOpenChat}
              className={styles.chatButton}
              style={availableLanguages?.length === 1 ? { marginLeft: 'auto' } : undefined}
            >
              <ChatIcon />
              {t(`viewer.views.stream.chat.buttons.open.label.${chatType}`)}
            </IconButton>
          </Tooltip>
        )}
      </header>

      {mobileLanguageSwitchEnabled && (
        <ViewerLanguageSwitch
          className={styles.mobileLanguageSwitch}
          hidden={languageSwitchHidden}
          tooltip={t('viewer.views.stream.tooltips.languageSwitch')}
        />
      )}
    </>
  );
}
