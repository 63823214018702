import { ReactNode } from 'react';
import classNames from 'classnames';

import type { MenuOption } from './settings-menu.types';

import { ChevronIcon } from '../../icons/chevron';
import { CheckIcon } from '../../icons/check';

import styles from './settings-menu.module.css';

type SubMenuProps = {
  label: ReactNode;
  chosenItemId?: string;
  menuOptions?: MenuOption[];
  onChooseItem?: (itemId: string) => void;
  onGoBack: () => void;
  dataTestId: string;
};

export function SubMenu({ label, chosenItemId, menuOptions, onChooseItem, onGoBack, dataTestId }: SubMenuProps) {
  return (
    <div role="menu" className={styles.menu} data-testid={dataTestId}>
      <button
        type="button"
        role="menuitem"
        className={classNames(styles.item, styles.backButton)}
        onClick={onGoBack}
        data-testid="menu-back-button"
      >
        <ChevronIcon />
        {label}
      </button>

      {menuOptions?.map((option) => (
        <button
          type="button"
          role="menuitem"
          key={option.id}
          data-testid={option.id || option.label}
          className={classNames(styles.item, styles.subMenuItem, { [styles.active]: option.id === chosenItemId })}
          onClick={() => {
            onChooseItem?.(option.id);
            onGoBack();
          }}
        >
          <CheckIcon />
          <span lang={option.languageCode}>{option.label}</span>
        </button>
      ))}
    </div>
  );
}
