import type { GraphqlError } from './error-component';
import type { FetchResult, Operation } from '@apollo/client';

import { ApolloLink } from '@apollo/client';
import i18next from 'i18next';

import { getErrorMessage } from './error-message-map';

const ERRORS_HANDLED_IN_UI = [
  'DeleteWebcastEvent',
  'DuplicateWebcast',
  'DeleteAppearanceAsset',
  'CreateAppearanceAsset',
];

export const problemsHandler = (setError: (error: GraphqlError) => void) => {
  return new ApolloLink((operation, forward) =>
    forward(operation).map((response) => {
      return handleResponse(response, operation, setError);
    })
  );
};

export const handleResponse = (
  response: FetchResult,
  operation: Operation,
  setError: (error: GraphqlError) => void
) => {
  const setErrorMessage = ({
    operationName,
    errorCode,
    errorMessage,
  }: {
    operationName: string;
    errorCode?: string;
    errorMessage?: string;
  }) => {
    const message = getErrorMessage(operationName);

    if (message) {
      setError(message);
    } else {
      setError({
        message: i18next.t('common.graphQlErrorDetails', {
          details: `${operationName} - ${errorCode || ''}${errorMessage || ''}`,
        }),
      });
    }
  };

  if (response.data) {
    // Don't do anything if we handle errors in UI
    if (ERRORS_HANDLED_IN_UI.includes(operation.operationName)) return response;

    const responseKeys = Object.keys(response.data);

    for (const key of responseKeys) {
      const __typename = response.data[key]?.__typename;
      const code = response.data[key]?.code;

      if (
        __typename?.endsWith('Error') ||
        __typename?.endsWith('Errors') ||
        __typename?.endsWith('Problem') ||
        __typename?.endsWith('Failure')
      ) {
        setErrorMessage({ operationName: operation.operationName, errorCode: code });
      }
    }
  }

  // Errors sometimes come in `data` field, sometimes in `errors` field, so we need to handle both cases
  response.errors?.forEach(({ extensions, message }) => {
    // Handled in UI
    if (
      (extensions?.errorType === 'NOT_FOUND' && message === 'Webcast not found') ||
      (extensions?.errorType === 'NOT_FOUND' && message.startsWith('VideoManager') && message.endsWith('not found')) ||
      (extensions?.errorType === 'BAD_REQUEST' && message === 'Invalid email address') ||
      (extensions?.errorType === 'BAD_REQUEST' && message === 'Email address not whitelisted')
    ) {
      return;
    }

    if (ERRORS_HANDLED_IN_UI.includes(operation.operationName)) return response;

    setErrorMessage({ operationName: operation.operationName, errorMessage: message });
  });

  return response;
};
