// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip_tooltip__lOLnl {
  flex: none;

  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);

  background: var(--backgroundColor);
  color: var(--textColor);

  border-radius: 2px;
  padding: 6px 12px;

  font-size: 12px;
  line-height: 15px;
  white-space: nowrap;

  opacity: 0;
  pointer-events: none;
  transition-property: opacity, top;
  transition-duration: 0.2s;
}

[class*='buttonsSection'] > *:first-child .tooltip_tooltip__lOLnl {
  left: 0;
  transform: none;
}

[class*='buttonsSection'] > *:last-child .tooltip_tooltip__lOLnl {
  right: 0;
  left: auto;
  transform: none;
}

*:hover > .tooltip_tooltip__lOLnl {
  opacity: 1;
  top: -34px;
  transition-delay: 0.5s;
}

*:hover > .tooltip_tooltip__lOLnl.tooltip_alignBottom__2uDB3 {
    top: 34px;
  }

.menuIsOpen *:hover > .tooltip_tooltip__lOLnl,
.mobile *:hover > .tooltip_tooltip__lOLnl {
  opacity: 0;
  top: 0;
  transition: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/tooltip/tooltip.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;;EAEV,kBAAkB;EAClB,MAAM;EACN,SAAS;EACT,0BAA0B;;EAE1B,kCAAkC;EAClC,uBAAuB;;EAEvB,kBAAkB;EAClB,iBAAiB;;EAEjB,eAAe;EACf,iBAAiB;EACjB,mBAAmB;;EAEnB,UAAU;EACV,oBAAoB;EACpB,iCAAiC;EACjC,yBAAyB;AAC3B;;AAEA;EACE,OAAO;EACP,eAAe;AACjB;;AAEA;EACE,QAAQ;EACR,UAAU;EACV,eAAe;AACjB;;AAEA;EACE,UAAU;EACV,UAAU;EACV,sBAAsB;AAKxB;;AAHE;IACE,SAAS;EACX;;AAGF;;EAEE,UAAU;EACV,MAAM;EACN,gBAAgB;AAClB","sourcesContent":[".tooltip {\n  flex: none;\n\n  position: absolute;\n  top: 0;\n  left: 50%;\n  transform: translate(-50%);\n\n  background: var(--backgroundColor);\n  color: var(--textColor);\n\n  border-radius: 2px;\n  padding: 6px 12px;\n\n  font-size: 12px;\n  line-height: 15px;\n  white-space: nowrap;\n\n  opacity: 0;\n  pointer-events: none;\n  transition-property: opacity, top;\n  transition-duration: 0.2s;\n}\n\n[class*='buttonsSection'] > *:first-child .tooltip {\n  left: 0;\n  transform: none;\n}\n\n[class*='buttonsSection'] > *:last-child .tooltip {\n  right: 0;\n  left: auto;\n  transform: none;\n}\n\n*:hover > .tooltip {\n  opacity: 1;\n  top: -34px;\n  transition-delay: 0.5s;\n\n  &.alignBottom {\n    top: 34px;\n  }\n}\n\n:global(.menuIsOpen) *:hover > .tooltip,\n:global(.mobile) *:hover > .tooltip {\n  opacity: 0;\n  top: 0;\n  transition: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": `tooltip_tooltip__lOLnl`,
	"alignBottom": `tooltip_alignBottom__2uDB3`
};
export default ___CSS_LOADER_EXPORT___;
