import type { ValidationErrors, WebcastData } from './event-details';

import i18next from 'i18next';

import { isPassedTime } from './is-passed-time';

export const MAX_TITLE_LENGTH = 100;

export function eventDetailsValidation(values: WebcastData, validatePastDate?: boolean) {
  const errors: ValidationErrors = {};

  if (!values.title.trim()) {
    errors.title = i18next.t('translations:manager.webcastSetup.eventDetails.title.validation.required');
  } else if (values.title.trim().length > MAX_TITLE_LENGTH) {
    errors.title = i18next.t('translations:manager.webcastSetup.eventDetails.title.validation.tooLong', {
      maxTitleLength: MAX_TITLE_LENGTH,
    });
  }

  if (!values.date) {
    errors.date = i18next.t('translations:manager.webcastSetup.eventDetails.date.validation.required');
  }

  if (!Number(values.durationInMinutes)) {
    errors.durationInMinutes = i18next.t('translations:manager.webcastSetup.eventDetails.duration.validation.required');
  }

  if (!values.startingHour) {
    errors.startingHour = i18next.t('translations:manager.webcastSetup.eventDetails.time.hour.validation.required');
  }

  if (!values.startingMinutes) {
    errors.startingMinutes = i18next.t(
      'translations:manager.webcastSetup.eventDetails.time.minutes.validation.required'
    );
  }

  if (
    values.startingHour &&
    values.startingMinutes &&
    values.date &&
    isPassedTime(values.date, Number(values.startingHour), Number(values.startingMinutes), new Date()) &&
    validatePastDate
  ) {
    errors.startingHour = i18next.t('translations:manager.webcastSetup.eventDetails.time.validation.passed');
  }

  return errors;
}
