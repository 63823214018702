import type { GetWebcastsQuery } from '../../../../generated/graphql-manager';
import type { WebcastWithTitle } from '../types';
import type { IndicatorStatus } from '@movingimage-evp/mi-ui-component-library';

import { CheckCircleIcon, CloseIcon, ProgressIndicator, classNames } from '@movingimage-evp/mi-ui-component-library';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  GetWebcastsDocument as GET_WEBCASTS,
  State,
  useDuplicateWebcastMutation,
  useGetWebcastStateForDuplicationQuery,
} from '../../../../generated/graphql-manager';
import { useAbsoluteRoutes } from '../../../../routes';
import { addParamToPath } from '../../../../utils';
import { useCurrentUser } from '../../../hooks/current-user';
import { getWebcastTitle } from '../../../hooks/use-get-primary-title';

import styles from './duplicate-webcast.module.css';

type Props = {
  duplicatedWebcastId: WebcastWithTitle['id'];
  duplicatedWebcastTitle: WebcastWithTitle['title'];
  onWebcastDuplicated: () => void;
  onWebcastDuplicatedError: () => void;
};

export function DuplicationProgressIndicator({
  duplicatedWebcastId,
  duplicatedWebcastTitle,
  onWebcastDuplicated,
  onWebcastDuplicatedError,
}: Props) {
  const navigate = useNavigate();
  const { lsproId } = useCurrentUser();
  const routes = useAbsoluteRoutes();
  const { t } = useTranslation();

  const [newWebcastId, setNewWebcastId] = useState('');
  const [duplicateWebcastMutation, { called }] = useDuplicateWebcastMutation();
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [error, setError] = useState(false);

  const {
    data: getWebcastResponse,
    startPolling,
    stopPolling,
  } = useGetWebcastStateForDuplicationQuery({
    variables: { webcastId: newWebcastId },
    skip: !newWebcastId,
  });

  const webcastDuplicated = getWebcastResponse?.webcast.state === 'PRELIVE';

  const duplicateWebcast = useCallback(() => {
    duplicateWebcastMutation({
      variables: { input: { id: duplicatedWebcastId } },
      update: (cache, { data }) => {
        if (data?.duplicateWebcast.__typename !== 'DuplicateWebcastSuccess') return;

        const cachedWebcastsQuery = cache.readQuery<GetWebcastsQuery>({
          query: GET_WEBCASTS,
          variables: { lsproId },
        });

        const originalWebcastData = cachedWebcastsQuery?.webcasts.edges.find(
          (edge) => edge.node.id === duplicatedWebcastId
        )?.node;

        const originalWebcastIndex = cachedWebcastsQuery?.webcasts.edges.findIndex(
          (edge) => edge.node.id === duplicatedWebcastId
        );

        if (cachedWebcastsQuery?.webcasts.edges && originalWebcastIndex !== undefined && originalWebcastData) {
          const title = getWebcastTitle(originalWebcastData?.primaryLanguage, originalWebcastData?.contents);

          const newWebcastData = {
            __typename: 'WebcastEdge',
            node: {
              ...originalWebcastData,
              id: data.duplicateWebcast.webcast.id,
              state: State.INITIALIZING,
              contents: [
                {
                  __typename: 'WebcastContent',
                  language: originalWebcastData?.primaryLanguage,
                  title: `${title} ${t('manager.webcasts.duplicateWebcast.copy')}`,
                },
              ],
            },
          };

          cache.writeQuery({
            query: GET_WEBCASTS,
            variables: { lsproId },
            data: {
              webcasts: {
                ...cachedWebcastsQuery.webcasts,
                edges: [
                  ...cachedWebcastsQuery.webcasts.edges.slice(0, originalWebcastIndex + 1),
                  newWebcastData,
                  ...cachedWebcastsQuery.webcasts.edges.slice(originalWebcastIndex + 1),
                ],
              },
            },
          });
        }
      },
      onCompleted: (data) => {
        if (data.duplicateWebcast.__typename === 'DuplicateWebcastProblem') {
          setError(true);
          setProgressPercentage(100);
          onWebcastDuplicatedError();
        }

        if (data.duplicateWebcast.__typename === 'DuplicateWebcastSuccess') {
          setNewWebcastId(data.duplicateWebcast.webcast.id);
          setProgressPercentage(100);
        }
      },
    });
  }, [duplicateWebcastMutation, duplicatedWebcastId, lsproId, onWebcastDuplicatedError, t]);

  useEffect(() => {
    if (called) return;

    duplicateWebcast();
  }, [called, duplicateWebcast]);

  useEffect(() => {
    if (webcastDuplicated) {
      onWebcastDuplicated();
      stopPolling();
      return;
    }

    startPolling(1000);
  }, [webcastDuplicated, onWebcastDuplicated, startPolling, stopPolling]);

  const progressIndicator = {
    INITIALIZING: {
      label: t('manager.webcasts.duplicateWebcast.progressIndicator.initializingLabel', {
        webcastTitle: duplicatedWebcastTitle,
      }),
      status: 'PROCESSING' as IndicatorStatus,
    },
    PRELIVE: {
      label: t('manager.webcasts.duplicateWebcast.progressIndicator.finishedLabel', {
        webcastTitle: duplicatedWebcastTitle,
      }),
      status: 'FINISHED' as IndicatorStatus,
      button: t('manager.webcasts.duplicateWebcast.progressIndicator.finishedButtonLabel'),
      onButtonClick: () => {
        navigate(addParamToPath(routes.webcastSetup_setupSummary, newWebcastId));
      },
      icon: <CheckCircleIcon style={{ fill: 'var(--mi-green)' }} />,
    },
    POSTLIVE: {
      status: 'FINISHED' as IndicatorStatus,
    },
    LIVE: {
      status: 'FINISHED' as IndicatorStatus,
    },
    LIVE_TEST: {
      status: 'FINISHED' as IndicatorStatus,
    },
    FAILED: {
      label: t('manager.webcasts.duplicateWebcast.progressIndicator.errorLabel', {
        webcastTitle: duplicatedWebcastTitle,
      }),
      status: 'FAILED' as IndicatorStatus,
      button: t('manager.webcasts.duplicateWebcast.progressIndicator.errorButtonLabel'),
      onButtonClick: duplicateWebcast,
      icon: <CloseIcon style={{ fill: 'var(--mi-red)' }} />,
    },
  }[error ? 'FAILED' : getWebcastResponse?.webcast.state || 'INITIALIZING'];

  return (
    <div
      data-testid="duplication-progress-indicator"
      className={classNames(
        styles.duplicationProgressIndicator,
        getWebcastResponse?.webcast.state === 'INITIALIZING' && styles.duplicating
      )}
    >
      <ProgressIndicator
        progressPercentage={progressPercentage}
        status={progressIndicator.status}
        label={progressIndicator.label}
        icon={progressIndicator.icon}
        buttonLabel={progressIndicator.button}
        onButtonClicked={progressIndicator.onButtonClick}
      />
    </div>
  );
}
