import { LiveUI, LiveUIMobile } from './live';
import { VodUI, VodUIMobile } from './vod';
import { UserInterfaceProps } from './user-interface.types';

export function UserInterface({
  elementVisibilitySettings = {},
  breakpoints = [],
  isMobile = false,
  isPlaying = false,
  isStopped = true,
  logoUrl = '',
  title = '',
  currentTime = 0,
  totalTime = 0,
  bufferPercentage = 0,
  isLooping = false,
  volume = 1,
  isFullscreen = false,
  liveUI = false,
  audioOnly = false,
  audioOnlyImageUrl = '',
  subtitlesEnabled = false,
  subtitle = '',
  customPrimaryColor = '',
  theme,
  controlBarColor,
  iconColor,
  subtitleOptionId = '',
  subtitleOptions = [],
  qualityId = '',
  qualities = [],
  autoQualityOptionAvailable = true,
  playbackSpeedId = '',
  playbackSpeeds = [],
  storyboard,
  downloadSources,
  watermark,
  isTrimmable = false,
  trimStartTime = 0,
  trimEndTime = 0,
  isMuted = false,
  onPlaybackToggle,
  onTimeChange,
  onTimeDeltaChange,
  onLoopButtonClick,
  onVolumeChange,
  onVolumeDeltaChange,
  onMuteChange,
  onFullscreenToggle,
  onSetSubtitle,
  onSetQuality,
  onSetPlaybackSpeed,
  onTrimStartTimeChange,
  onTrimEndTimeChange,
  audioTrackId = '',
  audioTracks,
  onSetAudioTrack,
  onOverlayVisibilityChange,
}: UserInterfaceProps) {
  function renderVodUI(isMobile: boolean) {
    return isMobile ? (
      <VodUIMobile
        elementVisibilitySettings={elementVisibilitySettings}
        breakpoints={breakpoints}
        customPrimaryColor={customPrimaryColor}
        theme={theme}
        controlBarColor={controlBarColor}
        iconColor={iconColor}
        isFullscreen={isFullscreen}
        isPlaying={isPlaying}
        isStopped={isStopped}
        audioOnly={audioOnly}
        audioOnlyImageUrl={audioOnlyImageUrl}
        isTrimmable={isTrimmable}
        bufferPercentage={bufferPercentage}
        currentTime={currentTime}
        logoUrl={logoUrl}
        playbackSpeedId={playbackSpeedId}
        playbackSpeeds={playbackSpeeds}
        qualities={qualities}
        autoQualityOptionAvailable={autoQualityOptionAvailable}
        qualityId={qualityId}
        storyboard={storyboard}
        subtitle={subtitle}
        subtitleOptionId={subtitleOptionId}
        subtitleOptions={subtitleOptions}
        subtitlesEnabled={subtitlesEnabled}
        title={title}
        totalTime={totalTime}
        trimEndTime={trimEndTime}
        trimStartTime={trimStartTime}
        downloadSources={downloadSources}
        watermark={watermark}
        onFullscreenToggle={onFullscreenToggle}
        onPlaybackToggle={onPlaybackToggle}
        onSetPlaybackSpeed={onSetPlaybackSpeed}
        onSetSubtitle={onSetSubtitle}
        onSetQuality={onSetQuality}
        onTimeChange={onTimeChange}
        onTimeDeltaChange={onTimeDeltaChange}
        onTrimEndTimeChange={onTrimEndTimeChange}
        onTrimStartTimeChange={onTrimStartTimeChange}
        audioTracks={audioTracks}
        audioTrackId={audioTrackId}
        onSetAudioTrack={onSetAudioTrack}
        onOverlayVisibilityChange={onOverlayVisibilityChange}
      />
    ) : (
      <VodUI
        elementVisibilitySettings={elementVisibilitySettings}
        breakpoints={breakpoints}
        customPrimaryColor={customPrimaryColor}
        theme={theme}
        controlBarColor={controlBarColor}
        iconColor={iconColor}
        isFullscreen={isFullscreen}
        isPlaying={isPlaying}
        isStopped={isStopped}
        isLooping={isLooping}
        audioOnly={audioOnly}
        audioOnlyImageUrl={audioOnlyImageUrl}
        isTrimmable={isTrimmable}
        bufferPercentage={bufferPercentage}
        currentTime={currentTime}
        logoUrl={logoUrl}
        playbackSpeedId={playbackSpeedId}
        playbackSpeeds={playbackSpeeds}
        qualities={qualities}
        autoQualityOptionAvailable={autoQualityOptionAvailable}
        qualityId={qualityId}
        storyboard={storyboard}
        subtitle={subtitle}
        subtitleOptionId={subtitleOptionId}
        subtitleOptions={subtitleOptions}
        subtitlesEnabled={subtitlesEnabled}
        title={title}
        totalTime={totalTime}
        trimEndTime={trimEndTime}
        trimStartTime={trimStartTime}
        volume={volume}
        isMuted={isMuted}
        watermark={watermark}
        downloadSources={downloadSources}
        onFullscreenToggle={onFullscreenToggle}
        onPlaybackToggle={onPlaybackToggle}
        onLoopButtonClick={onLoopButtonClick}
        onSetPlaybackSpeed={onSetPlaybackSpeed}
        onSetSubtitle={onSetSubtitle}
        onSetQuality={onSetQuality}
        onTimeChange={onTimeChange}
        onTimeDeltaChange={onTimeDeltaChange}
        onTrimEndTimeChange={onTrimEndTimeChange}
        onTrimStartTimeChange={onTrimStartTimeChange}
        onVolumeChange={onVolumeChange}
        onVolumeDeltaChange={onVolumeDeltaChange}
        onMuteChange={onMuteChange}
        audioTracks={audioTracks}
        audioTrackId={audioTrackId}
        onSetAudioTrack={onSetAudioTrack}
        onOverlayVisibilityChange={onOverlayVisibilityChange}
      />
    );
  }

  function renderLiveUI(isMobile: boolean) {
    return isMobile ? (
      <LiveUIMobile
        elementVisibilitySettings={elementVisibilitySettings}
        breakpoints={breakpoints}
        customPrimaryColor={customPrimaryColor}
        theme={theme}
        controlBarColor={controlBarColor}
        iconColor={iconColor}
        isFullscreen={isFullscreen}
        isPlaying={isPlaying}
        isStopped={isStopped}
        audioOnly={audioOnly}
        audioOnlyImageUrl={audioOnlyImageUrl}
        bufferPercentage={bufferPercentage}
        currentTime={currentTime}
        logoUrl={logoUrl}
        playbackSpeedId={playbackSpeedId}
        playbackSpeeds={playbackSpeeds}
        qualities={qualities}
        autoQualityOptionAvailable={autoQualityOptionAvailable}
        qualityId={qualityId}
        storyboard={storyboard}
        subtitle={subtitle}
        subtitleOptionId={subtitleOptionId}
        subtitleOptions={subtitleOptions}
        subtitlesEnabled={subtitlesEnabled}
        title={title}
        totalTime={totalTime}
        watermark={watermark}
        onFullscreenToggle={onFullscreenToggle}
        onPlaybackToggle={onPlaybackToggle}
        onSetSubtitle={onSetSubtitle}
        onSetQuality={onSetQuality}
        onTimeChange={onTimeChange}
        audioTracks={audioTracks}
        audioTrackId={audioTrackId}
        onSetAudioTrack={onSetAudioTrack}
        onOverlayVisibilityChange={onOverlayVisibilityChange}
      />
    ) : (
      <LiveUI
        elementVisibilitySettings={elementVisibilitySettings}
        breakpoints={breakpoints}
        customPrimaryColor={customPrimaryColor}
        theme={theme}
        controlBarColor={controlBarColor}
        iconColor={iconColor}
        isFullscreen={isFullscreen}
        isPlaying={isPlaying}
        isStopped={isStopped}
        audioOnly={audioOnly}
        audioOnlyImageUrl={audioOnlyImageUrl}
        bufferPercentage={bufferPercentage}
        currentTime={currentTime}
        logoUrl={logoUrl}
        playbackSpeedId={playbackSpeedId}
        playbackSpeeds={playbackSpeeds}
        qualities={qualities}
        autoQualityOptionAvailable={autoQualityOptionAvailable}
        qualityId={qualityId}
        storyboard={storyboard}
        subtitle={subtitle}
        subtitleOptionId={subtitleOptionId}
        subtitleOptions={subtitleOptions}
        subtitlesEnabled={subtitlesEnabled}
        title={title}
        totalTime={totalTime}
        volume={volume}
        isMuted={isMuted}
        watermark={watermark}
        onFullscreenToggle={onFullscreenToggle}
        onPlaybackToggle={onPlaybackToggle}
        onSetSubtitle={onSetSubtitle}
        onSetQuality={onSetQuality}
        onTimeChange={onTimeChange}
        onVolumeChange={onVolumeChange}
        onVolumeDeltaChange={onVolumeDeltaChange}
        onMuteChange={onMuteChange}
        audioTracks={audioTracks}
        audioTrackId={audioTrackId}
        onSetAudioTrack={onSetAudioTrack}
        onOverlayVisibilityChange={onOverlayVisibilityChange}
      />
    );
  }

  return liveUI ? renderLiveUI(isMobile) : renderVodUI(isMobile);
}
