import type { GetWebcastSummaryManagerQuery } from '../../../../../../generated/graphql-manager';

import {
  ClockIcon,
  PeakIcon,
  SubHeading,
  UsersMultipleIcon,
  classNames,
} from '@movingimage-evp/mi-ui-component-library';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReportType } from '../../../../../../generated/graphql-manager';
import { useFeatureToggle } from '../../../../../../service/feature-toggle/feature-toggle';
import { PostLiveEventInfobox } from '../../../../../components/post-live-event-infobox';
import infoboxStyles from '../../../../../components/post-live-event-infobox/post-live-event-infobox.module.css';
import { DownloadReport } from '../download-report/download-report';

import styles from './event-summary-metrics.module.css';

type EventSummaryMetricsProps = {
  webcast: GetWebcastSummaryManagerQuery['webcast'];
  session?: GetWebcastSummaryManagerQuery['session'];
};

export function EventSummaryMetrics({ webcast, session }: EventSummaryMetricsProps) {
  const totalViewersAvailable = useFeatureToggle('totalViewers');
  const { t } = useTranslation();
  const [streamingTime, setStreamingTime] = useState('00:00:00');

  const wasChatUsed = Boolean(webcast?.chat?.type);
  const wasSecurityUsed = Boolean(webcast?.securityPolicies?.find(({ enabled }) => enabled));

  useEffect(() => {
    if (session?.__typename !== 'GetSessionSuccess') return;

    const startStreaming = new Date(session.startDate);
    const endStreaming = session.endDate && new Date(session.endDate);

    const timeDifference = endStreaming && endStreaming.getTime() - startStreaming.getTime();

    if (!timeDifference) return;

    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    // Format the time as hh:mm:ss
    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;

    setStreamingTime(formattedTime);
  }, [session]);

  return (
    <div className={styles.metricsContainer}>
      <PostLiveEventInfobox
        title={t('manager.eventSummary.metrics.streamingTime')}
        icon={<ClockIcon />}
        value={streamingTime}
      />

      {totalViewersAvailable && (
        <PostLiveEventInfobox
          title={t('manager.eventSummary.metrics.viewers')}
          icon={<UsersMultipleIcon />}
          value={webcast.analytics?.uniqueUsers || 0}
        />
      )}

      <PostLiveEventInfobox
        title={t('manager.eventSummary.metrics.peakViewers')}
        icon={<PeakIcon />}
        value={webcast.analytics?.peakActivePlays || 0}
      />

      <div className={classNames(infoboxStyles.wrapper, totalViewersAvailable && infoboxStyles.totalViewersAvailable)}>
        <div className={styles.downloadButtonsWrapper}>
          <SubHeading>{t('manager.eventSummary.reports.title')}</SubHeading>

          <DownloadReport
            label={t('manager.eventSummary.reports.downloadViewers')}
            generateLabel={t('manager.eventSummary.reports.generateViewers')}
            webcastId={webcast.id}
            reportType={ReportType.VIEWS_REPORT}
          />

          {wasSecurityUsed && (
            <DownloadReport
              label={t('manager.eventSummary.reports.downloadRegisteredUsers')}
              generateLabel={t('manager.eventSummary.reports.generateRegisteredUsers')}
              webcastId={webcast.id}
              reportType={ReportType.REGISTERED_USERS_REPORT}
            />
          )}

          {wasChatUsed && (
            <DownloadReport
              label={t('manager.eventSummary.reports.downloadChatMessages')}
              generateLabel={t('manager.eventSummary.reports.generateChatMessages')}
              webcastId={webcast.id}
              reportType={ReportType.CHAT_MESSAGES_REPORT}
            />
          )}
        </div>
      </div>
    </div>
  );
}
