import type { KeycloakLoginOptions } from 'keycloak-js';
import type { ReactNode } from 'react';

import Keycloak from 'keycloak-js';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { isIdpLogin } from './is-idp-login';
import { LoadingScreen } from '../../components/loading-screen';

const config = {
  realm: process.env.REACT_APP_AUTHENTICATION_KEYCLOAK_REALM || '',
  url: process.env.REACT_APP_AUTHENTICATION_KEYCLOAK_URI,
  resource: process.env.REACT_APP_AUTHENTICATION_KEYCLOAK_CLIENT_ID || '',
  clientId: process.env.REACT_APP_AUTHENTICATION_KEYCLOAK_CLIENT_ID || '',
};

export const keycloak = new Keycloak(config);

export const getUserNameAndInitials = () => {
  const user = keycloak?.tokenParsed;
  const name = user?.given_name;
  const surname = user?.family_name;
  const fullName = user?.name;
  const email = user?.email;

  let initials = 'A';
  if (name) initials = name[0];
  if (surname) initials = surname[0];
  if (name && surname) initials = `${name[0]}${surname[0]}`;

  const userName = fullName || email;

  return { userName, email, initials };
};

export function KeycloakProvider({ children }: { children: ReactNode }) {
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
  const initializing = useRef(false);
  const { hash } = useLocation();

  useEffect(() => {
    if (initializing.current) return;
    initializing.current = true;

    const getKeycloakLoginOptions = () => {
      const loginOptions: KeycloakLoginOptions = {};

      if (isIdpLogin(hash)) {
        const [, identity] = hash.split('=');
        const hints = identity?.split('&');

        if (identity && hints?.length > 0) {
          loginOptions.idpHint = hints[0];
        }
      }

      return loginOptions;
    };

    keycloak
      .init({
        onLoad: 'check-sso',
        checkLoginIframe: false,
        pkceMethod: 'S256',
      })
      .then((authenticated) => {
        setIsUserAuthenticated(authenticated);
        if (!authenticated) keycloak.login(getKeycloakLoginOptions());
      })
      .catch(() => {
        setIsUserAuthenticated(false);
        keycloak.logout();
      });
  }, [hash]);

  if (!isUserAuthenticated) return <LoadingScreen />;

  return <>{children}</>;
}
