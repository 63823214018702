import type { HiveToken } from '../../../generated/graphql-viewer';
import type { PlayerConfig, PlayerSources, PluginsConfig, SettingsConfig } from '@movingimage-evp/player-library';

import * as FingerprintJS from '@fingerprintjs/fingerprintjs';
import { SourceType } from '@movingimage-evp/player-library';
import { useEffect, useState } from 'react';

export type LsproPlayerConfig = {
  webcastId: string;
  contentId: string;
  playbackUrl?: string;
  title?: string;
  accountId?: string;
  lsproId?: string;
  primaryColor?: string;
  logoUrl?: string;
  hiveConfiguration?: HiveToken | null;
  state?: string;
  channelName?: string;
  channelId?: string;
  videoManagerId?: string;
  groupId?: string;
  language?: string;
  muted?: boolean;
  autoplay?: boolean;
};

export function usePlayerConfig(config?: LsproPlayerConfig): PlayerConfig | undefined {
  const [playerConfig, setPlayerConfig] = useState<PlayerConfig | undefined>();

  useEffect(() => {
    if (!config) return;

    const { playbackUrl } = config;

    if (!playbackUrl) return;

    const updatePlayerConfig = async () => {
      let visitorId = '';

      try {
        visitorId = await fetchVisitorId();
      } finally {
        setPlayerConfig(getPlayerConfig({ ...config, username: visitorId, playbackUrl }));
      }
    };

    updatePlayerConfig();
  }, [config]);

  return playerConfig;
}

function getPlugins({
  webcastId,
  contentId,
  title,
  username,
  accountId,
  lsproId,
  hiveConfiguration,
  state,
  channelName,
  channelId,
  videoManagerId,
  groupId,
  language,
}: LsproPlayerConfig & { username: string }): PluginsConfig {
  return {
    youboraAnalytics: {
      accountCode: process.env.REACT_APP_NPAW_ACCOUNT_CODE || '',
      'user.name': username,
      'content.title': title || '',
      'content.id': contentId,
      'content.channel': channelName || '',
      'content.language': language || '',
      'content.customDimension.1': videoManagerId || '',
      'content.customDimension.3': channelId || '',
      'content.customDimension.6': groupId || '',
      'content.customDimension.7': accountId || '',
      'content.customDimension.8': 'LiveStream Pro',
      'content.customDimension.9': state,
      'content.customDimension.10': webcastId,
      'content.customDimension.11': lsproId,
    },
    ...(hiveConfiguration
      ? {
          hive: {
            eventName: title,
            token: {
              videoId: webcastId,
              jwt: hiveConfiguration.jwt || '',
              manifest: hiveConfiguration.manifest || '',
            },
          },
        }
      : {}),
  };
}

function getSources(src: string): PlayerSources {
  return {
    hls: {
      src,
      type: SourceType.HLS,
    },
  };
}

function getSettings(primaryColor?: string, logoUrl?: string, muted?: boolean, autoplay?: boolean): SettingsConfig {
  return {
    liveUI: true,
    hideLoopButton: true,
    primaryColor,
    logoUrl,
    maxPlaylistRetries: 0,
    autoplay,
    videoLayout: 'fill',
    muted,
  };
}

async function fetchVisitorId(): Promise<string> {
  const agent = await FingerprintJS.load({ monitoring: false });
  const { visitorId } = await agent.get();

  return visitorId;
}

function getPlayerConfig(config: LsproPlayerConfig & { username: string; playbackUrl: string }): PlayerConfig {
  return {
    sources: getSources(config.playbackUrl),
    settings: getSettings(config.primaryColor, config.logoUrl, config.muted, config.autoplay),
    metadata: { title: config.title },
    plugins: getPlugins(config),
    lng: config.language,
  };
}
