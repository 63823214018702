// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timer_wrapper__ws8sQ {
  color: var(--overlayForeground);
}

.mobile .timer_wrapper__ws8sQ {
  font-size: 12px;
  line-height: 15px;
}

.desktop .timer_wrapper__ws8sQ {
  font-size: 16px;
  line-height: 20px;
  margin-right: auto;
}

.timer_wrapper__ws8sQ > *:nth-child(2) {
  opacity: 0.7;
  margin-left: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/timer/timer.module.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;AACjC;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":[".wrapper {\n  color: var(--overlayForeground);\n}\n\n:global(.mobile) .wrapper {\n  font-size: 12px;\n  line-height: 15px;\n}\n\n:global(.desktop) .wrapper {\n  font-size: 16px;\n  line-height: 20px;\n  margin-right: auto;\n}\n\n.wrapper > *:nth-child(2) {\n  opacity: 0.7;\n  margin-left: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `timer_wrapper__ws8sQ`
};
export default ___CSS_LOADER_EXPORT___;
