// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[4].use[1]!../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[4].use[2]!../../styles/common.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.big-play-button_button__ASbwy {
  height: min(27%, 129px);
  width: auto;
  aspect-ratio: 1;

  -webkit-tap-highlight-color: transparent;
  border-radius: 50%;
}

.big-play-button_button__ASbwy svg {
  width: 100%;
  height: 100%;
}

.big-play-button_button__ASbwy circle {
  fill: var(--primaryColor);
}

.big-play-button_button__ASbwy path {
  fill: var(--overlayForeground);
}

@media (any-hover: hover) {
  .big-play-button_button__ASbwy:hover circle {
    fill: var(--overlayForeground);
  }

  .big-play-button_button__ASbwy:hover path {
    fill: var(--primaryColor);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/big-play-button/big-play-button.module.css"],"names":[],"mappings":"AAAA;EAEE,uBAAuB;EACvB,WAAW;EACX,eAAe;;EAEf,wCAAwC;EACxC,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE;IACE,8BAA8B;EAChC;;EAEA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".button {\n  composes: button from '../../styles/common.module.css';\n  height: min(27%, 129px);\n  width: auto;\n  aspect-ratio: 1;\n\n  -webkit-tap-highlight-color: transparent;\n  border-radius: 50%;\n}\n\n.button svg {\n  width: 100%;\n  height: 100%;\n}\n\n.button circle {\n  fill: var(--primaryColor);\n}\n\n.button path {\n  fill: var(--overlayForeground);\n}\n\n@media (any-hover: hover) {\n  .button:hover circle {\n    fill: var(--overlayForeground);\n  }\n\n  .button:hover path {\n    fill: var(--primaryColor);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `big-play-button_button__ASbwy ${___CSS_LOADER_ICSS_IMPORT_0___.locals["button"]}`
};
export default ___CSS_LOADER_EXPORT___;
