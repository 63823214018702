// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timeline_wrapper__1uawa {
  --thumb-size: 18px;

  display: flex;
  align-items: center;
  height: var(--thumb-height);
  position: relative;
}

.mobile .timeline_wrapper__1uawa {
  flex: 1;
}

.desktop .timeline_wrapper__1uawa {
  flex: none;
}

.timeline_indicators__t1NV5 {
  position: absolute;

  width: 100%;

  background: var(--sliderBackgroundColor);
  border-radius: 2px;
  overflow: hidden;
}

.mobile .timeline_indicators__t1NV5 {
  height: 4px;
}

.desktop .timeline_indicators__t1NV5 {
  height: 2px;
  transition: transform 0.2s;
}

@media (prefers-reduced-motion) {
  .desktop .timeline_indicators__t1NV5 {
    transition: none;
  }
}

.desktop .timeline_wrapper__1uawa:hover .timeline_indicators__t1NV5 {
  transform: scaleY(2);
}

.timeline_indicators__t1NV5 div {
  position: absolute;
  background: white;
  height: 100%;
  opacity: 0.2;
}

.timeline_indicators__t1NV5 div:nth-child(2) {
  opacity: 0.6;
}

.timeline_indicators__t1NV5 div:nth-child(3) {
  opacity: 1;
  background: var(--primaryColor);
}

.timeline_input__1gA\\+2 {
  width: 100%;
  position: relative;
  background: none;
  cursor: pointer;
}

.timeline_indicators__t1NV5 div {
  transition: width 0.2s linear;
}
`, "",{"version":3,"sources":["webpack://./src/components/timeline/timeline.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;;EAElB,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,kBAAkB;AACpB;;AAEA;EACE,OAAO;AACT;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,kBAAkB;;EAElB,WAAW;;EAEX,wCAAwC;EACxC,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;EACX,0BAA0B;AAC5B;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,UAAU;EACV,+BAA+B;AACjC;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,6BAA6B;AAC/B","sourcesContent":[".wrapper {\n  --thumb-size: 18px;\n\n  display: flex;\n  align-items: center;\n  height: var(--thumb-height);\n  position: relative;\n}\n\n:global(.mobile) .wrapper {\n  flex: 1;\n}\n\n:global(.desktop) .wrapper {\n  flex: none;\n}\n\n.indicators {\n  position: absolute;\n\n  width: 100%;\n\n  background: var(--sliderBackgroundColor);\n  border-radius: 2px;\n  overflow: hidden;\n}\n\n:global(.mobile) .indicators {\n  height: 4px;\n}\n\n:global(.desktop) .indicators {\n  height: 2px;\n  transition: transform 0.2s;\n}\n\n@media (prefers-reduced-motion) {\n  :global(.desktop) .indicators {\n    transition: none;\n  }\n}\n\n:global(.desktop) .wrapper:hover .indicators {\n  transform: scaleY(2);\n}\n\n.indicators div {\n  position: absolute;\n  background: white;\n  height: 100%;\n  opacity: 0.2;\n}\n\n.indicators div:nth-child(2) {\n  opacity: 0.6;\n}\n\n.indicators div:nth-child(3) {\n  opacity: 1;\n  background: var(--primaryColor);\n}\n\n.input {\n  width: 100%;\n  position: relative;\n  background: none;\n  cursor: pointer;\n}\n\n.indicators div {\n  transition: width 0.2s linear;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `timeline_wrapper__1uawa`,
	"indicators": `timeline_indicators__t1NV5`,
	"input": `timeline_input__1gA+2`
};
export default ___CSS_LOADER_EXPORT___;
