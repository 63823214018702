import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: string; output: string };
  Url: { input: string; output: string };
  _FieldSet: { input: any; output: any };
};

export type Appearance = {
  __typename?: 'Appearance';
  backgroundColor: Scalars['String']['output'];
  cardColor: Scalars['String']['output'];
  faviconUrl?: Maybe<Scalars['Url']['output']>;
  headingFont: Scalars['String']['output'];
  headingFontColor: Scalars['String']['output'];
  horizontalLogoUrl?: Maybe<Scalars['Url']['output']>;
  logoUrl?: Maybe<Scalars['Url']['output']>;
  primaryColor: Scalars['String']['output'];
  textFont: Scalars['String']['output'];
  textFontColor: Scalars['String']['output'];
  theme: Theme;
};

export type Chat = {
  __typename?: 'Chat';
  channelUrl: Scalars['String']['output'];
  chatProviderAccountId: Scalars['String']['output'];
  frozen: Scalars['Boolean']['output'];
  type: ChatType;
};

export enum ChatType {
  ANONYMOUS = 'ANONYMOUS',
  MODERATED = 'MODERATED',
  UNMODERATED = 'UNMODERATED',
}

export type ComplianceForm = {
  __typename?: 'ComplianceForm';
  text: Scalars['String']['output'];
};

export type ComplianceForms = {
  __typename?: 'ComplianceForms';
  customComplianceForm: ComplianceForm;
  defaultComplianceForm: ComplianceForm;
  includeDefaultComplianceForm: Scalars['Boolean']['output'];
};

export type CurrentSlide = {
  __typename?: 'CurrentSlide';
  /** Unique ID grouping all language versions for a given presentation */
  groupId: Scalars['String']['output'];
  /** Index of the current slide shown */
  index: Scalars['Int']['output'];
};

export type ECdnSettings = {
  __typename?: 'ECdnSettings';
  /** Represents Hive CDN settings. This property is set only when Hive CDN is enabled for a Webcast */
  hive?: Maybe<HiveSettings>;
};

export enum ErrorDetail {
  /**
   * The deadline expired before the operation could complete.
   *
   * For operations that change the state of the system, this error
   * may be returned even if the operation has completed successfully.
   * For example, a successful response from a server could have been
   * delayed long enough for the deadline to expire.
   *
   * HTTP Mapping: 504 Gateway Timeout
   * Error Type: UNAVAILABLE
   */
  DEADLINE_EXCEEDED = 'DEADLINE_EXCEEDED',
  /**
   * The server detected that the client is exhibiting a behavior that
   * might be generating excessive load.
   *
   * HTTP Mapping: 429 Too Many Requests or 420 Enhance Your Calm
   * Error Type: UNAVAILABLE
   */
  ENHANCE_YOUR_CALM = 'ENHANCE_YOUR_CALM',
  /**
   * The requested field is not found in the schema.
   *
   * This differs from `NOT_FOUND` in that `NOT_FOUND` should be used when a
   * query is valid, but is unable to return a result (if, for example, a
   * specific video id doesn't exist). `FIELD_NOT_FOUND` is intended to be
   * returned by the server to signify that the requested field is not known to exist.
   * This may be returned in lieu of failing the entire query.
   * See also `PERMISSION_DENIED` for cases where the
   * requested field is invalid only for the given user or class of users.
   *
   * HTTP Mapping: 404 Not Found
   * Error Type: BAD_REQUEST
   */
  FIELD_NOT_FOUND = 'FIELD_NOT_FOUND',
  /**
   * The client specified an invalid argument.
   *
   * Note that this differs from `FAILED_PRECONDITION`.
   * `INVALID_ARGUMENT` indicates arguments that are problematic
   * regardless of the state of the system (e.g., a malformed file name).
   *
   * HTTP Mapping: 400 Bad Request
   * Error Type: BAD_REQUEST
   */
  INVALID_ARGUMENT = 'INVALID_ARGUMENT',
  /**
   * The provided cursor is not valid.
   *
   * The most common usage for this error is when a client is paginating
   * through a list that uses stateful cursors. In that case, the provided
   * cursor may be expired.
   *
   * HTTP Mapping: 404 Not Found
   * Error Type: NOT_FOUND
   */
  INVALID_CURSOR = 'INVALID_CURSOR',
  /**
   * Unable to perform operation because a required resource is missing.
   *
   * Example: Client is attempting to refresh a list, but the specified
   * list is expired. This requires an action by the client to get a new list.
   *
   * If the user is simply trying GET a resource that is not found,
   * use the NOT_FOUND error type. FAILED_PRECONDITION.MISSING_RESOURCE
   * is to be used particularly when the user is performing an operation
   * that requires a particular resource to exist.
   *
   * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
   * Error Type: FAILED_PRECONDITION
   */
  MISSING_RESOURCE = 'MISSING_RESOURCE',
  /**
   * Service Error.
   *
   * There is a problem with an upstream service.
   *
   * This may be returned if a gateway receives an unknown error from a service
   * or if a service is unreachable.
   * If a request times out which waiting on a response from a service,
   * `DEADLINE_EXCEEDED` may be returned instead.
   * If a service returns a more specific error Type, the specific error Type may
   * be returned instead.
   *
   * HTTP Mapping: 502 Bad Gateway
   * Error Type: UNAVAILABLE
   */
  SERVICE_ERROR = 'SERVICE_ERROR',
  /**
   * Request failed due to network errors.
   *
   * HTTP Mapping: 503 Unavailable
   * Error Type: UNAVAILABLE
   */
  TCP_FAILURE = 'TCP_FAILURE',
  /**
   * Request throttled based on server concurrency limits.
   *
   * HTTP Mapping: 503 Unavailable
   * Error Type: UNAVAILABLE
   */
  THROTTLED_CONCURRENCY = 'THROTTLED_CONCURRENCY',
  /**
   * Request throttled based on server CPU limits
   *
   * HTTP Mapping: 503 Unavailable.
   * Error Type: UNAVAILABLE
   */
  THROTTLED_CPU = 'THROTTLED_CPU',
  /**
   * The operation is not implemented or is not currently supported/enabled.
   *
   * HTTP Mapping: 501 Not Implemented
   * Error Type: BAD_REQUEST
   */
  UNIMPLEMENTED = 'UNIMPLEMENTED',
  /**
   * Unknown error.
   *
   * This error should only be returned when no other error detail applies.
   * If a client sees an unknown errorDetail, it will be interpreted as UNKNOWN.
   *
   * HTTP Mapping: 500 Internal Server Error
   */
  UNKNOWN = 'UNKNOWN',
}

export enum ErrorType {
  /**
   * Bad Request.
   *
   * There is a problem with the request.
   * Retrying the same request is not likely to succeed.
   * An example would be a query or argument that cannot be deserialized.
   *
   * HTTP Mapping: 400 Bad Request
   */
  BAD_REQUEST = 'BAD_REQUEST',
  /**
   * The operation was rejected because the system is not in a state
   * required for the operation's execution.  For example, the directory
   * to be deleted is non-empty, an rmdir operation is applied to
   * a non-directory, etc.
   *
   * Service implementers can use the following guidelines to decide
   * between `FAILED_PRECONDITION` and `UNAVAILABLE`:
   *
   * - Use `UNAVAILABLE` if the client can retry just the failing call.
   * - Use `FAILED_PRECONDITION` if the client should not retry until
   * the system state has been explicitly fixed.  E.g., if an "rmdir"
   *      fails because the directory is non-empty, `FAILED_PRECONDITION`
   * should be returned since the client should not retry unless
   * the files are deleted from the directory.
   *
   * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
   */
  FAILED_PRECONDITION = 'FAILED_PRECONDITION',
  /**
   * Internal error.
   *
   * An unexpected internal error was encountered. This means that some
   * invariants expected by the underlying system have been broken.
   * This error code is reserved for serious errors.
   *
   * HTTP Mapping: 500 Internal Server Error
   */
  INTERNAL = 'INTERNAL',
  /**
   * The requested entity was not found.
   *
   * This could apply to a resource that has never existed (e.g. bad resource id),
   * or a resource that no longer exists (e.g. cache expired.)
   *
   * Note to server developers: if a request is denied for an entire class
   * of users, such as gradual feature rollout or undocumented allowlist,
   * `NOT_FOUND` may be used. If a request is denied for some users within
   * a class of users, such as user-based access control, `PERMISSION_DENIED`
   * must be used.
   *
   * HTTP Mapping: 404 Not Found
   */
  NOT_FOUND = 'NOT_FOUND',
  /**
   * The caller does not have permission to execute the specified
   * operation.
   *
   * `PERMISSION_DENIED` must not be used for rejections
   * caused by exhausting some resource or quota.
   * `PERMISSION_DENIED` must not be used if the caller
   * cannot be identified (use `UNAUTHENTICATED`
   * instead for those errors).
   *
   * This error Type does not imply the
   * request is valid or the requested entity exists or satisfies
   * other pre-conditions.
   *
   * HTTP Mapping: 403 Forbidden
   */
  PERMISSION_DENIED = 'PERMISSION_DENIED',
  /**
   * The request does not have valid authentication credentials.
   *
   * This is intended to be returned only for routes that require
   * authentication.
   *
   * HTTP Mapping: 401 Unauthorized
   */
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  /**
   * Currently Unavailable.
   *
   * The service is currently unavailable.  This is most likely a
   * transient condition, which can be corrected by retrying with
   * a backoff.
   *
   * HTTP Mapping: 503 Unavailable
   */
  UNAVAILABLE = 'UNAVAILABLE',
  /**
   * Unknown error.
   *
   * For example, this error may be returned when
   * an error code received from another address space belongs to
   * an error space that is not known in this address space.  Also
   * errors raised by APIs that do not return enough error information
   * may be converted to this error.
   *
   * If a client sees an unknown errorType, it will be interpreted as UNKNOWN.
   * Unknown errors MUST NOT trigger any special behavior. These MAY be treated
   * by an implementation as being equivalent to INTERNAL.
   *
   * When possible, a more specific error should be provided.
   *
   * HTTP Mapping: 520 Unknown Error
   */
  UNKNOWN = 'UNKNOWN',
}

export type HiveSettings = {
  __typename?: 'HiveSettings';
  backup?: Maybe<HiveToken>;
  primary: HiveToken;
};

export type HiveToken = {
  __typename?: 'HiveToken';
  jwt: Scalars['String']['output'];
  manifest: Scalars['String']['output'];
};

export type LiveStreaming = {
  __typename?: 'LiveStreaming';
  streams: Array<Stream>;
};

export type Mutation = {
  __typename?: 'Mutation';
  registerUser: RegisterResult;
};

export type MutationRegisterUserArgs = {
  input: RegisterInput;
};

export type Playback = {
  __typename?: 'Playback';
  backupUrl?: Maybe<Scalars['Url']['output']>;
  ecdnSettings?: Maybe<ECdnSettings>;
  primaryUrl: Scalars['Url']['output'];
  token: Scalars['String']['output'];
  /** @deprecated Use primaryUrl instead */
  url: Scalars['Url']['output'];
};

export type Presentation = {
  __typename?: 'Presentation';
  downloadLink: Scalars['Url']['output'];
  /** Unique ID grouping all language versions for a given presentation. */
  groupId: Scalars['String']['output'];
  /** Language (ISO 639-1 code) */
  language: Scalars['String']['output'];
  /** Extracted slide image URLs */
  slides?: Maybe<Array<Maybe<PresentationSlide>>>;
  /** Original name of presentation file */
  sourceFileName: Scalars['String']['output'];
};

export type PresentationSlide = {
  __typename?: 'PresentationSlide';
  downloadUrl: Scalars['Url']['output'];
  index: Scalars['Int']['output'];
  thumbnailDownloadUrl: Scalars['Url']['output'];
};

export type PushedSlide = {
  __typename?: 'PushedSlide';
  /** Unique ID grouping all language versions for a given presentation */
  groupId: Scalars['String']['output'];
  /** Index of the current slide shown */
  index: Scalars['Int']['output'];
  /** Offset in ms since webcast went live */
  offsetInMs: Scalars['Int']['output'];
};

export type Query = {
  __typename?: 'Query';
  _service: _Service;
  webcast?: Maybe<Webcast>;
};

export type QueryWebcastArgs = {
  accessCode?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  webcastId: Scalars['ID']['input'];
};

export type RegisterInput = {
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  webcastId: Scalars['String']['input'];
};

export type RegisterResult = {
  __typename?: 'RegisterResult';
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  status: RegisterStatus;
  timestamp: Scalars['DateTime']['output'];
  webcastId: Scalars['String']['output'];
};

export enum RegisterStatus {
  DUPLICATE = 'DUPLICATE',
  REGISTERED = 'REGISTERED',
}

export type SecurityPolicy = {
  __typename?: 'SecurityPolicy';
  accessGranted?: Maybe<Scalars['Boolean']['output']>;
  idps?: Maybe<Array<Scalars['String']['output']>>;
  requireNameFields?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<SecurityPolicyType>;
};

export enum SecurityPolicyType {
  ACCESS_CODE = 'ACCESS_CODE',
  EMAIL_REGISTRATION = 'EMAIL_REGISTRATION',
  EMAIL_WHITELIST = 'EMAIL_WHITELIST',
  NONE = 'NONE',
  REFERRER_PROTECTION = 'REFERRER_PROTECTION',
  SINGLE_SIGN_ON = 'SINGLE_SIGN_ON',
}

export enum State {
  LIVE = 'LIVE',
  LIVE_TEST = 'LIVE_TEST',
  POSTLIVE = 'POSTLIVE',
  PRELIVE = 'PRELIVE',
}

export type Stream = {
  __typename?: 'Stream';
  backupStatus: StreamStatus;
  metadata?: Maybe<StreamMetadata>;
  playback: Playback;
  /**
   * URL for playing back the stream
   * @deprecated Use 'playback' property instead
   */
  playbackUrl: Scalars['Url']['output'];
  primaryStatus: StreamStatus;
};

export type StreamMetadata = {
  __typename?: 'StreamMetadata';
  language?: Maybe<Scalars['String']['output']>;
};

export enum StreamState {
  CONNECTED = 'CONNECTED',
  NOT_CONNECTED = 'NOT_CONNECTED',
  SERVER_NOT_AVAILABLE = 'SERVER_NOT_AVAILABLE',
}

export type StreamStatus = {
  __typename?: 'StreamStatus';
  streamState: StreamState;
};

export type Streaming = {
  __typename?: 'Streaming';
  live?: Maybe<LiveStreaming>;
  vod?: Maybe<VodStreaming>;
};

export enum Theme {
  DARK = 'DARK',
  LIGHT = 'LIGHT',
}

export type VideoReference = {
  __typename?: 'VideoReference';
  channelId: Scalars['ID']['output'];
  videoId: Scalars['ID']['output'];
};

export type VodStream = {
  __typename?: 'VodStream';
  metadata: StreamMetadata;
  video: VideoReference;
};

export type VodStreaming = {
  __typename?: 'VodStreaming';
  enabled: Scalars['Boolean']['output'];
  streams?: Maybe<Array<VodStream>>;
};

export type Webcast = {
  __typename?: 'Webcast';
  accountId: Scalars['String']['output'];
  additionalLanguages: Array<Scalars['String']['output']>;
  appearance?: Maybe<Appearance>;
  chat?: Maybe<Chat>;
  complianceForms?: Maybe<ComplianceForms>;
  contents: Array<WebcastContent>;
  currentSlide?: Maybe<CurrentSlide>;
  durationInMinutes: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  lsproId: Scalars['String']['output'];
  plannedStartAt: Scalars['DateTime']['output'];
  presentations: Array<Presentation>;
  primaryLanguage: Scalars['String']['output'];
  pushedSlides: Array<PushedSlide>;
  securityPolicies: Array<SecurityPolicy>;
  sharingUrl?: Maybe<Scalars['Url']['output']>;
  state: State;
  streaming?: Maybe<Streaming>;
};

export type WebcastContent = {
  __typename?: 'WebcastContent';
  language: Scalars['String']['output'];
  live: WebcastLiveContent;
  postLive: WebcastPostLiveContent;
  preLive: WebcastPreLiveContent;
  title?: Maybe<Scalars['String']['output']>;
};

export type WebcastLiveContent = {
  __typename?: 'WebcastLiveContent';
  coverImageUrl?: Maybe<Scalars['Url']['output']>;
  description?: Maybe<Scalars['String']['output']>;
};

export type WebcastPostLiveContent = {
  __typename?: 'WebcastPostLiveContent';
  coverImageUrl?: Maybe<Scalars['Url']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  video?: Maybe<VideoReference>;
};

export type WebcastPreLiveContent = {
  __typename?: 'WebcastPreLiveContent';
  coverImageUrl?: Maybe<Scalars['Url']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  video?: Maybe<VideoReference>;
};

export type _Service = {
  __typename?: '_Service';
  sdl: Scalars['String']['output'];
};

export type GetWebcastViewerQueryVariables = Exact<{
  webcastId: Scalars['ID']['input'];
  accessCode?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetWebcastViewerQuery = {
  __typename?: 'Query';
  webcast?:
    | {
        __typename?: 'Webcast';
        id: string;
        lsproId: string;
        accountId: string;
        state: State;
        plannedStartAt: string;
        durationInMinutes: number;
        sharingUrl?: string | null | undefined;
        primaryLanguage: string;
        additionalLanguages: Array<string>;
        appearance?:
          | {
              __typename?: 'Appearance';
              primaryColor: string;
              backgroundColor: string;
              cardColor: string;
              textFont: string;
              textFontColor: string;
              headingFont: string;
              headingFontColor: string;
              theme: Theme;
              logoUrl?: string | null | undefined;
              horizontalLogoUrl?: string | null | undefined;
              faviconUrl?: string | null | undefined;
            }
          | null
          | undefined;
        complianceForms?:
          | {
              __typename?: 'ComplianceForms';
              includeDefaultComplianceForm: boolean;
              defaultComplianceForm: { __typename?: 'ComplianceForm'; text: string };
              customComplianceForm: { __typename?: 'ComplianceForm'; text: string };
            }
          | null
          | undefined;
        contents: Array<{
          __typename?: 'WebcastContent';
          language: string;
          title?: string | null | undefined;
          preLive: {
            __typename?: 'WebcastPreLiveContent';
            description?: string | null | undefined;
            coverImageUrl?: string | null | undefined;
            video?: { __typename?: 'VideoReference'; videoId: string; channelId: string } | null | undefined;
          };
          live: {
            __typename?: 'WebcastLiveContent';
            description?: string | null | undefined;
            coverImageUrl?: string | null | undefined;
          };
          postLive: {
            __typename?: 'WebcastPostLiveContent';
            description?: string | null | undefined;
            coverImageUrl?: string | null | undefined;
            video?: { __typename?: 'VideoReference'; videoId: string; channelId: string } | null | undefined;
          };
        }>;
        securityPolicies: Array<{
          __typename?: 'SecurityPolicy';
          type?: SecurityPolicyType | null | undefined;
          accessGranted?: boolean | null | undefined;
          requireNameFields?: boolean | null | undefined;
          idps?: Array<string> | null | undefined;
        }>;
      }
    | null
    | undefined;
};

export type GetWebcastAccessQueryVariables = Exact<{
  webcastId: Scalars['ID']['input'];
  accessCode?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetWebcastAccessQuery = {
  __typename?: 'Query';
  webcast?:
    | {
        __typename?: 'Webcast';
        id: string;
        securityPolicies: Array<{
          __typename?: 'SecurityPolicy';
          type?: SecurityPolicyType | null | undefined;
          accessGranted?: boolean | null | undefined;
          requireNameFields?: boolean | null | undefined;
          idps?: Array<string> | null | undefined;
        }>;
      }
    | null
    | undefined;
};

export type GetWebcastStreamQueryVariables = Exact<{
  webcastId: Scalars['ID']['input'];
  accessCode?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetWebcastStreamQuery = {
  __typename?: 'Query';
  webcast?:
    | {
        __typename?: 'Webcast';
        id: string;
        streaming?:
          | {
              __typename?: 'Streaming';
              live?:
                | {
                    __typename?: 'LiveStreaming';
                    streams: Array<{
                      __typename?: 'Stream';
                      playback: {
                        __typename?: 'Playback';
                        primaryUrl: string;
                        backupUrl?: string | null | undefined;
                        token: string;
                        ecdnSettings?:
                          | {
                              __typename?: 'ECdnSettings';
                              hive?:
                                | {
                                    __typename?: 'HiveSettings';
                                    primary: { __typename?: 'HiveToken'; jwt: string; manifest: string };
                                    backup?:
                                      | { __typename?: 'HiveToken'; jwt: string; manifest: string }
                                      | null
                                      | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                      };
                      primaryStatus: { __typename?: 'StreamStatus'; streamState: StreamState };
                      backupStatus: { __typename?: 'StreamStatus'; streamState: StreamState };
                      metadata?:
                        | { __typename?: 'StreamMetadata'; language?: string | null | undefined }
                        | null
                        | undefined;
                    }>;
                  }
                | null
                | undefined;
              vod?:
                | {
                    __typename?: 'VodStreaming';
                    enabled: boolean;
                    streams?:
                      | Array<{
                          __typename?: 'VodStream';
                          metadata: { __typename?: 'StreamMetadata'; language?: string | null | undefined };
                          video: { __typename?: 'VideoReference'; channelId: string; videoId: string };
                        }>
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        pushedSlides: Array<{ __typename?: 'PushedSlide'; groupId: string; index: number; offsetInMs: number }>;
        currentSlide?: { __typename?: 'CurrentSlide'; index: number } | null | undefined;
        chat?:
          | { __typename?: 'Chat'; chatProviderAccountId: string; channelUrl: string; type: ChatType }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetWebcastViewerSlidesQueryVariables = Exact<{
  webcastId: Scalars['ID']['input'];
  accessCode?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetWebcastViewerSlidesQuery = {
  __typename?: 'Query';
  webcast?:
    | {
        __typename?: 'Webcast';
        id: string;
        presentations: Array<{
          __typename?: 'Presentation';
          language: string;
          slides?:
            | Array<{ __typename?: 'PresentationSlide'; index: number; downloadUrl: string } | null | undefined>
            | null
            | undefined;
        }>;
        currentSlide?: { __typename?: 'CurrentSlide'; index: number } | null | undefined;
      }
    | null
    | undefined;
};

export type RegisterUserMutationVariables = Exact<{
  input: RegisterInput;
}>;

export type RegisterUserMutation = {
  __typename?: 'Mutation';
  registerUser: {
    __typename?: 'RegisterResult';
    email: string;
    firstName?: string | null | undefined;
    lastName?: string | null | undefined;
  };
};

export const GetWebcastViewerDocument = gql`
  query GetWebcastViewer($webcastId: ID!, $accessCode: String, $email: String) {
    webcast(webcastId: $webcastId, accessCode: $accessCode, email: $email) {
      id
      lsproId
      accountId
      state
      plannedStartAt
      durationInMinutes
      sharingUrl
      primaryLanguage
      additionalLanguages
      appearance {
        primaryColor
        backgroundColor
        cardColor
        textFont
        textFontColor
        headingFont
        headingFontColor
        theme
        logoUrl
        horizontalLogoUrl
        faviconUrl
      }
      complianceForms {
        includeDefaultComplianceForm
        defaultComplianceForm {
          text
        }
        customComplianceForm {
          text
        }
      }
      contents {
        language
        title
        preLive {
          description
          coverImageUrl
          video {
            videoId
            channelId
          }
        }
        live {
          description
          coverImageUrl
        }
        postLive {
          description
          coverImageUrl
          video {
            videoId
            channelId
          }
        }
      }
      securityPolicies {
        type
        accessGranted
        requireNameFields
        idps
      }
    }
  }
`;

/**
 * __useGetWebcastViewerQuery__
 *
 * To run a query within a React component, call `useGetWebcastViewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebcastViewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebcastViewerQuery({
 *   variables: {
 *      webcastId: // value for 'webcastId'
 *      accessCode: // value for 'accessCode'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetWebcastViewerQuery(
  baseOptions: Apollo.QueryHookOptions<GetWebcastViewerQuery, GetWebcastViewerQueryVariables> &
    ({ variables: GetWebcastViewerQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWebcastViewerQuery, GetWebcastViewerQueryVariables>(GetWebcastViewerDocument, options);
}
export function useGetWebcastViewerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetWebcastViewerQuery, GetWebcastViewerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWebcastViewerQuery, GetWebcastViewerQueryVariables>(GetWebcastViewerDocument, options);
}
export function useGetWebcastViewerSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetWebcastViewerQuery, GetWebcastViewerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetWebcastViewerQuery, GetWebcastViewerQueryVariables>(
    GetWebcastViewerDocument,
    options
  );
}
export type GetWebcastViewerQueryHookResult = ReturnType<typeof useGetWebcastViewerQuery>;
export type GetWebcastViewerLazyQueryHookResult = ReturnType<typeof useGetWebcastViewerLazyQuery>;
export type GetWebcastViewerSuspenseQueryHookResult = ReturnType<typeof useGetWebcastViewerSuspenseQuery>;
export type GetWebcastViewerQueryResult = Apollo.QueryResult<GetWebcastViewerQuery, GetWebcastViewerQueryVariables>;
export const GetWebcastAccessDocument = gql`
  query GetWebcastAccess($webcastId: ID!, $accessCode: String, $email: String) {
    webcast(webcastId: $webcastId, accessCode: $accessCode, email: $email) {
      id
      securityPolicies {
        type
        accessGranted
        requireNameFields
        idps
      }
    }
  }
`;

/**
 * __useGetWebcastAccessQuery__
 *
 * To run a query within a React component, call `useGetWebcastAccessQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebcastAccessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebcastAccessQuery({
 *   variables: {
 *      webcastId: // value for 'webcastId'
 *      accessCode: // value for 'accessCode'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetWebcastAccessQuery(
  baseOptions: Apollo.QueryHookOptions<GetWebcastAccessQuery, GetWebcastAccessQueryVariables> &
    ({ variables: GetWebcastAccessQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWebcastAccessQuery, GetWebcastAccessQueryVariables>(GetWebcastAccessDocument, options);
}
export function useGetWebcastAccessLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetWebcastAccessQuery, GetWebcastAccessQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWebcastAccessQuery, GetWebcastAccessQueryVariables>(GetWebcastAccessDocument, options);
}
export function useGetWebcastAccessSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetWebcastAccessQuery, GetWebcastAccessQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetWebcastAccessQuery, GetWebcastAccessQueryVariables>(
    GetWebcastAccessDocument,
    options
  );
}
export type GetWebcastAccessQueryHookResult = ReturnType<typeof useGetWebcastAccessQuery>;
export type GetWebcastAccessLazyQueryHookResult = ReturnType<typeof useGetWebcastAccessLazyQuery>;
export type GetWebcastAccessSuspenseQueryHookResult = ReturnType<typeof useGetWebcastAccessSuspenseQuery>;
export type GetWebcastAccessQueryResult = Apollo.QueryResult<GetWebcastAccessQuery, GetWebcastAccessQueryVariables>;
export const GetWebcastStreamDocument = gql`
  query GetWebcastStream($webcastId: ID!, $accessCode: String, $email: String) {
    webcast(webcastId: $webcastId, accessCode: $accessCode, email: $email) {
      id
      streaming {
        live {
          streams {
            playback {
              primaryUrl
              backupUrl
              token
              ecdnSettings {
                hive {
                  primary {
                    jwt
                    manifest
                  }
                  backup {
                    jwt
                    manifest
                  }
                }
              }
            }
            primaryStatus {
              streamState
            }
            backupStatus {
              streamState
            }
            metadata {
              language
            }
          }
        }
        vod {
          enabled
          streams {
            metadata {
              language
            }
            video {
              channelId
              videoId
            }
          }
        }
      }
      pushedSlides {
        groupId
        index
        offsetInMs
      }
      currentSlide {
        index
      }
      chat {
        chatProviderAccountId
        channelUrl
        type
      }
    }
  }
`;

/**
 * __useGetWebcastStreamQuery__
 *
 * To run a query within a React component, call `useGetWebcastStreamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebcastStreamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebcastStreamQuery({
 *   variables: {
 *      webcastId: // value for 'webcastId'
 *      accessCode: // value for 'accessCode'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetWebcastStreamQuery(
  baseOptions: Apollo.QueryHookOptions<GetWebcastStreamQuery, GetWebcastStreamQueryVariables> &
    ({ variables: GetWebcastStreamQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWebcastStreamQuery, GetWebcastStreamQueryVariables>(GetWebcastStreamDocument, options);
}
export function useGetWebcastStreamLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetWebcastStreamQuery, GetWebcastStreamQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWebcastStreamQuery, GetWebcastStreamQueryVariables>(GetWebcastStreamDocument, options);
}
export function useGetWebcastStreamSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetWebcastStreamQuery, GetWebcastStreamQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetWebcastStreamQuery, GetWebcastStreamQueryVariables>(
    GetWebcastStreamDocument,
    options
  );
}
export type GetWebcastStreamQueryHookResult = ReturnType<typeof useGetWebcastStreamQuery>;
export type GetWebcastStreamLazyQueryHookResult = ReturnType<typeof useGetWebcastStreamLazyQuery>;
export type GetWebcastStreamSuspenseQueryHookResult = ReturnType<typeof useGetWebcastStreamSuspenseQuery>;
export type GetWebcastStreamQueryResult = Apollo.QueryResult<GetWebcastStreamQuery, GetWebcastStreamQueryVariables>;
export const GetWebcastViewerSlidesDocument = gql`
  query GetWebcastViewerSlides($webcastId: ID!, $accessCode: String, $email: String) {
    webcast(webcastId: $webcastId, accessCode: $accessCode, email: $email) {
      id
      presentations {
        language
        slides {
          index
          downloadUrl
        }
      }
      currentSlide {
        index
      }
    }
  }
`;

/**
 * __useGetWebcastViewerSlidesQuery__
 *
 * To run a query within a React component, call `useGetWebcastViewerSlidesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebcastViewerSlidesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebcastViewerSlidesQuery({
 *   variables: {
 *      webcastId: // value for 'webcastId'
 *      accessCode: // value for 'accessCode'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetWebcastViewerSlidesQuery(
  baseOptions: Apollo.QueryHookOptions<GetWebcastViewerSlidesQuery, GetWebcastViewerSlidesQueryVariables> &
    ({ variables: GetWebcastViewerSlidesQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWebcastViewerSlidesQuery, GetWebcastViewerSlidesQueryVariables>(
    GetWebcastViewerSlidesDocument,
    options
  );
}
export function useGetWebcastViewerSlidesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetWebcastViewerSlidesQuery, GetWebcastViewerSlidesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWebcastViewerSlidesQuery, GetWebcastViewerSlidesQueryVariables>(
    GetWebcastViewerSlidesDocument,
    options
  );
}
export function useGetWebcastViewerSlidesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetWebcastViewerSlidesQuery, GetWebcastViewerSlidesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetWebcastViewerSlidesQuery, GetWebcastViewerSlidesQueryVariables>(
    GetWebcastViewerSlidesDocument,
    options
  );
}
export type GetWebcastViewerSlidesQueryHookResult = ReturnType<typeof useGetWebcastViewerSlidesQuery>;
export type GetWebcastViewerSlidesLazyQueryHookResult = ReturnType<typeof useGetWebcastViewerSlidesLazyQuery>;
export type GetWebcastViewerSlidesSuspenseQueryHookResult = ReturnType<typeof useGetWebcastViewerSlidesSuspenseQuery>;
export type GetWebcastViewerSlidesQueryResult = Apollo.QueryResult<
  GetWebcastViewerSlidesQuery,
  GetWebcastViewerSlidesQueryVariables
>;
export const RegisterUserDocument = gql`
  mutation RegisterUser($input: RegisterInput!) {
    registerUser(input: $input) {
      email
      firstName
      lastName
    }
  }
`;
export type RegisterUserMutationFn = Apollo.MutationFunction<RegisterUserMutation, RegisterUserMutationVariables>;

/**
 * __useRegisterUserMutation__
 *
 * To run a mutation, you first call `useRegisterUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserMutation, { data, loading, error }] = useRegisterUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterUserMutation(
  baseOptions?: Apollo.MutationHookOptions<RegisterUserMutation, RegisterUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RegisterUserMutation, RegisterUserMutationVariables>(RegisterUserDocument, options);
}
export type RegisterUserMutationHookResult = ReturnType<typeof useRegisterUserMutation>;
export type RegisterUserMutationResult = Apollo.MutationResult<RegisterUserMutation>;
export type RegisterUserMutationOptions = Apollo.BaseMutationOptions<
  RegisterUserMutation,
  RegisterUserMutationVariables
>;
