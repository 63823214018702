import type { SecurityPolicy } from '../../../../generated/graphql-manager';

import {
  Accordion,
  CopyToClipboard,
  Heading,
  InfoCircleIcon,
  InputDescription,
  Paragraph,
  SubHeading,
  TextArea,
  TextInput,
  Tooltip,
  getLanguageKey,
} from '@movingimage-evp/mi-ui-component-library';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { RestrictEmbedding } from './restrict-embedding';
import { State, useGetWebcastSharingQuery } from '../../../../generated/graphql-manager';
import { useAbsoluteRoutes } from '../../../../routes';
import { generateEmbedCode } from '../../../../utils/generate-embed-code';
import { SetupPageFooter } from '../../../components/setup-page-footer';
import { useGetPrimaryTitle } from '../../../hooks/use-get-primary-title';
import { useUserPermissions } from '../../../hooks/user-permissions';
import managerStyles from '../../../manager.module.css';

import styles from './sharing-and-embedding.module.css';

export type SharingData = {
  sharingUrl: string;
  securedLink: boolean;
  accessCode: string;
};

export function SharingAndEmbeddingPage() {
  const { t } = useTranslation();
  const { webcastId = '' } = useParams();
  const title = useGetPrimaryTitle();
  const routes = useAbsoluteRoutes();
  const { isEventEditingAllowed } = useUserPermissions();

  const [isReferrerProtectionOpen, setIsReferrerProtectionOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [isSharingAccordionOpen, setIsSharingAccordionOpen] = useState(false);
  const [isEmbedCodeAccordionOpen, setIsEmbedCodeAccordionOpen] = useState(false);

  const getWebcastSharingResponse = useGetWebcastSharingQuery({ variables: { webcastId } });

  const sharingUrl = getWebcastSharingResponse.data?.webcast.sharingUrl || '';
  const securityPolicies = getWebcastSharingResponse.data?.webcast.securityPolicies || [];

  const formDisabled = getWebcastSharingResponse.data?.webcast.state !== State.PRELIVE || !isEventEditingAllowed;

  const additionalLanguages = getWebcastSharingResponse.data?.webcast.additionalLanguages || [];
  const primaryLanguage = getWebcastSharingResponse.data?.webcast.primaryLanguage || '';

  const getEmbedCode = (language?: string) => {
    return generateEmbedCode({
      url: language ? getSharingUrlWithLanguage(language) : sharingUrl,
      title: title || '',
    });
  };

  const getSharingUrlWithLanguage = (language: string) => {
    return `${sharingUrl}/${language}`;
  };

  return (
    <main className={managerStyles.main} data-testid="sharing-page">
      <Heading className={managerStyles.grayText}>{t('manager.webcastSetup.sharingAndEmbedding.title')}</Heading>
      <Paragraph className={managerStyles.grayText}>
        {t('manager.webcastSetup.sharingAndEmbedding.description')}
      </Paragraph>

      <div className={styles.sharingWrapper}>
        {additionalLanguages.length === 0 && (
          <>
            <InputDescription inputId="sharing-url" label={t('manager.webcastSetup.sharingAndEmbedding.sharing.label')}>
              <TextInput readOnly value={sharingUrl} data-testid="sharing-url">
                <CopyToClipboard value={sharingUrl} notificationPosition="left" />
              </TextInput>
            </InputDescription>

            <InputDescription
              inputId="embed-code"
              label={t('manager.webcastSetup.sharingAndEmbedding.embedCode.label')}
            >
              <TextArea readOnly value={getEmbedCode()} data-testid="embed-code" rows={3}>
                <CopyToClipboard value={getEmbedCode()} notificationPosition="left" />
              </TextArea>
            </InputDescription>
          </>
        )}

        {additionalLanguages.length > 0 && (
          <>
            <div className={styles.sharingMultipleLanguagesWrapper}>
              <div className={styles.subHeadingWrapper}>
                <SubHeading>{t('manager.webcastSetup.sharingAndEmbedding.sharing.label')}</SubHeading>
                <Tooltip label={t('manager.webcastSetup.sharingAndEmbedding.sharing.multiLingual.hint')}>
                  <InfoCircleIcon className={styles.hint} />
                </Tooltip>
              </div>

              <InputDescription
                inputId="primary-language-sharing-url"
                label={t(`manager.common.languages.${getLanguageKey(primaryLanguage)}`)}
              >
                <TextInput
                  readOnly
                  value={getSharingUrlWithLanguage(primaryLanguage)}
                  data-testid="primary-language-sharing-url"
                >
                  <CopyToClipboard value={getSharingUrlWithLanguage(primaryLanguage)} notificationPosition="left" />
                </TextInput>
              </InputDescription>

              <Accordion
                data-testid="sharing-accordion"
                className={styles.multipleLanguagesAccordion}
                onToggle={setIsSharingAccordionOpen}
                buttonContent={
                  <span>
                    {isSharingAccordionOpen
                      ? t('manager.webcastSetup.sharingAndEmbedding.sharing.multiLingual.accordionCloseButtonLabel')
                      : t('manager.webcastSetup.sharingAndEmbedding.sharing.multiLingual.accordionOpenButtonLabel')}
                  </span>
                }
              >
                <div data-testid="sharing-accordion-content" className={styles.additionalLanguagesWrapper}>
                  {additionalLanguages.map((language) => (
                    <InputDescription
                      inputId={`sharing-url-${language}`}
                      key={`sharing-url-${language}`}
                      label={t(`manager.common.languages.${getLanguageKey(language)}`)}
                    >
                      <TextInput
                        readOnly
                        value={getSharingUrlWithLanguage(language)}
                        data-testid={`sharing-url-${language}`}
                      >
                        <CopyToClipboard value={getSharingUrlWithLanguage(language)} notificationPosition="left" />
                      </TextInput>
                    </InputDescription>
                  ))}
                </div>
              </Accordion>
            </div>

            <div className={styles.sharingMultipleLanguagesWrapper}>
              <div className={styles.subHeadingWrapper}>
                <SubHeading>{t('manager.webcastSetup.sharingAndEmbedding.embedCode.label')}</SubHeading>
                <Tooltip label={t('manager.webcastSetup.sharingAndEmbedding.embedCode.multiLingual.hint')}>
                  <InfoCircleIcon className={styles.hint} />
                </Tooltip>
              </div>

              <InputDescription
                inputId="primary-language-embed-code"
                label={t(`manager.common.languages.${getLanguageKey(primaryLanguage)}`)}
              >
                <TextArea
                  readOnly
                  value={getEmbedCode(primaryLanguage)}
                  data-testid="primary-language-embed-code"
                  rows={3}
                >
                  <CopyToClipboard value={getEmbedCode(primaryLanguage)} notificationPosition="left" />
                </TextArea>
              </InputDescription>

              <Accordion
                data-testid="embed-code-accordion"
                className={styles.multipleLanguagesAccordion}
                onToggle={setIsEmbedCodeAccordionOpen}
                buttonContent={
                  <span>
                    {isEmbedCodeAccordionOpen
                      ? t('manager.webcastSetup.sharingAndEmbedding.embedCode.multiLingual.accordionCloseButtonLabel')
                      : t('manager.webcastSetup.sharingAndEmbedding.embedCode.multiLingual.accordionOpenButtonLabel')}
                  </span>
                }
              >
                <div data-testid="embed-code-accordion-content" className={styles.additionalLanguagesWrapper}>
                  {additionalLanguages.map((language) => (
                    <InputDescription
                      inputId={`embed-code-${language}`}
                      key={`embed-code-${language}`}
                      label={t(`manager.common.languages.${getLanguageKey(language)}`)}
                    >
                      <TextArea readOnly value={getEmbedCode(language)} data-testid={`embed-code-${language}`} rows={3}>
                        <CopyToClipboard value={getEmbedCode(language)} notificationPosition="left" />
                      </TextArea>
                    </InputDescription>
                  ))}
                </div>
              </Accordion>
            </div>
          </>
        )}

        {!getWebcastSharingResponse.loading && (
          <RestrictEmbedding
            webcastId={webcastId}
            disabled={formDisabled}
            securityPolicies={securityPolicies as SecurityPolicy[]}
            isOpen={isReferrerProtectionOpen}
            onToggle={setIsReferrerProtectionOpen}
            onSaving={setSaving}
          />
        )}
      </div>

      <SetupPageFooter
        nextLabel={t('manager.webcastSetup.sharingAndEmbedding.nextStep.label')}
        route={routes.webcastSetup_messaging}
        saving={saving}
      />
    </main>
  );
}
