import { Heading, Paragraph, SelectionInputLayout, Spinner, Toggle } from '@movingimage-evp/mi-ui-component-library';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  State,
  useGetWebcastEcdnSettingsQuery,
  useUpdateWebcastEcdnMutation,
} from '../../../../generated/graphql-manager';
import { useAbsoluteRoutes } from '../../../../routes';
import { SetupPageFooter } from '../../../components/setup-page-footer';
import { useUserPermissions } from '../../../hooks/user-permissions';
import managerStyles from '../../../manager.module.css';

import styles from './ecdn.module.css';

export function EcdnPage() {
  const { t } = useTranslation();
  const { webcastId = '' } = useParams();
  const routes = useAbsoluteRoutes();
  const { isEventEditingAllowed } = useUserPermissions();

  const ecdnSettingsResponse = useGetWebcastEcdnSettingsQuery({
    variables: { webcastId },
    skip: !webcastId,
  });

  const [updateWebcastEcdn, updateWebcastEcdnResponse] = useUpdateWebcastEcdnMutation();

  const ecdnActive = ecdnSettingsResponse.data?.webcast.ecdnSettings?.isActive;

  function handleToggleEcdn() {
    updateWebcastEcdn({
      variables: {
        input: {
          id: webcastId,
          ecdnSettings: {
            enabled: !ecdnActive,
          },
        },
      },
    });
  }

  const changesLoading = updateWebcastEcdnResponse.loading;
  const formDisabled = ecdnSettingsResponse.data?.webcast.state !== State.PRELIVE || !isEventEditingAllowed;

  if (ecdnSettingsResponse.loading) {
    return (
      <div className={styles.spinner}>
        <Spinner size={40} />
      </div>
    );
  }

  return (
    <main className={managerStyles.main} data-testid="ecdn-page">
      <Heading className={managerStyles.grayText}>{t('manager.webcastSetup.ecdn.title')}</Heading>

      <Paragraph className={managerStyles.grayText}>{t('manager.webcastSetup.ecdn.description')}</Paragraph>

      <SelectionInputLayout
        className={styles.inputLayout}
        checked={ecdnActive}
        disabled={formDisabled}
        hintMessage={t('manager.webcastSetup.ecdn.toggleHint')}
      >
        <Toggle
          data-testid="enable-ecdn-toggle"
          checked={ecdnActive}
          disabled={formDisabled}
          onChange={handleToggleEcdn}
        >
          {t('manager.webcastSetup.ecdn.toggleLabel')}
        </Toggle>
      </SelectionInputLayout>

      <SetupPageFooter
        nextLabel={t('manager.webcastSetup.ecdn.nextStep.label')}
        route={routes.webcastSetup_setupSummary}
        saving={changesLoading}
      />
    </main>
  );
}
