import { MutableRefObject, useCallback, useEffect } from 'react';
import { focusNextOrPreviousElement } from './use-keyboard-menu-focus.utils';
import { Key, noop } from '../utils';

export function useKeyboardMenuFocus(
  ref: MutableRefObject<HTMLElement | null>,
  isOpen: boolean | undefined,
  onClose?: () => void
) {
  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      const target = event.target as HTMLButtonElement;
      const focusableElements = ref?.current?.querySelectorAll('button');

      switch (event.key) {
        case Key.ArrowDown:
        case Key.ArrowRight:
          focusNextOrPreviousElement(event, target, focusableElements);
          break;
        case Key.ArrowUp:
        case Key.ArrowLeft:
          focusNextOrPreviousElement(event, target, focusableElements, -1);
          break;
        case Key.Tab:
          if (event.shiftKey) {
            focusNextOrPreviousElement(event, target, focusableElements, -1);
          } else {
            focusNextOrPreviousElement(event, target, focusableElements);
          }
          break;
        case Key.Escape:
          event.preventDefault();
          (onClose || noop)();
          break;
        default:
          break;
      }
    },
    [onClose, ref]
  );

  useEffect(() => {
    if (!ref.current) return;

    const wrapperElement = ref.current;

    if (isOpen) {
      wrapperElement.addEventListener('keydown', handleKeyPress);
    } else {
      wrapperElement.removeEventListener('keydown', handleKeyPress);
    }
    return () => {
      wrapperElement.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress, isOpen, ref]);
}
