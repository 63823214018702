import {
  CheckCircleIcon,
  ChipsInput,
  FileOutlineIcon,
  FileUploader,
  InfoCircleIcon,
  InputChip,
  InputDescription,
  Modal,
  PrimaryButton,
  Radio,
  SecondaryButton,
  SelectionInputLayout,
  Spinner,
  Tooltip,
  WarningIcon,
  classNames,
} from '@movingimage-evp/mi-ui-component-library';
import { t } from 'i18next';
import { useEffect, useState } from 'react';

import { type SecurityPolicy } from '../../../../../generated/graphql-manager';
import { findReferrerProtectionPolicy } from '../../../../../utils/graphql-helpers';
import { validateEmailInput } from '../validate-email-input';

import styles from './email-whitelist-modal.module.css';

type EmailWhitelistModalProps = {
  isOpen: boolean;
  securityPolicies?: SecurityPolicy[];
  maxEmails?: number;
  onConfirm: (emails: string[]) => void;
  onClose: () => void;
};

type Email = {
  value: string;
  validationMessage?: string;
};

type AddEmailMethod = 'manual' | 'csv';

export function EmailWhitelistModal({
  isOpen,
  securityPolicies,
  maxEmails = 5000,
  onConfirm,
  onClose,
}: EmailWhitelistModalProps) {
  const referrerProtectionPolicy = findReferrerProtectionPolicy(securityPolicies);

  const [emails, setEmails] = useState<Email[]>(
    (referrerProtectionPolicy?.domains || []).map((value) => ({ value, validationMessage: '' }))
  );
  const [addMethod, setAddMethod] = useState<AddEmailMethod>('manual');
  const [isCsvLoading, setIsCsvLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const validateEmails = (emails: Email[]) => {
    const validatedEmails: Email[] = [];
    const validatedValues = new Set<string>();

    for (const email of emails) {
      const validationMessage = validateEmailInput(email.value, Array.from(validatedValues));
      const isValid = !validationMessage;

      validatedEmails.push({ value: email.value, validationMessage });

      if (isValid) validatedValues.add(email.value);
    }

    return validatedEmails;
  };

  const onChipsAdded = (values: Email['value'][]) => {
    setEmails((state) => validateEmails([...state, ...values.map((value) => ({ value, validationMessage: '' }))]));
  };

  const onChipDeleted = (index: number) => {
    setEmails((state) => validateEmails(state.filter((_, existingIndex) => existingIndex !== index)));
  };

  const onChipEdit = (value: string, index: number) => {
    const validationMessage = validateEmailInput(
      value,
      emails.map(({ value }) => value)
    );

    setEmails((state) =>
      state.map((email, existingIndex) => (existingIndex === index ? { value, validationMessage } : email))
    );
  };

  const resetState = () => {
    setEmails([]);
    setErrorMessage(undefined);
  };

  const handleClose = () => {
    resetState();
    onClose();
  };

  const handleConfirm = () => {
    if (submitDisabled) return;

    onConfirm(emails.map(({ value }) => value));
    handleClose();
  };

  const numberOfEmails = emails.length;
  const invalidEmails = emails.filter(({ validationMessage }) => Boolean(validationMessage));
  const numberOfInvalidEmails = invalidEmails.length;
  const numberOfValidEmails = emails.length - numberOfInvalidEmails;
  const submitDisabled = numberOfEmails === 0 || numberOfValidEmails === 0 || numberOfInvalidEmails > 0;
  const radioButtonsAvailable = addMethod !== 'csv' || emails.length === 0;
  const fileUploaderAvailable = addMethod === 'csv' && emails.length === 0;

  let validationMessage;
  if (numberOfInvalidEmails === 1) validationMessage = invalidEmails[0].validationMessage;
  if (numberOfInvalidEmails > 1)
    validationMessage = t('manager.webcastSetup.viewerAccess.options.emailWhitelist.errorMessages.multipleErrors');

  const tooltipLabel =
    validationMessage ||
    (submitDisabled && t('manager.webcastSetup.viewerAccess.options.emailWhitelist.errorMessages.emptyList')) ||
    undefined;

  const handleUploadFile = (file: File) => {
    const fileReader = new FileReader();

    if (!file) return;

    setErrorMessage(undefined);
    setIsCsvLoading(true);

    fileReader.onload = (event) => {
      parseAndSaveCsvEmails(event?.target?.result as string);
      setIsCsvLoading(false);
    };

    fileReader.readAsText(file);
  };

  const parseAndSaveCsvEmails = (value?: string) => {
    if (!value) {
      return setErrorMessage(
        t('manager.webcastSetup.viewerAccess.options.emailWhitelist.addEmailsModal.csvUpload.hint.empty')
      );
    }

    const emails = value.split('\n').map((email) => email.trim().toLowerCase());
    const emailsWithoutHeader = !emails[0].includes('@') ? emails.slice(1) : emails;
    const filteredEmails = Array.from(new Set(emailsWithoutHeader)).filter(Boolean);

    if (filteredEmails.length > maxEmails) {
      return setErrorMessage(
        t('manager.webcastSetup.viewerAccess.options.emailWhitelist.addEmailsModal.csvUpload.hint.size')
      );
    }

    setEmails((state) =>
      validateEmails([...state, ...filteredEmails.map((email) => ({ value: email, validationMessage: '' }))])
    );
  };

  useEffect(resetState, [addMethod]);

  const sortedEmailsByValidation = emails.sort((a, b) => {
    if (a.validationMessage && !b.validationMessage) return -1;
    if (!a.validationMessage && b.validationMessage) return 1;
    return 0;
  });

  const EmailsChipsInput = (
    <ChipsInput
      separators={['Enter', ',', ' ']}
      onChipsAdded={onChipsAdded}
      className={{ chipsInput: styles.chipsInput }}
      data-testid="email-whitelist-chips-input"
    >
      {sortedEmailsByValidation.map((email, index) => (
        <InputChip
          key={`${email.value}-${index}`}
          value={email.value}
          invalid={Boolean(email.validationMessage)}
          onDelete={() => onChipDeleted(index)}
          onEdit={(value) => onChipEdit(value, index)}
        />
      ))}
    </ChipsInput>
  );

  return (
    <Modal
      data-testid="email-whitelist-modal"
      title={t('manager.webcastSetup.viewerAccess.options.emailWhitelist.addEmailsModal.title')}
      isOpen={isOpen}
      onClose={handleClose}
      footer={
        !fileUploaderAvailable && (
          <div className={styles.emailWhitelistFooter}>
            <SecondaryButton data-testid="email-whitelist-modal-cancel-button" onClick={handleClose}>
              {t('manager.webcastSetup.viewerAccess.options.emailWhitelist.addEmailsModal.footer.cancel')}
            </SecondaryButton>

            <Tooltip label={tooltipLabel} hidden={!submitDisabled}>
              <PrimaryButton
                data-testid="email-whitelist-modal-submit-button"
                disabled={submitDisabled}
                onClick={handleConfirm}
              >
                {t('manager.webcastSetup.viewerAccess.options.emailWhitelist.addEmailsModal.footer.confirm')}
              </PrimaryButton>
            </Tooltip>
          </div>
        )
      }
    >
      {radioButtonsAvailable && (
        <div className={styles.addMethodWrapper}>
          <SelectionInputLayout checked={addMethod === 'manual'}>
            <Radio
              data-testid="add-email-manual"
              name="add-emails-method"
              value="manual"
              checked={addMethod === 'manual'}
              onChange={() => setAddMethod('manual')}
            >
              {t('manager.webcastSetup.viewerAccess.options.emailWhitelist.addEmailsModal.manualInput.radio')}
            </Radio>
          </SelectionInputLayout>

          <SelectionInputLayout checked={addMethod === 'csv'}>
            <Radio
              data-testid="add-email-csv"
              name="add-emails-method"
              value="csv"
              checked={addMethod === 'csv'}
              onChange={() => setAddMethod('csv')}
            >
              {t('manager.webcastSetup.viewerAccess.options.emailWhitelist.addEmailsModal.csvUpload.radio')}
            </Radio>
          </SelectionInputLayout>
        </div>
      )}

      {addMethod === 'manual' && (
        <>
          <InputDescription
            hint={t('manager.webcastSetup.viewerAccess.options.emailWhitelist.addEmailsModal.manualInput.hint')}
            hintDelay={0}
            label={t('manager.webcastSetup.viewerAccess.options.emailWhitelist.addEmailsModal.manualInput.label')}
            style={{ marginTop: 50 }}
          >
            {EmailsChipsInput}
          </InputDescription>

          <div
            data-testid="email-whitelist-modal-manual-info"
            className={classNames(styles.inputInfo, validationMessage && styles.invalid)}
          >
            {validationMessage ||
              t('manager.webcastSetup.viewerAccess.options.emailWhitelist.addEmailsModal.manualInput.helpMessage')}
          </div>
        </>
      )}

      {addMethod === 'csv' && numberOfEmails === 0 && (
        <div className={styles.fileUploaderWrapper}>
          {isCsvLoading && <Spinner className={styles.fileUploaderSpinner} />}

          <FileUploader
            // Remount the file uploader when an error occurs in order not to show the progress bar
            // when the file got loaded but there are still zero emails because there was an error.
            key={errorMessage}
            acceptedFormats=".csv"
            icon={<FileOutlineIcon />}
            onChange={handleUploadFile}
            testIdPrefix="email-whitelist-csv-"
            additionalUploadInformation={[
              t('manager.webcastSetup.viewerAccess.options.emailWhitelist.addEmailsModal.csvUpload.hint.size'),
              t('manager.webcastSetup.viewerAccess.options.emailWhitelist.addEmailsModal.csvUpload.hint.extension'),
              t('manager.webcastSetup.viewerAccess.options.emailWhitelist.addEmailsModal.csvUpload.hint.delimiter'),
              t('manager.webcastSetup.viewerAccess.options.emailWhitelist.addEmailsModal.csvUpload.hint.duplication'),
            ]}
          >
            {errorMessage && (
              <div data-testid="file-upload-error-message" className={classNames(styles.resultInfo, styles.invalid)}>
                <WarningIcon />
                {errorMessage}
              </div>
            )}
          </FileUploader>
        </div>
      )}

      {addMethod === 'csv' && numberOfEmails > 0 && (
        <>
          <div className={styles.resultWrapper}>
            <span data-testid="valid-emails-info">
              {t('manager.webcastSetup.viewerAccess.options.emailWhitelist.addEmailsModal.csvUpload.validEmails', {
                count: numberOfValidEmails,
              })}
            </span>

            <div className={classNames(styles.resultInfo, numberOfInvalidEmails > 0 && styles.invalid)}>
              {numberOfInvalidEmails > 0 ? (
                <>
                  <InfoCircleIcon />

                  <span data-testid="invalid-emails-info">
                    {t(
                      'manager.webcastSetup.viewerAccess.options.emailWhitelist.addEmailsModal.csvUpload.invalidEmails',
                      { count: numberOfInvalidEmails }
                    )}
                  </span>
                </>
              ) : (
                <>
                  <CheckCircleIcon />

                  <span data-testid="all-valid-emails-info">
                    {t(
                      'manager.webcastSetup.viewerAccess.options.emailWhitelist.addEmailsModal.csvUpload.allValidEmails'
                    )}
                  </span>
                </>
              )}
            </div>
          </div>

          <InputDescription
            label={t('manager.webcastSetup.viewerAccess.options.emailWhitelist.addEmailsModal.csvUpload.label')}
          >
            {EmailsChipsInput}
          </InputDescription>

          {Boolean(numberOfInvalidEmails) && (
            <div data-testid="email-whitelist-modal-csv-info" className={classNames(styles.inputInfo, styles.invalid)}>
              <InfoCircleIcon />
              {t('manager.webcastSetup.viewerAccess.options.emailWhitelist.addEmailsModal.csvUpload.invalidEmailsInfo')}
            </div>
          )}
        </>
      )}
    </Modal>
  );
}
